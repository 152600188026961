import type { PlannerFetchContext, UnassignedIssue } from "@/components/ThePlanner/types";
import { useIssueService }                           from "@/service";
import type { Nullable }                             from "@/types";
import { groupBy }                                   from "@/utils";
import { useIssuesFetchProvider }                    from "@/views/PlannerView/use/useIssuesFetchProvider";
import { computed }                                  from "vue";

export type UseUnassignedIssuesReturn = ReturnType<typeof useUnassignedIssues>;

export type UseUnassignedIssuesOptions = {};

export function useUnassignedIssues(_options?: UseUnassignedIssuesOptions) {
  const _issueService = useIssueService();
  const {
    httpCtx: _httpFetchCtx,
    issues,
    fetch: _fetch
  } = useIssuesFetchProvider("unassigned", {
    service: _issueService
  });

  const issuesGroupedByRedmineId = computed(() => {
    return groupBy(issues.value, issue => issue.redmine_id);
  });

  const isLoading = computed(() => {
    return (
      _httpFetchCtx.value?.isFetching.value ?? false
    );
  });

  function fetch(params: PlannerFetchContext): Promise<Nullable<UnassignedIssue[]>> {
    return _fetch({
      filterType: params.issuesFilter.type,
      sort: params.unplannedIssuesSortBy,
      filterValue: (() => {
        switch ( params.issuesFilter.type ) {
          case "project":
            return params.issuesFilter.value?.name ?? "";
          case "user":
            return params.issuesFilter.value?.full_name ?? "";
          default:
            return "";
        }
      })()
    });
  }

  function setIssues(issues_: UnassignedIssue[]) {
    issues.value = issues_;
  }

  return {
    issues,
    issuesGroupedByRedmineId,
    isLoading,
    fetch,
    setIssues
  };
}
