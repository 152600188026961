<script setup lang="ts">
import type { TooltipContentBlockDraggedProps } from "@/components/ThePlanner/components/TheTooltip/types";
import { formatDatetime }                       from "@/utils/datetimeFormatters";
import { differenceInMinutes }                  from "date-fns";
import { computed }                             from "vue";

defineOptions({
  name: "TooltipContentBlockIssueDragged"
});

const props = defineProps<TooltipContentBlockDraggedProps>();

const issue = computed(() => props.issue);

const formattedDateStart = computed(() => {
  return formatDatetime(props.startDate);
});

const formattedDateEnd = computed(() => {
  return formatDatetime(props.endDate);
});

const estimatedHours = computed(() => {
  const t = differenceInMinutes(props.endDate, props.startDate) ?? 60;
  return +(t / 60).toFixed(2);
});

const spentHours = computed(() => {
  return issue.value.total_spent_hours ?? 0;
});
</script>

<template>
  <div class="flex flex-col gap-1 p-2 h-full justify-center text-xs text-gray-600">
    <div class="text-center text-sm">#{{ issue.redmine_id }}</div>

    <div class="flex flex-col mt-1 gap-1 mb-1">
      <div class="flex gap-0.5">
        <div class="font-semibold">Estimated:</div>
        <div class="">{{ estimatedHours }}h</div>
      </div>

      <div class="flex gap-0.5">
        <div class="font-semibold">Spent:</div>
        <div
          :class="[
            spentHours > estimatedHours ? 'text-red-600' : 'text-green-600'
          ]"
        >
          {{ spentHours }}h
        </div>
      </div>

      <div class="flex gap-3 justify-center text-gray-600">
        <div class="gap-1 flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <div class="whitespace-nowrap">{{ formattedDateStart }}</div>
        </div>

        <div class="gap-1 flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <span class="whitespace-nowrap">{{ formattedDateEnd }}</span>
        </div>
      </div>
    </div>

    <p
      v-if="message"
      class="mt-1.5 text-center text-xs text-red-500"
    >
      {{ message }}
    </p>
  </div>
</template>
