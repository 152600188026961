<script setup lang="ts">
import { AppAccordion }                        from "@/components";
import { ButtonUnselectUser }                  from "@/components/ThePlanner/components/buttons";
import { DatesList }                           from "@/components/ThePlanner/components/TheWrike/components";
import {
  useUserBlock
}                                              from "@/components/ThePlanner/components/TheWrike/components/UserBlock/composables";
import type {
  UserBlockProps
}                                              from "@/components/ThePlanner/components/TheWrike/components/UserBlock/types";
import { type ComponentPublicInstance, toRef } from "vue";

const props = defineProps<UserBlockProps>();

const {
  containerEl,
  dateItems,
  isHighlighted,
  onUserDeselect,
  onElementMouseenter,
  onElementMouseleave,
  onUnassignedBlockDrop,
  onAssignedBlockDrop
} = useUserBlock({
  user: toRef(props, "user"),
  issues: toRef(props, "issues"),
  schedulerCtx: props.schedulerCtx
});
</script>

<template>
  <AppAccordion
    :ref="(el: ComponentPublicInstance) => containerEl = el?.$el ?? null"
    :model-value="true"
    disable-toggle-button
    class="w-full"
    header-class="p-0 border-b border-gray-300 w-full"
    @mouseenter="onElementMouseenter"
    @mouseleave="onElementMouseleave"
  >
    <template #header>
      <!-- HEADER -->
      <div
        class="w-full flex justify-between items-center px-2 py-1 text-sm"
        :class="{
          'bg-gray-300': !isHighlighted,
          'bg-amber-300': isHighlighted
        }"
      >
        <div class="text-sm flex gap-1 font-semibold">
          <div class="uppercase">{{ user.last_name }}</div>
          <div>{{ user.first_name }}</div>
        </div>

        <div>
          <ButtonUnselectUser @click="onUserDeselect(user)" />
        </div>
      </div>
    </template>

    <div class="flex flex-col">

      <!--BODY-->
      <div class="p-2">
        <DatesList
          :items="dateItems"
          :scheduler-ctx="schedulerCtx"
          :on-unassigned-block-drop="onUnassignedBlockDrop"
          :on-assigned-block-drop="onAssignedBlockDrop"
        />
      </div>
    </div>
  </AppAccordion>
</template>
