import type { AssignedIssue, DateInterval, UnassignedIssue }            from "@/components/ThePlanner/types";
import { useIssueService }                                              from "@/service";
import type { CreateIssueParams, DeleteIssueParams, UpdateIssueParams } from "@/service/Issue/types";
import type { HttpContext }                                             from "@/service/utils";
import type { Nullable }                                                from "@/types";
import { groupBy, map }                                                 from "@/utils";
import {
  useIssuesFetchProvider
}                                                                       from "@/views/PlannerView/use/useIssuesFetchProvider";
import { handleHttpEvents }                                             from "@/views/PlannerView/use/utils";
import { computed, shallowRef }                                         from "vue";

export type UseAssignedIssuesReturn = ReturnType<typeof useAssignedIssues>;

export type UseAssignedIssuesOptions = {};

export function useAssignedIssues(_options?: UseAssignedIssuesOptions) {
  // const issues = ref<AssignedIssue[]>([]);
  const _httpCreateCtx = shallowRef<Nullable<HttpContext<AssignedIssue>>>(null);
  const _httpUnassignCtx = shallowRef<Nullable<HttpContext<UnassignedIssue>>>(null);
  const _httpUpdateCtx = shallowRef<Nullable<HttpContext<AssignedIssue>>>(null);
  const _issueService = useIssueService();
  const {
    httpCtx: _httpFetchCtx,
    issues,
    fetch: _fetch
  } = useIssuesFetchProvider(
    "assigned",
    {
      service: _issueService
    }
  );

  const issuesGroupedByRedmineId = computed(() => {
    const collection = map(
      issues.value,
      (issue, index) => {
        return { issue, index };
      }
    );

    return groupBy(
      collection,
      item => item.issue.redmine_id
    );
  });

  // const _httpFetchCtx = shallowRef<Nullable<HttpContext<AssignedIssue[]>>>(null);


  const isLoading = computed(() => {
    return (
      (_httpFetchCtx.value?.isFetching.value ?? false) ||
      (_httpCreateCtx.value?.isFetching.value ?? false) ||
      (_httpUpdateCtx.value?.isFetching.value ?? false) ||
      (_httpUnassignCtx.value?.isFetching.value ?? false)
    );
  });

  function fetch(dateInterval: DateInterval): Promise<Nullable<AssignedIssue[]>> {
    return _fetch({ dateInterval });
  }

  function create(params: CreateIssueParams): Promise<Nullable<AssignedIssue>> {
    return new Promise((resolve, _reject) => {
      _httpCreateCtx.value = _issueService.createAssignedIssue(params);

      handleHttpEvents({
        httpContext: _httpCreateCtx.value,
        onResponseOK({ data }) {
          if ( data.value ) {
            issues.value.push(data.value);
          }

          resolve(data.value);
        },
        onError(_ctx) {
          resolve(null);
        },
        onFinally(_ctx) {
          _httpCreateCtx.value = null;
        }
      });
    });
  }

  function unassign(params: DeleteIssueParams): Promise<boolean> {
    return new Promise((resolve, _reject) => {
      const { index = -1 } = issuesGroupedByRedmineId.value.get(params.issueRedmineId)?.[0] ?? {};

      if ( index < 0 ) {
        return resolve(false);
      }

      _httpUnassignCtx.value = _issueService.deleteAssignedIssue(params);

      handleHttpEvents({
        httpContext: _httpUnassignCtx.value,
        onResponse({ response }) {
          issues.value = issues.value.filter(
            issue => issue.redmine_id !== params.issueRedmineId
          );

          resolve(response.value!.ok);
        },
        onError(_ctx) {
          resolve(false);
        },
        onFinally(_ctx) {
          _httpUnassignCtx.value = null;
        }
      });

      return _httpUnassignCtx.value;
    });
  }

  function update(params: UpdateIssueParams): Promise<Nullable<AssignedIssue>> {
    return new Promise((resolve, _reject) => {
      const { index = -1 } = issuesGroupedByRedmineId.value.get(params.issueRedmineId)?.[0] ?? {};

      if ( index < 0 ) {
        resolve(null);
      }

      _httpUpdateCtx.value = _issueService.updateAssignedIssue(params);

      handleHttpEvents({
        httpContext: _httpUpdateCtx.value,
        onResponse(ctx) {
          issues.value.splice(index, 1, ctx.data.value!);
          resolve(ctx.data.value);
        },
        onError(_ctx) {
          resolve(null);
        },
        onFinally(_ctx) {
          _httpUpdateCtx.value = null;
        }
      });
    });
  }

  function setIssues(issues_: AssignedIssue[]) {
    issues.value = issues_;
  }

  return {
    issues,
    issuesGroupedByRedmineId,
    isLoading,
    fetch,
    setIssues,
    create,
    unassign,
    update
  };
}
