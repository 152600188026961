<script setup lang="ts">
import { TheDatepickerBase }           from "@/components";
import { type TheDatepickerBaseProps } from "@/components/inputs/TheDatepickerBase";
import type { Nullable }               from "@/types";
import { format }                      from "@/utils/datetimeFormatters";
import { merge }                       from "lodash-es";
import { computed }                    from "vue";

const props = defineProps<{
  modelValue: Nullable<Date>;
  config?: TheDatepickerBaseProps;
}>();

const _modelValue = defineModel<Nullable<Date>>();
const _config = computed<TheDatepickerBaseProps>(() => {
  return merge<TheDatepickerBaseProps, TheDatepickerBaseProps>(
    {
      hideNavigation: ["time"],
      format(date: Date | null) {
        if ( !date ) return "";
        return format(date, "E d.M.yyyy");
      }
    },
    props.config ?? {}
  );
});
</script>

<template>
  <TheDatepickerBase
    v-model="_modelValue"
    :config="_config"
  />
</template>
