import type { UserBlockItem }                     from "@/components/ThePlanner/components/TheWrike/types";
import type { AssignedIssue, DateInterval, User } from "@/components/ThePlanner/types";
import { groupBy, map }                           from "@/utils";
import { compareAsc, isWithinInterval }           from "date-fns";
import { computed, type Ref }                     from "vue";

export type UseUserBlockItemsOptions = {
  users: Ref<User[]>;
  dateInterval: Ref<DateInterval>;
  issues: Ref<AssignedIssue[]>;
};

export type UseUserBlockItemsReturn = ReturnType<typeof useUserBlockItems>;

export function useUserBlockItems(options: UseUserBlockItemsOptions) {
  const issuesWithinInterval = computed<AssignedIssue[]>(() => {
    const _dateInterval = options.dateInterval.value;
    const _issues = options.issues.value;

    return _issues
      .filter(
        (issue) =>
          isWithinInterval(issue.start_date_time, _dateInterval) ||
          isWithinInterval(issue.end_date_time, _dateInterval)
      ).sort(
        (a, b) => compareAsc(
          a.start_date_time,
          b.start_date_time
        )
      );
  });

  const issuesGroupedByUserRedmineID = computed(() => {
    return groupBy(
      issuesWithinInterval.value,
      (issue) => issue.assigned_to_planner.redmine_id
    );
  });

  const blocks = computed<UserBlockItem[]>(() => {
    return map(options.users.value, (user, index) => {
      const issues = issuesGroupedByUserRedmineID.value.get(user.redmine_id) ?? [];

      return {
        id: user.redmine_id,
        index,
        user,
        issues
      };
    });
  });

  return {
    blocks
  };
}
