import type { SchedulerBlock } from "@/components/ThePlanner/components/TheGrid/types";
import type { DateInterval }   from "@/components/ThePlanner/types";
import { isWithinInterval }    from "date-fns";

export function computeBlockLevelIndex(
  blockInterval: DateInterval,
  blockIndex: number,
  blocks: ReadonlyArray<SchedulerBlock>
): number {
  if ( !blocks.length || blockIndex === 0 ) {
    return 0;
  }

  const blockIndexesMap = new Map<number, number>();
  let maxBlockIndex = 0;

  for ( let i = 0; i < blockIndex; i++ ) {
    const currentBlock = blocks[i]!;
    const withinInterval = isWithinInterval(
      blockInterval.start,
      {
        start: currentBlock.issue.start_date_time,
        end: currentBlock.issue.end_date_time
      }
    );

    if ( !withinInterval ) {
      continue;
    }

    if ( currentBlock.levelIndex > maxBlockIndex ) {
      maxBlockIndex = currentBlock.levelIndex;
    }

    blockIndexesMap.set(currentBlock.levelIndex, currentBlock.levelIndex);
  }

  for ( let i = 0; i <= maxBlockIndex; i++ ) {
    if ( !blockIndexesMap.has(i) ) {
      return i;
    }
  }

  return maxBlockIndex + 1;
}
