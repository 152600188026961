import type EventBlock                            from "@/components/ThePlanner/components/EventBlock/EnventBlock.vue";
import type {
  TimelineCtx
}                                                 from "@/components/ThePlanner/components/TheGrid/composables/useTimeline";
import type { GridRow, SchedulerBlock }           from "@/components/ThePlanner/components/TheGrid/types";
import { calculateRowOffset }                     from "@/components/ThePlanner/components/TheGrid/utils";
import {
  BLOCK_EVENT_HEIGHT,
  BLOCK_EVENT_HEIGHT_DETAILED_INFO,
  GRID_ROW_EVENT_BLOCKS_GAP,
  GRID_ROW_MIN_HEIGHT,
  GRID_ROW_PADDING_BOTTOM,
  GRID_ROW_PADDING_TOP
}                                                 from "@/components/ThePlanner/config";
import type { AssignedIssue, DateInterval }       from "@/components/ThePlanner/types";
import { createRowBlockEvents }                   from "@/components/ThePlanner/utils";
import type { ReadonlyRef }                       from "@/types";
import { compareAsc, isWithinInterval }           from "date-fns";
import { groupBy }                                from "lodash-es";
import type { Ref }                               from "vue";
import { computed, markRaw, ref, toValue, watch } from "vue";

export type UseSchedulerBlocksOptions = {
  rows: ReadonlyRef<GridRow[]>;
  issues: ReadonlyRef<Readonly<AssignedIssue[]>>;
  dateInterval: ReadonlyRef<DateInterval>;
  timelineCtx: TimelineCtx;
  isDetailedInfo: Readonly<Ref<boolean>>;
  issuesHighlightedRedmineIds: ReadonlyRef<Readonly<number[]>>;
};

export type UseSchedulerBlocksReturn = ReturnType<typeof useSchedulerBlocks>;

export function useSchedulerBlocks(options: UseSchedulerBlocksOptions) {
  const blocks = ref<SchedulerBlock[]>([]);
  const eventBlocksRefs = ref(new Map<string, InstanceType<typeof EventBlock>>());

  const {
    issuesHighlightedRedmineIds,
    issues,
    rows,
    dateInterval,
    timelineCtx,
    isDetailedInfo
  } = options;

  const issuesWithinInterval = computed(() => {
    const _dateInterval = toValue(dateInterval);
    const _issues = toValue(issues);

    return _issues
      .filter(
        (issue: AssignedIssue) =>
          isWithinInterval(issue.start_date_time, _dateInterval) ||
          isWithinInterval(issue.end_date_time, _dateInterval)
      ).sort(
        (a, b) => compareAsc(
          a.start_date_time,
          b.start_date_time
        )
      );
  });

  const groupedIssuesByUserId = computed(() => {
    return groupBy<AssignedIssue>(
      issuesWithinInterval.value,
      issue => issue.assigned_to_planner.redmine_id
    );
  });

  watch(
    () =>
      [
        issuesWithinInterval.value,
        rows.value,
        timelineCtx.dayItemWidth.value,
        isDetailedInfo.value,
        issuesHighlightedRedmineIds.value
      ] as [AssignedIssue[], GridRow[], number, boolean, number[]],
    ([
       _issues = [],
       rows = [],
       _dayItemWidth = 0, /* if user resize the browser */
       blocksDetailedInfo = false, /* if detailed info is enabled */
       highlightedIssuesRedmineIds = []
     ]) => {
      const blocks_: SchedulerBlock[] = [];

      for ( let i = 0; i < rows.length; i++ ) {
        const row = rows[i]!;
        const issuesToRow = groupedIssuesByUserId.value[row.user.redmine_id] ?? [];

        const blocksToRow: SchedulerBlock[] = createRowBlockEvents(
          i,
          rows as GridRow[],
          row.user,
          issuesToRow,
          timelineCtx,
          blocksDetailedInfo,
          highlightedIssuesRedmineIds
        );

        blocks_.push(...blocksToRow);

        const blocksCount = blocksToRow.length;
        const maxBlockLevelIndex = Math.max(...blocksToRow.map(block => block.levelIndex), 0);
        const height = blocksCount === 0 ?
                       GRID_ROW_MIN_HEIGHT :
                       (
                         GRID_ROW_PADDING_TOP +
                         ((blocksDetailedInfo ? BLOCK_EVENT_HEIGHT_DETAILED_INFO : BLOCK_EVENT_HEIGHT) * (maxBlockLevelIndex + 1)) +
                         (maxBlockLevelIndex * GRID_ROW_EVENT_BLOCKS_GAP) +
                         GRID_ROW_PADDING_BOTTOM
                       );

        row.offset = calculateRowOffset(i, height, rows);
        row.eventBlocksCount = blocksCount;
        row.eventBlocks = blocksToRow;
        row.height = height;
        row.maxBlockLevelIndex = maxBlockLevelIndex;
      }

      blocks.value = blocks_;
    },
    { immediate: true }
  );

  function updateEventBlockRef(block: SchedulerBlock, elRef: InstanceType<typeof EventBlock> | null) {
    const id = block.id;

    if ( elRef ) {
      eventBlocksRefs.value.set(id, markRaw(elRef));
    } else {
      eventBlocksRefs.value.delete(id);
    }
  }

  return {
    blocks,
    eventBlocksRefs,
    updateEventBlockRef
  };
}
