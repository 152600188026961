<script setup lang="ts">
import ResizeableContainer from "@/components/ThePlanner/components/ResizeableContainer/App.vue";
import SidebarBody         from "@/components/ThePlanner/components/TheGrid/components/SidebarBody.vue";
import type { GridCtx }    from "@/components/ThePlanner/components/TheGrid/composables";
import { computed }        from "vue";
import TheScheduler        from "./TheScheduler.vue";

defineOptions({
  name: "GridBody"
});

const { gridCtx } = defineProps<{ gridCtx: GridCtx; }>();
const {
  splitterState,
  // plannerCtx,
  contentHeight,
  bodyEl
} = gridCtx;

const splitterButtonsOffset = computed(() => {
  return (gridCtx.bodyElSize.height.value / 2) + gridCtx.bodyElScroller.y.value;
});
</script>

<template>
  <div
    ref="bodyEl"
    class="the-grid-body overflow-y-auto h-full relative"
  >
    <div
      class="w-full min-h-full"
      :style="{ height: `${ contentHeight }px`}"
    >
      <ResizeableContainer
        :splitter-buttons-offset="splitterButtonsOffset"
        locked-section="left"
        v-model:state="splitterState.state"
        v-model:highlighted="splitterState.highlighted"
        v-model:locked-section-width="splitterState.width"
        @resizing-start="splitterState.highlighted = true"
      >
        <template #content-left>
          <SidebarBody :grid-ctx="gridCtx" />
        </template>

        <template #content-right>
          <TheScheduler :grid-ctx="gridCtx" />
        </template>
      </ResizeableContainer>
    </div>
  </div>
</template>
