<script setup lang="ts">
import { TheLoader }                                             from "@/components";
import { ModalIssueDetail }                                      from "@/components/modals/IssueDetail";
import { IssuesStack, ResizeableContainer, TheGrid, TheTooltip } from "@/components/ThePlanner/components";
import EventBlock
                                                                 from "@/components/ThePlanner/components/EventBlock/EnventBlock.vue";
import BlockContextMenu
                                                                 from "@/components/ThePlanner/components/TheGrid/components/BlockContextMenu.vue";
import { TheToolbar }                                            from "@/components/ThePlanner/components/TheToolbar";
import TheWrike
                                                                 from "@/components/ThePlanner/components/TheWrike/TheWrike.vue";
import { usePlanner }                                            from "@/components/ThePlanner/composables/usePlanner";
import type { ThePlannerProps2 }                                 from "@/components/ThePlanner/types";
import { computed, shallowRef }                                  from "vue";

const props = defineProps<ThePlannerProps2>();

const plannerCtx = usePlanner({
  config: props.config
});

const {
  unassignedDraggedBlockCtx,
  blockContextMenuCtx,
  tooltipCtx,
  issueModalDetailCtx,
  containerEl,
  contentEl,
  viewType
} = plannerCtx;

const currentViewComponent = computed(() => {
  return {
    timeline: TheGrid,
    wrike: TheWrike
  }[viewType.value];
});

const currentViewInstance = shallowRef<
  InstanceType<typeof TheGrid> | InstanceType<typeof TheWrike> | null
>(null);

function scrollViewTo(date: Date) {
  currentViewInstance.value?.scrollViewTo(date);
}

defineExpose<{
  scrollViewTo: typeof scrollViewTo;
}>({
  scrollViewTo
});
</script>

<template>
  <div
    ref="containerEl"
    class="the-planner w-full h-full bg-white border border-solid border-gray-300"
    :class="{
      'top-0 left-0 fixed w-screen h-screen': plannerCtx.isFullscreenView.value
    }"
  >
    <div class="h-full w-full vue-loader-container">
      <ResizeableContainer
        locked-section="right"
        :locked-section-width="plannerCtx.unassignedIssuesContainerWidth.value"
      >
        <template #content-left>
          <div class="flex flex-col flex-nowrap">
            <div class="flex-grow-0 flex-shrink-0 border-b border-gray-300">
              <TheToolbar :planner-ctx="plannerCtx" />
            </div>

            <div class="flex-1" ref="contentEl">
              <component
                :is="currentViewComponent"
                ref="currentViewInstance"
                :planner-ctx="plannerCtx"
              />
            </div>
          </div>
        </template>

        <template #content-right>
          <IssuesStack
            :planner-ctx="plannerCtx"
          />
        </template>
      </ResizeableContainer>

      <TheLoader
        :visible="plannerCtx.loaders.global?.value"
      />
    </div>

    <!--TO BODY TELEPORTS-->
    <teleport to="body">
      <TheTooltip
        :ref="tooltipCtx.setTooltipComponentRef"
        :visible="tooltipCtx.state.visible"
        :position="tooltipCtx.state.position"
        :style="{
          width: tooltipCtx.widthNormalizedValue.value,
          height: tooltipCtx.heightNormalizedValue.value
        }"
        :class-value="tooltipCtx.state.classList"
      >
        <component
          :is="tooltipCtx.state.component"
          v-bind="tooltipCtx.state.componentProps"
        />
      </TheTooltip>
    </teleport>

    <teleport to="body">
      <!--  ISSUE DETAIL MODAL -->
      <template v-if="issueModalDetailCtx.issue.value">
        <ModalIssueDetail
          v-model:open="issueModalDetailCtx.isOpened.value"
          :planner-ctx="plannerCtx"
          :redmine-id="issueModalDetailCtx.issue.value.redmine_id"
          :users="plannerCtx.users.value"
          @open="issueModalDetailCtx.onModalOpenHandler()"
          @close="issueModalDetailCtx.onModalCloseHandler()"
        />
      </template>

      <!--  CONTEXT MENU  -->
      <BlockContextMenu :ctx="blockContextMenuCtx" />

      <EventBlock
        :z-index="1000"
        class="cursor-move"
        :ref="unassignedDraggedBlockCtx.setEventBlockInstance"
        style="position: fixed;"
        v-if="unassignedDraggedBlockCtx.isVisible.value && unassignedDraggedBlockCtx.block.value"
        :position="unassignedDraggedBlockCtx.block.value.position"
        :height="unassignedDraggedBlockCtx.block.value.height"
        :width="unassignedDraggedBlockCtx.block.value.width"
        :type="unassignedDraggedBlockCtx.block.value.type"
      >
        <div class="w-full h-full text-sm pl-2 flex items-center">
          <div class="inline-block text-nowrap whitespace-nowrap">
            #{{ unassignedDraggedBlockCtx.block.value!.issue.redmine_id }}
          </div>
        </div>
      </EventBlock>
    </teleport>
  </div>
</template>
