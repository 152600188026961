<script setup lang="ts">
import { SimpleSelect, type SimpleSelectOption } from "@/components";

defineProps<{ modelValue: number }>();
defineEmits<{ "update:modelValue": [number] }>();
const _modelValue = defineModel<number>({ required: true });
const _options = [
  {
    id: "2",
    value: 2,
    label: "2"
  },
  {
    id: "3",
    value: 3,
    label: "3"
  },
  {
    id: "4",
    value: 4,
    label: "4"
  }
] as SimpleSelectOption<number>[];
</script>

<template>
  <SimpleSelect :options="_options" v-model="_modelValue" />
</template>
