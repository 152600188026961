<script setup lang="ts">
import { AppIcon }                               from "@/components";
import UserSelectMultiple                        from "@/components/inputs/UserSelectMultiple/UserSelectMultiple.vue";
import { SelectDataViewType }                    from "@/components/ThePlanner/components";
import { SelectWrikeColumnsCount }               from "@/components/ThePlanner/components/SelectWrikeColumnsCount";
import TheDateRangePicker                        from "@/components/ThePlanner/components/TheDateRangePicker/App.vue";
import type { PlannerCtx }                       from "@/components/ThePlanner/composables/usePlanner";
import { type ComponentInstance, computed, ref } from "vue";

defineOptions({
  name: "PlannerToolbar"
});

const props = defineProps<{ plannerCtx: PlannerCtx }>();

const {
  dateInterval,
  isFullscreenView,
  isDetailedInfo,
  usersSelected,
  users,
  wrikeColumnsCount,
  viewType,
  setViewType,
  setWrikeColumnsCount,
  onRefreshContentData,
  onUserSelectHandler,
  onUserDeselectHandler,
  toggleFullscreenView,
  toggleDetailedInfo,
  goNextDateInterval,
  goPreviousDateInterval,
  goCurrentDateInterval
} = props.plannerCtx;

const datepickerRef = ref<ComponentInstance<typeof TheDateRangePicker> | null>(null);
const visibleSelectWrikeColumnsCount = computed(() => {
  return viewType.value === "wrike";
});

</script>

<template>
  <div class="the-planner-toolbar bg-gray-100 w-full h-full">
    <!-- Content wrapper -->
    <div class="relative h-full w-full pl-2 pr-2 py-1.5">
      <div class="grid grid-cols-1 grid-rows-2 gap-1 mr-24 h-full">
        <div class="flex gap-1.5">

          <div class="w-36 flex-shrink-0">
            <label class="flex gap-1 items-center h-full">
              <AppIcon name="circle-stack" class="text-gray-400" size="md" />
              <SelectDataViewType
                style="--ms-py: auto;"
                class="h-full"
                :model-value="viewType"
                @update:model-value="setViewType"
              />
            </label>
          </div>

          <div class="w-36 flex-shrink-0" v-show="visibleSelectWrikeColumnsCount">
            <label
              class="flex gap-1 items-center h-full"
            >
              <AppIcon name="table-cells" size="md" class="text-gray-400" />
              <SelectWrikeColumnsCount
                style="--ms-py: auto; --ms-px: 0.5rem;"
                class="h-full"
                :model-value="wrikeColumnsCount"
                @update:model-value="setWrikeColumnsCount"
              />
            </label>
          </div>

          <div class="flex-shrink-0 h-full">
            <label class="flex gap-1.5 h-full items-center">
              <span class="text-gray-400 text-xs select-none">Detailní informace</span>
              <span class="">
              <input
                type="checkbox"
                :value="isDetailedInfo"
                @input="toggleDetailedInfo(($event.target as HTMLInputElement).checked)">
            </span>
            </label>
          </div>
        </div>

        <div class="flex gap-1.5 text-gray-400 items-center">
          <div class="w-64 flex-shrink-0 h-full">
            <label class="flex justify-center gap-1 items-center h-full">
              <AppIcon name="users" size="md" class="text-gray-400" />
              <UserSelectMultiple
                class="flex-0 h-full"
                style="--ms-py: auto;"
                :model-value="usersSelected"
                :users-list="users"
                @select="onUserSelectHandler"
                @deselect="onUserDeselectHandler"
              />
            </label>
          </div>

          <div class="flex-shrink-0">
            <div class="flex items-center gap-1 justify-between text-gray-400 text-xs">
              <TheDateRangePicker
                ref="datepickerRef"
                class="w-72"
                v-model="dateInterval"
                @go-next-date-interval="goNextDateInterval()"
                @go-previous-date-interval="goPreviousDateInterval()"
              />

              <div
                class="hover:text-blue-400 cursor-pointer uppercase underline flex gap-0.5 items-center"
                @click="goCurrentDateInterval()"
              >
                <AppIcon name="clock" />
                <div>
                  dnes
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute top-0 right-0 flex pr-1 gap-0.5">
        <button
          class="p-1 transition hover:text-blue-600"
          @click="onRefreshContentData()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
        </button>

        <button
          class="p-1 transition hover:text-blue-600"
          @click="toggleFullscreenView()"
        >
          <AppIcon
            :name="!isFullscreenView ? 'arrows-pointing-out' : 'arrows-pointing-in'"
          />
        </button>
      </div>
    </div>
  </div>
</template>
