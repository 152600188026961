<script setup lang="ts">
import type { SimpleInputEmits, SimpleInputProps } from "@/components/inputs/SimpleInput/types";
import { useVModel }                               from "@vueuse/core";
import { computed, ref }                           from "vue";

defineOptions({
  name: "TextInput",
  inheritAttrs: false
});


const props = withDefaults(defineProps<SimpleInputProps>(), {
  type: "text"
});
const emit = defineEmits<SimpleInputEmits>();


const inputRef = ref<HTMLInputElement | null>(null);
const mv = useVModel(props, "modelValue", emit);
const isEmpty = computed(() => !props.modelValue);

const classDefs = computed<Record<string, boolean>>(() => {
  return {
    "simple-text-input--is-empty": isEmpty.value
  };
});

defineExpose<{ focus: typeof focus, inputEl: typeof inputRef }>({
  focus,
  inputEl: inputRef
});

function focus() {
  inputRef.value?.focus();
}

function clearModelValueHandler() {
  mv.value = "";
  emit("clear");
}
</script>

<template>
  <div
    class="simple-text-input"
    :class="classDefs"
  >
    <input
      ref="inputRef"
      v-model="mv"
      :placeholder="placeholder"
      :type="type"
      v-bind="$attrs"
      :class="inputClass"
      class="simple-text-input__input"
    >

    <div
      class="simple-text-input__icon"
    >
      <!--      <div v-show="isEmpty">-->
      <!--        <slot name="icon" />-->
      <!--      </div>-->

      <template v-if="!disableClearIcon">

        <div
          v-show="!isEmpty"
          @click="clearModelValueHandler">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </div>
      </template>
    </div>

  </div>
</template>

<style lang="postcss">
.simple-text-input {
  @apply overflow-hidden w-full relative placeholder-gray-400 text-gray-600;
}

.simple-text-input__input {
  @apply
  w-full
  border
  border-solid
  border-gray-300
  rounded
  pl-3
  pr-6
  py-2
  focus:border-gray-400
  hover:border-gray-400
  text-xs
  outline-none;
}

.simple-text-input__icon {
  @apply text-gray-500 hover:text-gray-700 cursor-pointer absolute top-1/2 right-1 -translate-y-1/2;
}
</style>
