import type { Issue }                from "@/components/ThePlanner/types";
import { useIssueService }           from "@/service";
import type { HttpContext }          from "@/service/utils";
import type { Nullable }             from "@/types";
import { handleHttpEvents }          from "@/views/PlannerView/use/utils";
import { computed, ref, shallowRef } from "vue";

export type UseIssueDetailReturn = ReturnType<typeof useIssueDetail>;

export type UseIssueDetailOptions = {};

export function useIssueDetail(_options?: UseIssueDetailOptions) {
  const _httpContext = shallowRef<Nullable<HttpContext<Issue>>>(null);
  const _issueService = useIssueService();
  const issue = ref<Nullable<Issue>>(null);
  const isLoading = computed(() => {
    return _httpContext.value?.isFetching.value ?? false;
  });

  function fetch(redmineId: number): Promise<Nullable<Issue>> {
    return new Promise(resolve => {
      if ( _httpContext.value?.canAbort.value ) {
        _httpContext.value.abort();
      }

      _httpContext.value = _issueService.getIssue(redmineId.toString());

      handleHttpEvents({
        httpContext: _httpContext.value,
        onError(_ctx) {
          issue.value = null;
          resolve(null);
        },
        onResponse(ctx) {
          issue.value = ctx.data.value;
          resolve(issue.value);
        },
        onFinally(ctx) {
          if ( ctx === _httpContext.value ) {
            _httpContext.value = null;
          }
        }
      });
    });
  }

  function clear() {
    issue.value = null;
  }

  const issues = computed<Issue[]>(() => {
    return [issue.value].filter(Boolean) as Issue[];
  });

  return {
    issue,
    issues,
    isLoading,

    fetch,
    clear
  };
}
