import type { TimelineCtx }             from "@/components/ThePlanner/components/TheGrid/composables";
import type { BlockBase, DateInterval } from "@/components/ThePlanner/types";
import { roundToNearestMinutes }        from "date-fns";

export type ComputeBlockDateIntervalOptions = {
  startRoundTo?: boolean;
  endRoundTo?: boolean;
  basedOn?: "width" | "estimated-hours"
};

export function computeBlockDateInterval(
  block: BlockBase,
  timelineCtx: TimelineCtx,
  options?: ComputeBlockDateIntervalOptions
): DateInterval {
  const { position, issue } = block;
  const {
    startRoundTo = true,
    endRoundTo = true,
    basedOn = "estimated-hours"
  } = options ?? {};
  let width = 0;

  switch ( basedOn ) {
    case "width" :
      width = block.width;
      break;
    case "estimated-hours":
      width = Math.max(
        Math.ceil((issue.estimated_hours ?? 0) * timelineCtx.hourItemWidth.value),
        timelineCtx.hourItemWidth.value
      );
      break;
  }

  const start = startRoundTo ?
                roundToNearestMinutes(
                  timelineCtx.offsetToDate(position.x),
                  { nearestTo: 15, roundingMethod: "floor" }
                ) :
                timelineCtx.offsetToDate(position.x);

  const end = endRoundTo ?
              roundToNearestMinutes(
                timelineCtx.offsetToDate(position.x + width),
                { nearestTo: 15, roundingMethod: "floor" }
              ) :
              timelineCtx.offsetToDate(position.x + width);

  return {
    start,
    end
  };
}
