import type { EnumerableKey } from "@/types";

export type GroupByKeyGetter<T, K extends EnumerableKey = EnumerableKey> = (item: T) => K;

export type GroupByOptions = {
  eliminateDuplicities?: boolean;
}

export type GroupByResult<TKey extends EnumerableKey, TItem> = ReturnType<typeof groupBy<TItem, TKey>>;

export function groupBy<
  TItem,
  TKey extends EnumerableKey
>(
  collection: Readonly<TItem[]>,
  groupByKey: GroupByKeyGetter<TItem, TKey>,
  options?: GroupByOptions
) {
  const { eliminateDuplicities = true } = options ?? {};
  const map: Map<
    ReturnType<GroupByKeyGetter<TItem, TKey>>,
    TItem[]
  > = new Map();

  for ( let i = 0, len = collection.length; i < len; i++ ) {
    const item = collection[i]!;
    const key = groupByKey(item);

    const storedValues: TItem[] = map.get(key) ?? [];
    storedValues.push(item);

    map.set(
      key,
      eliminateDuplicities ?
      Array.from(new Set(storedValues)) :
      storedValues
    );
  }

  return map;
}
