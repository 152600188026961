import {
  useGridHoveredRowHandler,
  useGridRows,
  useGridSidebar,
  useScheduler,
  useTimeline
}                                                        from "@/components/ThePlanner/components/TheGrid/composables";
import type { GridRow }                                  from "@/components/ThePlanner/components/TheGrid/types";
import type { PlannerCtx }                               from "@/components/ThePlanner/composables/usePlanner";
import { GRID_USERS_SECTION_WIDTH_PERCENTAGE }           from "@/components/ThePlanner/config";
import type { SplitterState }                            from "@/components/ThePlanner/types";
import { isPositionInsideArea }                          from "@/components/ThePlanner/utils";
// import { isMousePositionInsideElement }                  from "@/components/ThePlanner/utils";
import { useElementBounding, useElementSize, useScroll } from "@vueuse/core";
import { computed, onMounted, ref }                      from "vue";

export type UseGrid2Options = {
  plannerCtx: PlannerCtx;
};

export type GridCtx = ReturnType<typeof useGrid>;

export function useGrid(options: UseGrid2Options) {
  const { plannerCtx } = options;

  /*Container*/
  const containerEl = ref<HTMLElement | null>(null);
  const contentEl = ref<HTMLElement | null>(null);
  const headerEl = ref<HTMLElement | null>(null);
  const bodyEl = ref<HTMLElement | null>(null);
  const bodyElSize = useElementSize(bodyEl);
  const bodyElBounding = useElementBounding(bodyEl);
  const bodyElScroller = useScroll(bodyEl);

  /*Spliter*/
  const splitterState = ref<SplitterState>({
    state: "default",
    width: 150,
    highlighted: false
  });

  /*Grid rows*/
  const rows = useGridRows({
    users: plannerCtx.usersSelected
  });
  const hoveredRow = ref<GridRow | null>(null);

  /*Parts*/
  const timelineCtx = useTimeline({
    dateInterval: plannerCtx.dateInterval
  });
  const sidebarCtx = useGridSidebar({});
  const schedulerCtx = useScheduler({
    plannerCtx,
    timelineCtx,
    rows,
    hoveredRow
  });

  // Side effect - hoveredRow
  useGridHoveredRowHandler({
    rows,
    hoveredRow,
    sidebarCtx,
    schedulerCtx,
    isMousePositionInsideGridBodyEl: _isMousePositionInsideGridBodyElement,
    setHoveredRow: (row) => {
      hoveredRow.value = row;
    }
  });

  const contentHeight = computed(() => {
    return schedulerCtx.contentHeight.value;
  });

  // (-) from side to side
  const isScrollbarHorizontal = computed(() => {
      return (
        sidebarCtx.isScrollbarHorizontal.value ||
        schedulerCtx.isScrollbarHorizontal.value
      );
    }
  );

  // (|) up and down
  const isScrollbarVertical = computed(() => {
      return schedulerCtx.contentHeight.value > bodyElSize.height.value;
    }
  );

  // LIFECYCLE
  onMounted(() => {
    splitterState.value.width = Math.max(
      (containerEl.value?.getBoundingClientRect().width ?? 0) * GRID_USERS_SECTION_WIDTH_PERCENTAGE,
      splitterState.value.width
    );
  });

  function _isMousePositionInsideGridBodyElement(e: MouseEvent) {
    return isPositionInsideArea(e, bodyElBounding);
  }

  function scrollViewTo(date: Date) {
    let amount = timelineCtx.dateToOffset(date);

    schedulerCtx.containerScrollLeftValue.value = amount < 0 ?
                                                  0 :
                                                  amount > schedulerCtx.contentWidth.value ?
                                                  schedulerCtx.contentWidth.value :
                                                  amount;
  }

  return {
    containerEl,
    contentEl,
    headerEl,
    bodyEl,
    bodyElSize,
    bodyElScroller,
    sidebarCtx,
    timelineCtx,
    plannerCtx,
    isScrollbarVertical,
    isScrollbarHorizontal,
    splitterState,
    contentHeight,
    // contentWidth,
    rows,
    hoveredRow,
    schedulerCtx,

    scrollViewTo
  };
}
