import { TooltipContentBlockDetail }                       from "@/components/ThePlanner/components";
import type { TooltipContentIssueDetailProps }             from "@/components/ThePlanner/components/TheTooltip";
import type {
  UseSchedulerBlocksReturn
}                                                          from "@/components/ThePlanner/components/TheWrike/components/DateBlock/useSchedulerBlocks";
import type {
  SchedulerCtx
}                                                          from "@/components/ThePlanner/components/TheWrike/composables";
import type { SchedulerBlock }                             from "@/components/ThePlanner/components/TheWrike/types";
import type { DateInterval }                               from "@/components/ThePlanner/types";
import { calculateTooltipPosition, getPositionRelativeTo } from "@/components/ThePlanner/utils";
import { calculateDistance2Points }                        from "@/utils";
import { markRaw, nextTick }                               from "vue";
import type { Ref }                                        from "vue";

export type UseEventBlockHandlersOptions = {
  schedulerCtx: SchedulerCtx;
  dateInterval: Ref<DateInterval>;
} & Pick<
  UseSchedulerBlocksReturn,
  "blocks" |
  "eventBlocksRefs"
>;

export type UseEventBlockHandlers = ReturnType<typeof useEventBlockHandlers>;

export function useEventBlockHandlers(options: UseEventBlockHandlersOptions) {
  const {
    schedulerCtx,
    eventBlocksRefs
  } = options;

  const { tooltipCtx } = schedulerCtx.plannerCtx;

  function onMouseenter(block: SchedulerBlock, _e: MouseEvent) {
    tooltipCtx.state.classList = "max-w-[400px]";
    tooltipCtx.state.component = markRaw(TooltipContentBlockDetail);
    tooltipCtx.state.componentProps = {
      issue: block.issue
    } as TooltipContentIssueDetailProps;

    tooltipCtx.setVisible(true);

    nextTick(() => {
      tooltipCtx.state.position = calculateTooltipPosition({
        tooltipCtx,
        eventBlockEl: eventBlocksRefs.value.get(block.id)!.$el
      });
    });
  }

  function onMouseleave(_block: SchedulerBlock, _e: MouseEvent) {
    tooltipCtx.clear();
  }

  function onMousedown(block: SchedulerBlock, e: MouseEvent) {
    window.addEventListener("mousemove", _onWindowMousemove);

    // Clear the state
    window.addEventListener(
      "mouseup",
      () => {
        window.removeEventListener("mousemove", _onWindowMousemove);
      },
      { once: true }
    );

    function _onWindowMousemove(e2: MouseEvent) {
      const distance = calculateDistance2Points(e, e2);

      if ( distance > 3 ) {
        window.removeEventListener("mousemove", _onWindowMousemove);
        const el = eventBlocksRefs.value.get(block.id)!.el as HTMLElement;

        schedulerCtx.assignedDraggedBlockCtx.create({
          mouseEvent: e2,
          width: el.offsetWidth ?? 100,
          height: el.offsetHeight ?? 35,
          issue: block.issue,
          detailedInfo: block.isDetailedInfo,
          pointerOffset: getPositionRelativeTo(e2, el)
        });
      }
    }
  }

  function onMouseup(_block: SchedulerBlock, _e: MouseEvent) {

  }

  function onDblclick(block: SchedulerBlock, _e: MouseEvent) {
    options.schedulerCtx.plannerCtx.onAssignedIssueGoToRedmine(block.issue);
  }

  function onContextmenu(block: SchedulerBlock, e: MouseEvent) {
    e.preventDefault();
    options.schedulerCtx.plannerCtx.blockContextMenuCtx.show(block, e);
  }

  return {
    onDblclick,
    onMousedown,
    onMouseup,
    onMouseenter,
    onMouseleave,
    onContextmenu
  };
}
