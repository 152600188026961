<script setup lang="ts">
import { useScroll }  from "@vueuse/core";
import { ref, watch } from "vue";

defineOptions({
  name: "VirtualScroller"
});

const props = defineProps<{
  modelValue: number;
  width?: number;
}>();

const emit = defineEmits<{
  "update:modelValue": [number]
}>();

const el = ref<HTMLElement | null>(null);
const scroller = useScroll(
  el,
  {
    onScroll: (_e: Event) => {
      emit("update:modelValue", el.value!.scrollLeft);
    },
    eventListenerOptions: { passive: true }
  }
);

watch(
  () => props.modelValue,
  () => {
    scroller.x.value = props.modelValue;
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="virtual-scroller"
    ref="el"
  >
    <div
      class="virtual-scroller-inner"
      :style="{
        width: `${ width }px`
      }"
    ></div>
  </div>
</template>

<style scoped>
.virtual-scroller {
  width: 100%;
  overflow-x: auto;
}

.virtual-scroller-inner {
  height: 1px;
  transform: translateY(-1px);
}
</style>
