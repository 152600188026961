<script setup lang="ts">
import { AppAccordion }                             from "@/components";
import { NoDataMessage }                            from "@/components/ThePlanner/components";
import { TheEventBlock, TheEventBlockContentIssue } from "@/components/ThePlanner/components/EventBlock";
import type { DateBlockProps }                      from "@/components/ThePlanner/components/TheWrike/components";
import {
  useDateBlock
}                                                   from "@/components/ThePlanner/components/TheWrike/components/DateBlock/useDateBlock";
import { type ComponentInstance, toRef }            from "vue";

const props = defineProps<DateBlockProps>();

const {
  labelDate,
  labelDayOfWeek,
  issuesCount,
  blocks,
  containerEl,
  isHighlighted,
  eventBlockHandlers,
  updateEventBlockRef,
  onElementMouseenter,
  onElementMouseleave
} = useDateBlock({
  issues: toRef(props, "issues"),
  dateInterval: toRef(props, "dateInterval"),
  schedulerCtx: props.schedulerCtx,
  onUnassignedBlockDrop: props.onUnassignedBlockDrop,
  onAssignedBlockDrop: props.onAssignedBlockDrop
  // onSchedulerBlockDragStart: props.onSchedulerBlockDragStart,
  // onSchedulerBlockDrag: props.onSchedulerBlockDrag,
  // onSchedulerBlockDragEnd: props.onSchedulerBlockDragEnd
});
</script>

<template>
  <AppAccordion
    ref="containerEl"
    :model-value="true"
    header-class="p-0"
    disable-toggle-button
    @mouseenter="onElementMouseenter"
    @mouseleave="onElementMouseleave"
  >
    <template #header>
      <div
        class="flex justify-between items-center w-full px-2 py-1.5 border-b border-gray-300"
        :class="{
            'bg-fuchsia-300': today && !isHighlighted,
            'bg-gray-100': !isHighlighted && !today,
            'bg-amber-300': isHighlighted
          }"
      >
        <div class="flex gap-1 text-sm">
          <div class="text-sm">{{ labelDate }}</div>
          <div>-</div>
          <div class="text-sm uppercase italic font-semibold">{{ labelDayOfWeek }}</div>
        </div>

        <div class="bg-gray-500 w-6 h-6 rounded-full text-white text-xs flex justify-center items-center">
          {{ issuesCount }}
        </div>
      </div>
    </template>

    <div class="min-h-14 h-auto relative">
      <NoDataMessage v-if="!blocks.length" class="!py-1 text-sm" />

      <div v-else class="flex flex-col gap-1.5 p-2">
        <div
          v-for="block in blocks"
          :key="block.id"
          class="relative"
        >
          <TheEventBlock
            :ref="(el: ComponentInstance<typeof TheEventBlock>) => updateEventBlockRef(block, el)"
            min-height="35px"
            :height="block.height"
            class="cursor-pointer select-none"
            :class="{
                'is-dragging': block.id === schedulerCtx.assignedDraggedBlockCtx.block.value?.id
              }"
            :type="block.type"
            v-show="block.isVisible"
            @mouseenter="(e: MouseEvent) => eventBlockHandlers.onMouseenter(block,e)"
            @mouseleave="(e: MouseEvent) => eventBlockHandlers.onMouseleave(block,e)"
            @mousedown="(e: MouseEvent) => eventBlockHandlers.onMousedown(block,e)"
            @mouseup="(e: MouseEvent) => eventBlockHandlers.onMouseup(block,e)"
            @dblclick="(e: MouseEvent) => eventBlockHandlers.onDblclick(block,e)"
            @contextmenu="(e: MouseEvent) => eventBlockHandlers.onContextmenu(block,e)"
          >
            <TheEventBlockContentIssue
              :issue="block.issue"
              :detailed="block.isDetailedInfo"
              class="w-full h-full"
            />
          </TheEventBlock>
        </div>
      </div>
    </div>
  </AppAccordion>
</template>
