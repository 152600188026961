import type { WrikeCtx }                      from "@/components/ThePlanner/components/TheWrike/composables";
import type { UseDraggedBlockListenerParams } from "@/components/ThePlanner/composables";
import type { UnassignedIssue }               from "@/components/ThePlanner/types";
import { isPositionInsideArea }               from "@/components/ThePlanner/utils";
import type { Nullable }                                     from "@/types";
import { useElementBounding, type UseElementBoundingReturn } from "@vueuse/core";
import type { Ref }                                          from "vue";
import { onMounted, onUnmounted, ref }                       from "vue";

export type HandleUnassignedDraggedBlockCallbackParams = {
  containerElBounding: UseElementBoundingReturn;
  isInsideContainer: boolean;
  params: UseDraggedBlockListenerParams<UnassignedIssue>;
};

export type UseHandleUnassignedDraggedBlock = {
  containerEl: Ref<Nullable<HTMLElement>>;
  wrikeCtx: WrikeCtx;

  onDragstart?: (params: HandleUnassignedDraggedBlockCallbackParams) => void;
  onDragend?: (params: HandleUnassignedDraggedBlockCallbackParams) => void;
  onDrag?: (params: HandleUnassignedDraggedBlockCallbackParams) => void;
  onContainerElEnter?: (params: HandleUnassignedDraggedBlockCallbackParams) => void;
  onContainerElLeave?: (params: HandleUnassignedDraggedBlockCallbackParams) => void;
};

export type UseHandleUnassignedDraggedBlockReturn = ReturnType<typeof useHandleUnassignedDraggedBlock>;

export function useHandleUnassignedDraggedBlock(options: UseHandleUnassignedDraggedBlock) {
  const { wrikeCtx, containerEl } = options;
  const { plannerCtx } = wrikeCtx;
  const containerElBounding = useElementBounding(containerEl);
  const isInsideContainer = ref(false);

  onMounted(() => {
    const { unassignedDraggedBlockCtx } = plannerCtx;

    unassignedDraggedBlockCtx.addListener("start", _onUnassignedBlockDragstart);
  });

  onUnmounted(() => {
    clear();
  });

  function _onUnassignedBlockDragstart(params: UseDraggedBlockListenerParams<UnassignedIssue>) {
    plannerCtx.unassignedDraggedBlockCtx.addListener("move", _onUnassignedBlockDrag);
    plannerCtx.unassignedDraggedBlockCtx.addListener("end", _onUnassignedBlockDragend);
    options.onDragstart?.({
      containerElBounding,
      isInsideContainer: isInsideContainer.value,
      params
    });
  }

  function _onUnassignedBlockDragend(params: UseDraggedBlockListenerParams<UnassignedIssue>) {
    plannerCtx.unassignedDraggedBlockCtx.removeListener("move", _onUnassignedBlockDrag);
    plannerCtx.unassignedDraggedBlockCtx.removeListener("end", _onUnassignedBlockDragend);
    options.onDragend?.({
      containerElBounding,
      isInsideContainer: isInsideContainer.value,
      params
    });

    isInsideContainer.value = false;
  }

  function _onUnassignedBlockDrag(params: UseDraggedBlockListenerParams<UnassignedIssue>) {
    const _isInsideContainerOld = isInsideContainer.value;
    isInsideContainer.value = isPositionInsideArea(params.block.value!.position, containerElBounding);

    if (
      !_isInsideContainerOld && isInsideContainer.value
    ) {
      options.onContainerElEnter?.({
        containerElBounding,
        isInsideContainer: isInsideContainer.value,
        params
      });
    }

    if ( _isInsideContainerOld && !isInsideContainer.value ) {
      options.onContainerElLeave?.({
        containerElBounding,
        isInsideContainer: isInsideContainer.value,
        params
      });
    }

    options.onDrag?.({
      containerElBounding,
      isInsideContainer: isInsideContainer.value,
      params
    });
  }

  function clear() {
    plannerCtx.unassignedDraggedBlockCtx.removeListener("start", _onUnassignedBlockDragstart);
    plannerCtx.unassignedDraggedBlockCtx.removeListener("move", _onUnassignedBlockDrag);
    plannerCtx.unassignedDraggedBlockCtx.removeListener("end", _onUnassignedBlockDragend);
    isInsideContainer.value = false;
  }

  return {
    clear,
    isInsideContainer
  };
}
