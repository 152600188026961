import { HOUR_COLUMN_MIN_WIDTH }            from "@/components/ThePlanner/config";
import type { DateInterval }                from "@/components/ThePlanner/types";
import { datetimeFormatters }               from "@/utils";
import { useElementSize, useScroll }        from "@vueuse/core";
import {
  addDays,
  addHours,
  addMinutes,
  differenceInCalendarDays,
  differenceInMinutes,
  endOfDay,
  endOfHour,
  startOfDay
}                                           from "date-fns";
import { computed, type Ref, ref, toValue } from "vue";

export type UseTimelineOptions = {
  dateInterval: Ref<DateInterval>;
};


export type TimelineItemType = "day" | "hour";

export type TimelineItem = {
  id: string;
  nth: number;
  type: TimelineItemType;
  interval: DateInterval;
  label: string;
  title: string;
  width: number;
}

export type TimelineCtx = ReturnType<typeof useTimeline>;

export function useTimeline(options: UseTimelineOptions) {
  const containerEl = ref<HTMLElement | null>(null);
  const containerElSize = useElementSize(containerEl);
  const containerScroller = useScroll(containerEl);

  const containerScrollLeftValue = computed({
    get() {
      return containerScroller.x.value;
    },
    set(left) {
      containerScroller.x.value = left;
    }
  });

  const intervalStart = computed(() => {
    return startOfDay(toValue(options.dateInterval).start);
  });

  const intervalEnd = computed(() => {
    return endOfDay(toValue(options.dateInterval).end);
  });

  const daysCount = computed(() => {
    return Math.abs(differenceInCalendarDays(
      intervalEnd.value,
      intervalStart.value
    )) + 1;
  });

  const hourItemWidth = computed(() => {
    return Math.max(
      (toValue(containerElSize.width.value) / daysCount.value) / 24,
      HOUR_COLUMN_MIN_WIDTH
    );
  });

  const minuteItemWidth = computed(() => {
    return hourItemWidth.value / 60;
  });

  const dayItemWidth = computed(() => {
    return hourItemWidth.value * 24;
  });

  const dayItems = computed<TimelineItem[]>(
    () => {
      const items = Array.from<TimelineItem>({ length: daysCount.value });

      for (
        let idx = 0, currentDay = intervalStart.value;
        idx < daysCount.value;
        idx++, currentDay = addDays(currentDay, 1)
      ) {
        items[idx] = {
          id: currentDay.getTime().toString(),
          nth: idx,
          type: "day",
          width: dayItemWidth.value,
          title: datetimeFormatters.format(currentDay, "d.M.yyyy EEEE"),
          label: datetimeFormatters.format(currentDay, "d.M.yyyy E"),
          interval: {
            start: currentDay,
            end: endOfDay(currentDay)
          }
        };
      }

      return items;
    }
  );

  const hourItems = computed<TimelineItem[]>(() => {
    const items = Array.from<TimelineItem>(
      { length: dayItems.value.length * 24 }
    );
    let indexToPush = 0;

    dayItems.value.forEach((dayItem) => {
      createHourItems(dayItem, hourItemWidth.value).forEach(
        (hourItem) => {
          items[indexToPush++] = hourItem;
        }
      );
    });

    return items;
  });

  const hourItemsCount = computed(
    () => hourItems.value.length
  );

  const contentWidth = computed(
    () => daysCount.value * dayItemWidth.value
  );

  function dateToOffset(date: Date): number {
    const minutes = differenceInMinutes(date, intervalStart.value);
    return minutes * minuteItemWidth.value;
  }

  function offsetToDate(offset: number): Date {
    return addMinutes(intervalStart.value, offsetToMinutes(offset));
  }

  function offsetToMinutes(offset: number): number {
    return Math.ceil(offset / minuteItemWidth.value);
  }

  return {
    containerEl,
    contentWidth,
    containerScrollLeftValue,
    intervalStart,
    intervalEnd,
    daysCount,
    dayItemWidth,
    hourItemWidth,
    minuteItemWidth,
    dayItems,
    hourItems,
    hourItemsCount,
    offsetToDate,
    offsetToMinutes,
    dateToOffset
  };
}

function createHourItems(dayItem: TimelineItem, width: number): TimelineItem[] {
  return Array.from<unknown, TimelineItem>(
    { length: 24 },
    (_, index) => {

      const start = addHours(dayItem.interval.start, index);
      const end = endOfHour(start);

      return {
        id: `${ dayItem.id }__${ index }`,
        nth: index,
        interval: { start, end },
        label: index.toString(),
        title: `${ datetimeFormatters.formatTime(start) } - ${ datetimeFormatters.formatTime(end) }`,
        type: "hour",
        width
      };
    }
  );
}
