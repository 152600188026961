<script setup lang="ts">
import { SimpleInput }                    from "@/components";
import { parseRedmineID }                 from "@/utils";
import { useEventListener }               from "@vueuse/core";
import { isNumber }                       from "lodash-es";
import { computed, nextTick, ref, watch } from "vue";

defineOptions({
  name: "FormSearchIssue"
});

const emit = defineEmits<{
  submit: [{ redmineId: number | null, value: string | null }];
}>();

const _modelValueRedmineId = defineModel<string | null>("redmineId");
const _textInputRef = ref<InstanceType<typeof SimpleInput> | null>(null);
const _errorMsg = ref("");
const _isEmpty = computed(() => !_modelValueRedmineId.value);

useEventListener(window, "keydown", (e: KeyboardEvent) => {
  if ( e.ctrlKey && e.key === "k" ) {
    e.preventDefault();
    _textInputRef.value?.focus();
  }
});


watch(() => _modelValueRedmineId.value, (v) => {
  if ( !v ) {
    _errorMsg.value = "";
  }
});

async function submitHandler() {
  await nextTick();
  const redmineId = parseRedmineID(_modelValueRedmineId.value ?? "");
  _errorMsg.value = (_modelValueRedmineId.value ?? "").length && !isNumber(redmineId) ?
                    "Zadejte #123456 nebo 123456" :
                    "";

  emit("submit", { redmineId, value: _modelValueRedmineId.value ?? null });
}

function reset() {
  _modelValueRedmineId.value = null;
  _errorMsg.value = "";
}

defineExpose<{
  reset: typeof reset,
  setErrorMsg: (msg: string) => void;
}>({
  setErrorMsg: (msg: string) => {
    _errorMsg.value = msg;
  },
  reset
});
</script>

<template>
  <form @submit.prevent="submitHandler" class="flex items-center gap-2 group">
    <label
      for="search-issue-input"
      class="text-md text-gray-400 text-nowrap italic group-focus-within:text-pink-600"
      :class="{
        'text-pink-600': !_isEmpty
      }"
    >
      Hledat Issue
    </label>

    <div
      class="w-28 focus-within:w-64 relative"
      :class="{
        '!w-64': !_isEmpty
      }"
    >
      <div
        v-show="_errorMsg"
        class="absolute bottom-0 translate-y-full w-full text-xs text-red-600 italic z-10 text-center">
        {{ _errorMsg }}
      </div>
      <SimpleInput
        ref="_textInputRef"
        v-model="_modelValueRedmineId"
        autocomplete="off"
        placeholder="CTRL + K"
        id="search-issue-input"
        class="text-sm text-gray-700 font-semibold"
        :input-class="[
          'group-focus-within:border-pink-600 transition-all placeholder-gray-500 placeholder-opacity-40',
          {
            '!border-pink-600': !_isEmpty
          }
        ]"
        @clear="submitHandler"
      >
        <template #icon>
          <label for="search-issue-input">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
          </label>
        </template>
      </SimpleInput>
    </div>
  </form>
</template>
