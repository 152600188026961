import type { AssignedIssue } from "@/components/ThePlanner/types";
import type { Nullable }      from "@/types";
import { ref }                from "vue";

export function useModalIssueDetailCtx() {
  const isOpened = ref(false);
  const issue = ref<Nullable<AssignedIssue>>(null);

  function open(issue_: AssignedIssue) {
    issue.value = issue_;
    isOpened.value = true;
  }

  function close() {
    issue.value = null;
    isOpened.value = false;
  }

  function onModalOpenHandler() {

  }

  function onModalCloseHandler() {
    close();
  }

  return {
    isOpened,
    issue,
    open,
    close,
    onModalOpenHandler,
    onModalCloseHandler
  };
}
