<script setup lang="ts">
import ResizeableContainer from "@/components/ThePlanner/components/ResizeableContainer/App.vue";
import type { GridCtx }    from "@/components/ThePlanner/components/TheGrid/composables";
import SidebarHeader       from "./SidebarHeader.vue";
import TheTimeline         from "./TheTimeline.vue";

defineOptions({
  name: "GridHeader"
});

const { gridCtx } = defineProps<{ gridCtx: GridCtx }>();

const {
  splitterState,
  isScrollbarVertical,
  headerEl,
  timelineCtx
} = gridCtx;

</script>

<template>
  <div ref="headerEl" class="the-grid-header w-full h-full text-gray-700 font-medium bg-gray-100">
    <div class="w-full h-full flex flex-nowrap">
      <ResizeableContainer
        locked-section="left"
        v-model:state="splitterState.state"
        v-model:highlighted="splitterState.highlighted"
        v-model:locked-section-width="splitterState.width"
        @resizing-start="splitterState.highlighted = true"
        disable-collapsable
        disable-expandable
      >
        <template #content-left>
          <SidebarHeader
            :grid-ctx="gridCtx"
          />
        </template>
        <template #content-right>
          <TheTimeline
            :timeline-ctx="timelineCtx"
            class="flex-1 basis-0"
          />
        </template>
      </ResizeableContainer>
      <div
        v-show="isScrollbarVertical"
        class="scroll-x-gap border-l border-solid border-gray-300">
      </div>
    </div>
  </div>
</template>
