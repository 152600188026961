<script setup lang="ts">
import { SimpleInput, TheLoader }          from "@/components";
import { type HttpContext, useAppService } from "@/service";
import { useAuthStore }                    from "@/store";
import type { Nullable }                   from "@/types";
import { useNotification }                 from "@kyvg/vue3-notification";
import { ref, shallowRef }                 from "vue";
import { useRouter }                       from "vue-router";

defineOptions({
  name: "Login"
});

const router = useRouter();
const authStore = useAuthStore();
const loginHttpCtx = shallowRef<Nullable<HttpContext<{ token: string }>>>(null);
const username = ref(import.meta.env.DEV ? "test@olc.cz" : "");
const password = ref(import.meta.env.DEV ? "eEy7RLmeHnkrzu4V" : "");
const appService = useAppService();
const { notify } = useNotification();

function submitHandler() {
  loginHttpCtx.value = appService.login(username.value, password.value);

  const { onFetchResponse, onFetchError, response, data, error } = loginHttpCtx.value;

  onFetchResponse(() => {
    if ( response.value?.ok && !error.value && data.value?.token ) {
      authStore.login(data.value.token);
      router.push("/");
    }
  });

  onFetchError(() => {
    if ( response.value?.status === 400 ) {
      notify({
        type: "error",
        text: "Špatné přihlašovací údaje"
      });
    }
  });
}
</script>

<template>
  <div class="h-screen flex justify-center items-start pt-32">
    <div class="border-2 p-5 border-gray-200 shadow rounded w-[400px] vue-loader-container">
      <form @submit.prevent="submitHandler" class="text-sm flex flex-col gap-3">
        <div class="flex flex-col gap-2">
          <div>
            <h1 class="text-2xl text-gray-500 text-center">Přihlášení</h1>
          </div>

          <div class="flex flex-col gap-0.5">
            <label for="username" class="text-gray-500 font-semibold">
              Email:
            </label>

            <SimpleInput
              id="username"
              autofocus
              v-model="username"
              placeholder="Zadejte email ..."
            />
          </div>

          <div class="flex flex-col gap-0.5">
            <label for="password" class="text-gray-500 font-semibold">
              Heslo
            </label>

            <SimpleInput
              id="password"
              autocomplete="off"
              type="password"
              v-model="password"
              placeholder="Zadejte heslo ..."
            />
          </div>
        </div>

        <div class="">
          <button class="py-2 outline-0 w-full px-3 bg-blue-500 focus:bg-blue-600 hover:bg-blue-600 active:bg-blue-500 transition text-white rounded">
            Přihlásit
          </button>
        </div>
      </form>
      <TheLoader :visible="loginHttpCtx?.isFetching.value" />
    </div>
  </div>
</template>
