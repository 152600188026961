import { useAuthStore }                from "@/store";
import { createFetch as _createFetch } from "@vueuse/core";

export function createFetch() {
  const authStore = useAuthStore();

  return _createFetch({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    options: {
      beforeFetch(ctx) {
        if ( authStore.isAuthenticated ) {
          ctx.options.headers = {
            ...ctx.options.headers,
            "Authorization": `Token ${ authStore.token as string }`
          };
        }

        return ctx;
      },
      onFetchError(ctx) {
        return ctx;
      }
    }
  });
}
