<script setup lang="ts">
import { ResizeableContainer } from "@/components/ThePlanner/components/ResizeableContainer";
import type { GridCtx }        from "@/components/ThePlanner/components/TheGrid/composables";
import { VirtualScroller }     from "@/components/ThePlanner/components/VirtualScroller";

defineOptions({
  name: "HorizontalScrollbars"
});

const { gridCtx } = defineProps<{ gridCtx: GridCtx; }>();
const {
  splitterState,
  isScrollbarVertical,
  isScrollbarHorizontal,
  sidebarCtx,
  schedulerCtx
} = gridCtx;
</script>

<template>
  <div class="flex flex-nowrap">
    <ResizeableContainer
      locked-section="left"
      v-model:state="splitterState.state"
      v-model:highlighted="splitterState.highlighted"
      v-model:locked-section-width="splitterState.width"
      disable-collapsable
      disable-expandable
    >
      <template #content-left>
        <VirtualScroller
          v-show="isScrollbarHorizontal"
          v-model="sidebarCtx.scrollLeft.value"
          :width="sidebarCtx.contentWidth.value"
        />
      </template>
      <template #content-right>
        <VirtualScroller
          v-show="isScrollbarHorizontal"
          v-model="schedulerCtx.containerScrollLeftValue.value"
          :width="schedulerCtx.contentWidth.value"
        />
      </template>
    </ResizeableContainer>

    <div
      v-show="isScrollbarVertical"
      class="scroll-x-gap">
    </div>
  </div>
</template>
