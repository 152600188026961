import {
  format as _format
}               from "date-fns";
import LocaleCS from "date-fns/locale/cs";

export function format(date: Date, format: string): string {
  //@ts-expect-error
  return _format(date, format, { locale: LocaleCS });
}

export function formatDate(date: Date): string {
  return format(date, "d.M.yyyy");
}

export function formatDatetime(date: Date): string {
  return format(date, "d.M.yyyy HH:mm");
}

export function formatTime(date: Date): string {
  return format(date, "HH:mm");
}
