import type { App }    from "vue";
import type { Router } from "vue-router";
import * as Sentry     from "@sentry/vue";

let _isInitialized = false;

type InitOptions = {
  app: App,
  router: Router
}

export function init({ app, router }: InitOptions) {
  if ( _isInitialized ) return;

  Sentry.init({
    app,
    dsn: "https://fccf560872c9c97025579a5e0855964c@sentry.olc.cz/113",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],

    attachProps: true,
    logErrors: true,
    trackComponents: false,

    environment: import.meta.env.MODE,

    tracesSampleRate: 1.0,
    // tracePropagationTargets: [
    //   "localhost",
    //   /^https:\/\/planner\.olc\.cz\/api/
    // ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  _isInitialized = true;
}
