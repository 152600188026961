import type { Project }                    from "@/components/ThePlanner/types";
import { API_URLS }                        from "@/service/config";
import { toProject }                       from "@/service/Project/toProject";
import type { HttpContext }                from "@/service/utils";
import { afterFetchHookFactory, useFetch } from "@/service/utils";
import { map }                             from "@/utils";

export function useProjectService() {
  const fetch = useFetch();

  function getProjects(): HttpContext<Project[]> {
    return fetch(
      API_URLS.project(),
      {
        afterFetch: afterFetchHookFactory({
          mapperFn: (data: any) => map(data, toProject)
        })
      }
    )
      .get()
      .json();
  }

  return {
    getProjects
  };
}
