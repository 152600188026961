<script setup lang="ts">
import { TheDatepickerBase }           from "@/components";
import { type TheDatepickerBaseProps } from "@/components/inputs/TheDatepickerBase";
import type { Nullable }               from "@/types";
import { formatTime }                  from "@/utils/datetimeFormatters";
import type { TimeModel }              from "@vuepic/vue-datepicker";
import { merge }                       from "lodash-es";
import { computed }                    from "vue";

const props = defineProps<{
  modelValue: Nullable<Date>;
  config?: TheDatepickerBaseProps;
}>();

const _modelValue = defineModel<Nullable<TimeModel>>();
const _config = computed<TheDatepickerBaseProps>(() => {
  return merge<TheDatepickerBaseProps, TheDatepickerBaseProps>(
    {
      timePicker: true,
      hideNavigation: ["time"],
      format(date: Date | null) {
        if ( !date ) return "";
        return formatTime(date);
      }
      // config: { modeHeight: 120 }
    },
    props.config ?? {}
  );
});
</script>

<template>
  <TheDatepickerBase
    v-model="_modelValue"
    :config="_config"
  />
</template>
