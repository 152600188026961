<script setup lang="ts">
</script>

<template>
  <button class="accordion__btn-toggle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
  </button>
</template>

<style lang="postcss">
.accordion__btn-toggle {
  @apply cursor-pointer hover:text-white hover:bg-gray-500 rounded p-0.5 transition-colors overflow-hidden;

  svg {
    @apply transition-transform;
  }
}
</style>
