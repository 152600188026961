<script setup lang="ts">
import type { MultiselectProps }                             from "@/components/inputs/Multiselect";
import type { UserSelectSingleEmits, UserSelectSingleProps } from "./types";
import Multiselect                                           from "@vueform/multiselect";

defineOptions({
  name: "UserSelectSingle"
});

const props = withDefaults(defineProps<UserSelectSingleProps>(), { canClear: true });
defineEmits<UserSelectSingleEmits>();

const _vm = defineModel({ required: true });
const config: MultiselectProps = {
  placeholder: "Vyber uživatele",
  appendToBody: true,
  allowAbsent: true,
  canClear: props.canClear,
  searchable: true,
  strict: false,
  object: true,
  label: "full_name",
  trackBy: ["last_name", "first_name"],
  valueProp: "redmine_id"
};

</script>

<template>
  <Multiselect
    v-model="_vm"
    :options="usersList"
    v-bind="config"
    class="the-multiselect"
  >
    <template #singlelabel="{ value: _val }">
      <div class="multiselect-single-label">
        <div class="multiselect-single-label-text">
          <span class="uppercase">{{ _val.last_name }}</span>&nbsp;
          <span>{{ _val.first_name }}</span>
        </div>
      </div>
    </template>
    <template #option="{ option }">
      <div class="h-full flex items-center font-semibold">
        <div class="uppercase">
          {{ option.last_name }}
        </div>&nbsp;
        <div>
          {{ option.first_name }}
        </div>
      </div>
    </template>
  </Multiselect>
</template>
