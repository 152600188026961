// https://medium.com/the-z/making-a-resizable-div-in-js-is-not-easy-as-you-think-bda19a1bc53d
// https://jsfiddle.net/vgz97fsL/1/

import type { Position }                                                                        from "@/components/ThePlanner/types/shared";
import {
  getPositionRelativeTo
}                                                                                               from "@/components/ThePlanner/utils/getPositionRelativeTo";
import { useEventListener }                                                                     from "@vueuse/core";
import { debounce }                                                                             from "lodash-es";
import { computed, type MaybeRef, onUnmounted, reactive, ref, type Ref, toRef, toValue, watch } from "vue";

export type ResizeDirection = "left" | "right";

export type ResizeFn = (params: ResizeInfoEvent) => void;
export type ResizeStartFn = (params: ResizeInfoEvent) => false | void;
export type ResizeEndFn = (params: ResizeInfoEvent) => void;

export type ResizeInfoEvent = {
  x: number;
  y: number;
  width: number;
  height: number;
  dir: ResizeDirection;
};

export type UseElementResizeableOptions = {
  elementInfo?: {
    width?: MaybeRef<number>;
    height?: MaybeRef<number>;
    x?: MaybeRef<number>;
    y?: MaybeRef<number>;
  };
  contextElement?: MaybeRef<HTMLElement | null | undefined>;
  resizerLeftElement?: Ref<HTMLElement | null>;
  resizerRightElement?: Ref<HTMLElement | null>;
  onResize?: ResizeFn;
  onStart?: ResizeStartFn;
  onEnd?: ResizeEndFn;
}

export function useElementResizeable(
  element: Ref<HTMLElement | null>,
  options: UseElementResizeableOptions
) {
  const elementInfoOnMousedown = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };

  let mousePositionOnMousedown = {
    x: 0,
    y: 0
  };

  const resizing = ref(false);

  const elementInfo = reactive({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });

  let cursorOffsetAtResizer: Position = { x: 0, y: 0 };
  const windowMousemoveDebounced = debounce(windowMousemove);

  let resizeDirection: ResizeDirection | null = null;

  useEventListener(options.resizerLeftElement, "mousedown", resizerMousedown);
  useEventListener(options.resizerRightElement, "mousedown", resizerMousedown);

  watch(() => element.value, () => {
    initElementInfo();
  }, { immediate: true });

  onUnmounted(() => {
    windowMousemoveDebounced.cancel();
  });

  function initElementInfo() {
    if ( element.value ) {
      const style = getComputedStyle(element.value);

      elementInfo.x = toValue(options?.elementInfo?.x) ?? parseFloat(style.left);
      elementInfo.y = toValue(options?.elementInfo?.y) ?? parseFloat(style.top);
      elementInfo.width = toValue(options?.elementInfo?.width) ?? parseFloat(style.width);
      elementInfo.height = toValue(options?.elementInfo?.height) ?? parseFloat(style.height);
    } else {
      elementInfo.x = 0;
      elementInfo.y = 0;
      elementInfo.width = 0;
      elementInfo.height = 0;
    }
  }

  function resizerMousedown(e: MouseEvent) {
    const resizerEl = e.currentTarget as HTMLElement;
    resizeDirection = (resizerEl).dataset["direction"] as ResizeDirection;

    if (
      options.onStart?.({
        x: elementInfo.x,
        y: elementInfo.y,
        width: elementInfo.width,
        height: elementInfo.height,
        dir: resizeDirection
      }) === false
    ) {
      return;
    }

    mousePositionOnMousedown = getPositionRelativeTo(e, (toValue(options.contextElement) ?? window.document.body) as HTMLElement);

    cursorOffsetAtResizer = (() => {
      const p = getPositionRelativeTo(e, resizerEl);

      return {
        x: Math.max(resizerEl.offsetWidth - p.x, 0),
        y: Math.max(resizerEl.offsetHeight - p.y, 0)
      };
    })();

    initElementInfo();
    elementInfoOnMousedown.x = elementInfo.x;
    elementInfoOnMousedown.y = elementInfo.y;
    elementInfoOnMousedown.width = elementInfo.width;
    elementInfoOnMousedown.height = elementInfo.height;

    window.addEventListener("mousemove", windowMousemoveDebounced);
    window.addEventListener("mouseup", windowMouseup);
  }

  function windowMousemove(e: MouseEvent) {
    if ( resizeDirection ) {
      resize(resizeDirection, e);
    }
  }

  function windowMouseup(_e: MouseEvent) {
    window.removeEventListener("mousemove", windowMousemoveDebounced);
    window.removeEventListener("mouseup", windowMouseup);

    options.onEnd?.({
      x: elementInfo.x,
      y: elementInfo.y,
      width: elementInfo.width,
      height: elementInfo.height,
      dir: resizeDirection as ResizeDirection
    });

    elementInfoOnMousedown.x = 0;
    elementInfoOnMousedown.y = 0;
    elementInfoOnMousedown.width = 0;
    elementInfoOnMousedown.height = 0;

    resizing.value = false;

    mousePositionOnMousedown.x = 0;
    mousePositionOnMousedown.y = 0;

    cursorOffsetAtResizer.x = 0;
    cursorOffsetAtResizer.y = 0;
  }

  function resize(dir: ResizeDirection, e: MouseEvent) {
    resizing.value = true;

    switch ( dir ) {
      case "left":
        resizeLeft(e);
        break;
      case "right":
        resizeRight(e);
        break;
    }

    options?.onResize?.({
      x: elementInfo.x,
      y: elementInfo.y,
      width: elementInfo.width,
      height: elementInfo.height,
      dir
    });
  }

  function resizeLeft(e: MouseEvent) {
    const mousePos = getPositionRelativeTo(e, (toValue(options.contextElement) ?? document.body) as HTMLElement);

    elementInfo.x = elementInfoOnMousedown.x + (mousePos.x - mousePositionOnMousedown.x);
    elementInfo.width = elementInfoOnMousedown.width - (mousePos.x - mousePositionOnMousedown.x);
  }

  function resizeRight(e: MouseEvent) {
    const mousePos = getPositionRelativeTo(e, (toValue(options.contextElement) ?? document.body) as HTMLElement);
    elementInfo.width = mousePos.x - (elementInfoOnMousedown.x) + cursorOffsetAtResizer.x;
  }

  return {
    x: toRef(elementInfo, "x"),
    y: toRef(elementInfo, "y"),
    width: toRef(elementInfo, "width"),
    height: toRef(elementInfo, "height"),
    isResizing: computed(() => resizing.value)
  };
}
