<script setup lang="ts">
import { AppAccordionBtnToggle } from "@/components";

defineProps<{
  type: "highlight" | "error" | "default"
}>();

const emit = defineEmits<{ toggleOpen: []; }>();

function emitToggle() {
  emit("toggleOpen");
}
</script>

<template>
  <div
    :data-type="type"
    class="pr-3 flex w-full gap-1.5 h-full items-center transition py-2"
  >
    <slot />

    <AppAccordionBtnToggle @click="emitToggle" />
  </div>
</template>

<style lang="postcss" scoped>
div[data-type="highlight"] {
  @apply bg-amber-300;
}

div[data-type="error"] {
  @apply bg-red-200 hover:bg-red-400;
}
</style>
