import { COMPARATOR_STRING_SORT }              from "@/components/ThePlanner/config";
import type { Project }                        from "@/components/ThePlanner/types";
import { type HttpContext, useProjectService } from "@/service";
import type { Nullable }                       from "@/types";
import { handleHttpEvents }                    from "@/views/PlannerView/use/utils";
import { computed, ref, shallowRef }           from "vue";

export type UseProjectsReturn = ReturnType<typeof useProjects>;

export type UseProjectsOptions = {};

export function useProjects(_options?: UseProjectsOptions) {
  const projects = ref<Project[]>([]);
  const _projectService = useProjectService();
  const _httpFetchCtx = shallowRef<Nullable<HttpContext<Project[]>>>(null);
  const isLoading = computed(() => {
    return _httpFetchCtx.value?.isFetching.value ?? false;
  });

  function fetch(): Promise<Project[] | null> {
    return new Promise((resolve) => {
      if ( _httpFetchCtx.value?.isFetching && _httpFetchCtx.value.canAbort.value ) {
        _httpFetchCtx.value.abort();
      }

      const fetchCtx = _projectService.getProjects();
      _httpFetchCtx.value = fetchCtx;

      handleHttpEvents({
        httpContext: fetchCtx,
        onResponse({ data }) {
          projects.value = (data.value ?? []).sort((p1: Project, p2: Project) => COMPARATOR_STRING_SORT(p1.name, p2.name));
          resolve(projects.value);
        },
        onError(ctx) {
          projects.value = [];

          if ( ctx.aborted ) {
            resolve(projects.value);
          } else {
            resolve(null);
          }
        },
        onFinally(ctx) {
          if ( ctx === _httpFetchCtx.value ) {
            _httpFetchCtx.value = null;
          }
        }
      });
    });
  }

  return {
    projects,
    isLoading,
    fetch
  };
}
