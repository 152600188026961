import { ThePlanner }                                                                                      from "@/components";
import type {
  FormSearchIssue
}                                                                                                          from "@/components/forms";
import {
  useAppService
}                                                                                                          from "@/service";
import type {
  Nullable
}                                                                                                          from "@/types";
import {
  useIssueSearchHandler
}                                                                                                          from "@/views/PlannerView/use/useIssueSearchHandler";
import {
  useNotification
}                                                                                                          from "@kyvg/vue3-notification";
import { type ComponentInstance, ref, watch }                                                              from "vue";
import { useAssignedIssues, useIssueDetail, usePlannerConfig, useProjects, useUnassignedIssues, useUsers } from "./use";

export function usePlannerView() {
  const { notify } = useNotification();
  const plannerInstanceRef = ref<Nullable<ComponentInstance<typeof ThePlanner>>>(null);

  const usersController = useUsers();
  const appService = useAppService();
  const projectsController = useProjects();
  const assignedIssuesController = useAssignedIssues();
  const unassignedIssuesController = useUnassignedIssues();
  const issueDetailController = useIssueDetail();

  const formSearchIssueRedmineIdValue = ref("");
  const formSearchIssueInstanceRef = ref<Nullable<InstanceType<typeof FormSearchIssue>>>(null);

  const {
    config: plannerConfig,
    showLoader: showPlannerLoader,
    hideLoader: hidePlannerLoader
  } = usePlannerConfig({
    usersController,
    projectsController,
    assignedIssuesController,
    unassignedIssuesController,
    issueDetailController,
    plannerInstanceRef
  });

  const searchIssueHandler = useIssueSearchHandler({
    formSearchIssueRef: formSearchIssueInstanceRef,
    plannerInstanceRef,
    issueDetailController,
    assignedIssuesController,
    unassignedIssuesController,
    fetchContext: plannerConfig.fetchContext,
    dateInterval: plannerConfig.dateInterval
  });

  async function synchronizeIssuesHandler() {
    if ( !confirm("Opravdu chcete synchronizovat data z Planneru s Redminem?") ) return;
    showPlannerLoader("global");

    const { error, response } = await appService.updateOutdated();
    hidePlannerLoader("global");

    if ( error.value || !response.value?.ok ) {
      notify({
        type: "error",
        text: "Synchronizace neprovedena."
      });
    } else {
      notify({
        type: "success",
        text: "Úspěšně synchronizováno."
      });

      await assignedIssuesController.fetch(plannerConfig.dateInterval.value);
    }

  }

  function updateFormSearchIssueInstanceRef(el: Nullable<InstanceType<typeof FormSearchIssue>>) {
    formSearchIssueInstanceRef.value = el;
  }

  watch(() => formSearchIssueRedmineIdValue.value, (v) => {
    if ( !v ) {
      issueDetailController.clear();
    }
  });

  return {
    plannerConfig,
    plannerInstanceRef,

    assignedIssuesController,
    unassignedIssuesController,
    projectsController,
    issueDetailController,

    searchIssueHandler,
    formSearchIssueRedmineIdValue,

    synchronizeIssuesHandler,
    updateFormSearchIssueInstanceRef
  };
}
