import { type RemovableRef, useStorage } from "@vueuse/core";
import { defineStore }                   from "pinia";
import { computed }                      from "vue";

export const useAuthStore = defineStore("authStore", () => {
  const token: RemovableRef<string | null> = useStorage<string | null>("token", null, window.localStorage);
  const isAuthenticated = computed(() => !!token.value);

  function login(_token: string) {
    token.value = _token;
  }

  function logout() {
    token.value = null;
  }

  return {
    isAuthenticated,
    token,
    login,
    logout
  };
});
