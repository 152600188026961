import type { DateInterval }    from "@/components/ThePlanner/types";
import { endOfDay, startOfDay } from "date-fns";

export function getCurrentDateInterval(): DateInterval {
  const today = new Date();
  // const start = startOfDay(startOfWeek(today, { locale: cs }));
  const start = startOfDay(today);
  // const end = endOfDay(endOfWeek(today, { locale: cs }));
  const end = endOfDay(today);

  return {
    start,
    end
  };
}
