<script setup lang="ts">
import type { TheDatepickerBaseProps }      from "@/components/inputs/TheDatepickerBase";
import { TheDatePicker, TheTimePicker }     from "@/components/modals/IssueDetail/components/index";
import { cloneDate }                        from "@/utils";
import type { TimeModel }                   from "@vuepic/vue-datepicker";
import { getHours, getMinutes, startOfDay } from "date-fns";
import { computed }                         from "vue";

const props = defineProps<{
  modelValue: Date;
  datePickerConfig?: TheDatepickerBaseProps;
  timePickerConfig?: TheDatepickerBaseProps;
}>();

const emit = defineEmits<{
  "update:modelValue": [Date];
  "update:time": [TimeModel];
  "update:date": [Date];
}>();

const _dateModelValue = computed<Date>({
  get() {
    return startOfDay(props.modelValue);
  },
  set(date) {
    emitDateValue(date);
    emitValue({ date });
  }
});

const _timeModelValue = computed<TimeModel>({
  get() {
    const hours = getHours(props.modelValue);
    const minutes = getMinutes(props.modelValue);

    return {
      hours,
      minutes
    };
  },
  set(v) {
    emitTimeValue(v);
    emitValue({
      hours: +v.hours,
      minutes: +v.minutes
    });
  }
});

function emitDateValue(date: Date) {
  emit("update:date", date);
}

function emitTimeValue(time: TimeModel) {
  emit("update:time", time);
}

function emitValue(params: { date?: Date, minutes?: number; hours?: number; }) {
  const {
    hours = _timeModelValue.value.hours,
    minutes = _timeModelValue.value.minutes,
    date = _dateModelValue.value
  } = params;

  const d = cloneDate(date, {
    hour: +hours,
    min: +minutes
  });

  emit("update:modelValue", d);
}

</script>

<template>
  <div class="flex gap-1 items-center">
    <!--DATE-->
    <div class="flex gap-1 items-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
        </svg>
      </div>

      <div class="w-28">
        <TheDatePicker
          v-model="_dateModelValue"
          :config="datePickerConfig"
        />
      </div>
    </div>

    <!--TIME-->
    <div class="flex gap-1 items-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </div>

      <div class="w-16">
        <TheTimePicker
          v-model="_timeModelValue"
          :config="timePickerConfig"
        />
      </div>
    </div>
  </div>
</template>
