import { COMPARATOR_STRING_SORT }           from "@/components/ThePlanner/config";
import type { User }                        from "@/components/ThePlanner/types";
import { type HttpContext, useUserService } from "@/service";
import type { Nullable }                    from "@/types";
import { groupBy, map }                     from "@/utils";
import { handleHttpEvents }                 from "@/views/PlannerView/use/utils";
import { computed, ref, shallowRef }        from "vue";

function _sortUsers(user1: User, user2: User): number {
  const a = `${ user1.last_name } ${ user1.first_name }`;
  const b = `${ user2.last_name } ${ user2.first_name }`;

  return COMPARATOR_STRING_SORT(a, b);
}

export type UseUsersReturn = ReturnType<typeof useUsers>;

export function useUsers() {
  const _userService = useUserService();
  const _httpFetchCtx = shallowRef<Nullable<HttpContext<User[]>>>(null);
  const _httpUpdateCtx = shallowRef<Nullable<HttpContext<User>>>(null);
  const isLoading = computed(() => {
    return (
      (_httpFetchCtx.value?.isFetching.value ?? false) ||
      (_httpUpdateCtx.value?.isFetching.value ?? false)
    );
  });

  const users = ref<User[]>([]);
  const usersSelected = computed(() => {
    return users.value.filter(user => user.is_selected);
    // .sort(_sortUsers);
  });
  const usersUnselected = computed(() => {
    return users.value.filter(user => !user.is_selected);
  });
  const usersGroupedByRedmineId = computed(() => {
    const collection = map(
      users.value, (user, index) => {
        return {
          user, index
        };
      }
    );

    return groupBy(
      collection,
      (item) => item.user.redmine_id
    );
  });

  function fetch(): Promise<Nullable<User[]>> {
    return new Promise((resolve) => {
      if (
        _httpFetchCtx.value?.isFetching.value &&
        _httpFetchCtx.value.canAbort.value
      ) {
        _httpFetchCtx.value.abort();
      }

      const fetchCtx = _userService.getUsers();
      _httpFetchCtx.value = fetchCtx;

      handleHttpEvents({
        httpContext: fetchCtx,
        onResponse({ data }) {
          users.value = (data.value ?? []).sort(_sortUsers);
          resolve(users.value);
        },
        onError(ctx) {
          users.value = [];

          if ( ctx.aborted.value ) {
            resolve(users.value);
          } else {
            resolve(null);
          }
        },
        onFinally(ctx) {
          if ( ctx === _httpFetchCtx.value ) {
            _httpFetchCtx.value = null;
          }
        }
      });
    });
  }


  function updateUserSelectedStatus(
    redmineId: number,
    selectedStatus: boolean
  ): Promise<User | null> {
    return new Promise((resolve, _reject) => {
      const { index = -1 } = usersGroupedByRedmineId.value.get(redmineId)?.[0] ?? {};

      if ( index < 0 ) {
        resolve(null);
      }

      _httpUpdateCtx.value = _userService.updateUserSelected(
        redmineId,
        selectedStatus
      );

      handleHttpEvents({
        httpContext: _httpUpdateCtx.value,
        onResponseOK({ data }) {
          const user = data.value!;
          users.value[index] = user;
          resolve(user);
        },
        onResponseNOK(_ctx) {
          resolve(null);
        },
        onError(_ctx) {
          resolve(null);
        },
        onFinally(_ctx) {
          _httpUpdateCtx.value = null;
        }
      });
    });
  }

  return {
    users,
    usersSelected,
    usersUnselected,
    usersGroupedByRedmineId,
    isLoading,
    fetch,
    updateUserSelectedStatus
  };
}
