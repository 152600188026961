import type EventBlock                      from "@/components/ThePlanner/components/EventBlock/EnventBlock.vue";
import { createBlockBase }                  from "@/components/ThePlanner/components/TheGrid/utils";
import type { SchedulerBlock }              from "@/components/ThePlanner/components/TheWrike/types";
import type { AssignedIssue }               from "@/components/ThePlanner/types";
import { map }                              from "@/utils";
import { computed, markRaw, ref, type Ref } from "vue";

export type UseSchedulerBlocksOptions = {
  issues: Ref<AssignedIssue[]>;
  detailedInfo: Ref<boolean>;
}

export type UseSchedulerBlocksReturn = ReturnType<typeof useSchedulerBlocks>;

export function useSchedulerBlocks(options: UseSchedulerBlocksOptions) {
  const eventBlocksRefs = ref(new Map<string, InstanceType<typeof EventBlock>>());
  // const blocks = ref<SchedulerBlock[]>([]);

  const blocks = computed(() => {
    const { issues } = options;

    return map<SchedulerBlock, AssignedIssue>(issues.value, (issue, _index) => {
      return createBlockBase<AssignedIssue>({
        issue,
        timelineHourWidth: 0,
        detailedInfo: options.detailedInfo.value
      });
    });
  });

//   watch(() => [options.issues.value, options.detailedInfo.value], () => {
//     blocks.value = map<SchedulerBlock, AssignedIssue>(options.issues.value, (issue, _index) => {
//       console.log(options.detailedInfo.value);
// debugger
//       return createBlockBase<AssignedIssue>({
//         issue,
//         timelineHourWidth: 0,
//         detailedInfo: true
//       });
//     });
//   });


  function updateEventBlockRef(block: SchedulerBlock, elRef: InstanceType<typeof EventBlock> | null) {
    const id = block.id;

    if ( elRef ) {
      eventBlocksRefs.value.set(id, markRaw(elRef));
    } else {
      eventBlocksRefs.value.delete(id);
    }
  }

  return {
    blocks,
    eventBlocksRefs,
    updateEventBlockRef
  };
}
