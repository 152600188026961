import { useDraggedBlockCtx }                                                      from "@/components/ThePlanner/composables/useDraggedBlockCtx";
import {
  useModalIssueDetailCtx
}                                                                                  from "@/components/ThePlanner/composables/useModalIssueDetailCtx";
import {
  usePlannerTooltipCtx
}                                                                                  from "@/components/ThePlanner/composables/usePlannerTooltipCtx";
import {
  useSchedulerBlockContextMenu
}                                                                                  from "@/components/ThePlanner/composables/useSchedulerBlockContextMenu";
import {
  UNASSIGNED_CONTAINER_WIDTH_PERCENTAGE
}                                                                                  from "@/components/ThePlanner/config";
import type {
  AssignedIssue,
  DateInterval,
  OnAssignUnassignedIssueParams,
  OnIssueUpdateParams,
  PlannerFetchContext,
  UnassignedIssue,
  User
}                                                                                  from "@/components/ThePlanner/types";
import type {
  PlannerConfig,
  PlannerViewType
}                                                                                  from "@/components/ThePlanner/types/planner";
import { makeClone }                                                               from "@/components/ThePlanner/utils";
import { groupBy }                                                                 from "@/utils";
import { merge }                                                                   from "lodash-es";
import { computed, onMounted, onUnmounted, readonly, ref, shallowReadonly, toRaw } from "vue";

export type UsePlannerOptions = {
  config: PlannerConfig;
}

export type PlannerCtx = ReturnType<typeof usePlanner>;

export function usePlanner(options: UsePlannerOptions) {
  const { config } = options;

  const wrikeColumnsCount = computed({
    get() {
      return config.wrikeColumnsCount.value;
    },
    set(count: number) {
      config.handlers.onWrikeColumnsCountChanged(count);
    }
  });

  const dateInterval = computed<DateInterval>({
    get() {
      return config.dateInterval.value;
    },
    set(dateInterval_: DateInterval) {
      config.handlers.onDateIntervalChanged(dateInterval_);
    }
  });

  const viewType = computed<PlannerViewType>({
    get() {
      return config.view.value;
    },
    set(viewType_: PlannerViewType) {
      config.handlers.onViewTypeChanged(viewType_);
    }
  });

  const fetchContext = computed<PlannerFetchContext>({
    get() {
      return options.config.fetchContext.value;
    },
    set(fetchContext_: PlannerFetchContext) {
      config.handlers.onFetchContextChanged(fetchContext_);
    }
  });

  const containerEl = ref<HTMLElement | null>(null);
  const contentEl = ref<HTMLElement | null>(null);
  const unassignedIssuesContainerWidth = ref(0);

  const isFullscreenView = ref(false);
  const isDetailedInfo = ref(false);

  const unassignedDraggedBlockCtx = useDraggedBlockCtx<UnassignedIssue>();
  const tooltipCtx = usePlannerTooltipCtx();
  const issueModalDetailCtx = useModalIssueDetailCtx();
  const blockContextMenuCtx = useSchedulerBlockContextMenu({
    offset: {
      top: 5,
      left: 5
    },
    handlers: {
      onGoToRedmine: (block) => {
        void onAssignedIssueGoToRedmine(block.issue);
      },
      onDelete: (block) => {
        void onAssignedIssueDelete(block.issue);
      },
      onEdit: (block) => {
        blockContextMenuCtx.hide();
        issueModalDetailCtx.open(block.issue);
      }
    }
  });

  const issuesHighlightedRedmineIds = computed<number[]>(() => {
    return config.issuesHighlighted.value.map(i => i.redmine_id);
  });

  const issuesGroupedByUsers = computed(() => {
    return groupBy(
      options.config.issues.value,
      (issue) => issue.assigned_to_planner.redmine_id
    );
  });

  /*** HOOKS */

  onMounted(() => {
    unassignedIssuesContainerWidth.value = Math.max(
      (containerEl.value?.getBoundingClientRect().width ?? 0) * UNASSIGNED_CONTAINER_WIDTH_PERCENTAGE,
      150
    );

    config.handlers.onMounted?.();
  });

  onUnmounted(() => {
    unassignedDraggedBlockCtx.clearListeners();
    config.handlers.onMounted?.();
  });

  function onUpdateFetchContext(ctx: Partial<PlannerFetchContext>) {
    fetchContext.value = merge(
      makeClone(fetchContext.value, true),
      makeClone(ctx, true)
    );
  }

  function onRefreshUnplannedIssues() {
    config.handlers.onRefreshUnassignedIssues(
      makeClone(fetchContext.value, true)
    );
  }

  function onUnassignedIssuePlan(params: OnAssignUnassignedIssueParams) {
    return config.handlers.onAssignUnassignedIssue(
      toRaw(params)
    );
  }

  async function onAssignedIssueUpdate(params: OnIssueUpdateParams) {
    const { issue, updatedData } = params;

    return config.handlers.onIssueUpdate({
      issue,
      updatedData
    });
  }

  async function onAssignedIssueGoToRedmine(issue: AssignedIssue) {
    return config.handlers.onIssueGoToRedmine(issue);
  }

  async function onAssignedIssueDelete(issue: AssignedIssue) {
    return config.handlers.onIssueDelete({ issue });
  }

  function onUserSelectHandler(user: User) {
    return config.handlers.onUserSelect(user);
  }

  function onUserDeselectHandler(user: User) {
    return config.handlers.onUserDeselect(user);
  }

  function goCurrentDateInterval() {
    dateInterval.value = config.handlers.getCurrentDateInterval();
  }

  function goNextDateInterval() {
    dateInterval.value = config.handlers.getNextDateInterval(
      dateInterval.value
    );
  }

  function goPreviousDateInterval() {
    dateInterval.value = config.handlers.getPreviousDateInterval(
      dateInterval.value
    );
  }

  function toggleFullscreenView(value?: boolean) {
    isFullscreenView.value = value ?? !isFullscreenView.value;
  }

  function toggleDetailedInfo(value?: boolean) {
    isDetailedInfo.value = value ?? !isDetailedInfo.value;
  }

  function onRefreshContentData() {
    return config.handlers.onRefreshDataView({
      dateInterval: dateInterval.value
    });
  }

  function setViewType(view: PlannerViewType): void {
    viewType.value = view;
  }

  function setDateInterval(interval: DateInterval) {
    dateInterval.value = interval;
  }

  function setWrikeColumnsCount(count: number) {
    wrikeColumnsCount.value = count;
  }

  return {
    wrikeColumnsCount,
    setWrikeColumnsCount,

    viewType,
    setViewType,

    isFullscreenView: readonly(isFullscreenView),
    toggleFullscreenView,

    isDetailedInfo: readonly(isDetailedInfo),
    toggleDetailedInfo,

    containerEl,
    contentEl,

    dateInterval,
    setDateInterval,

    fetchContext,

    unassignedIssuesContainerWidth,

    users: shallowReadonly(config.users),
    usersSelected: shallowReadonly(config.usersSelected),
    usersUnselected: shallowReadonly(config.usersUnselected),

    issues: shallowReadonly(config.issues),
    issuesGroupedByUsers,
    issuesUnassigned: shallowReadonly(config.issuesUnassigned),
    issuesHighlighted: shallowReadonly(config.issuesHighlighted),
    issuesHighlightedRedmineIds: shallowReadonly(issuesHighlightedRedmineIds),

    projects: shallowReadonly(config.projects),
    projectsFiltered: shallowReadonly(config.projectsFiltered),

    /* CTXS */
    tooltipCtx,
    unassignedDraggedBlockCtx,
    issueModalDetailCtx,

    onUserSelectHandler,
    onUserDeselectHandler,

    onAssignedIssueUpdate,
    onAssignedIssueGoToRedmine,
    onAssignedIssueDelete,

    blockContextMenuCtx,

    onUnplannedIssuePlan: onUnassignedIssuePlan,

    goCurrentDateInterval,
    goPreviousDateInterval,
    goNextDateInterval,
    onUpdateFetchContext,

    loaders: config.loaders,

    onRefreshUnplannedIssues,
    onRefreshContentData
  };
}
