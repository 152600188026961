import { ThePlanner }                                                                     from "@/components";
import { FormSearchIssue }                                                                from "@/components/forms";
import type {
  DateInterval,
  PlannerFetchContext
}                                                                                         from "@/components/ThePlanner/types";
import type { Nullable }                                                                  from "@/types";
import type {
  UseAssignedIssuesReturn
}                                                                                         from "@/views/PlannerView/use/useAssignedIssues";
import type {
  UseIssueDetailReturn
}                                                                                         from "@/views/PlannerView/use/useIssueDetail";
import type {
  UseUnassignedIssuesReturn
}                                                                                         from "@/views/PlannerView/use/useUnassignedIssues";
import { addHours, endOfDay, isFriday, isMonday, nextFriday, previousMonday, startOfDay } from "date-fns";
import { isNumber }                                                                       from "lodash-es";
import { type ComponentInstance, type Ref }                                               from "vue";

export type UseIssueSearchHandlerOptions = {
  issueDetailController: UseIssueDetailReturn;
  assignedIssuesController: UseAssignedIssuesReturn;
  unassignedIssuesController: UseUnassignedIssuesReturn;
  fetchContext: Ref<PlannerFetchContext>;
  dateInterval: Ref<DateInterval>;
  formSearchIssueRef: Ref<Nullable<InstanceType<typeof FormSearchIssue>>>;
  plannerInstanceRef: Ref<Nullable<ComponentInstance<typeof ThePlanner>>>;
}

export type UseIssueSearchHandlerReturn = ReturnType<typeof useIssueSearchHandler>;

export type SearchHandlerParams = {
  value: string | null;
  redmineId: number | null;
};

export function useIssueSearchHandler(options: UseIssueSearchHandlerOptions) {
  const {
    issueDetailController,
    assignedIssuesController,
    unassignedIssuesController,
    fetchContext,
    dateInterval,
    formSearchIssueRef,
    plannerInstanceRef
  } = options;

  async function searchHandler(params: SearchHandlerParams) {
    const { value, redmineId } = params;
    const { issue: detailedIssue } = issueDetailController;

    if ( !value?.length ) {
      if ( detailedIssue.value ) {
        switch ( detailedIssue.value.state ) {
          case "unassigned":
            unassignedIssuesController.setIssues([]);
            void unassignedIssuesController.fetch(fetchContext.value);
            break;
          case "assigned":
            assignedIssuesController.setIssues([]);
            void assignedIssuesController.fetch(dateInterval.value);
            break;
        }

        issueDetailController.clear();
      }
      return;
    }

    if ( !isNumber(redmineId) ) return;

    await issueDetailController.fetch(redmineId!);

    if ( !detailedIssue.value ) {
      formSearchIssueRef.value?.setErrorMsg(`Issue #${ value } nenalezena.`);
      return;
    }

    switch ( detailedIssue.value.state ) {
      case "assigned":
        const issue = detailedIssue.value;

        dateInterval.value.start = startOfDay(
          isMonday(issue.start_date_time) ?
          issue.start_date_time : previousMonday(issue.start_date_time)
        );

        dateInterval.value.end = endOfDay(
          isFriday(issue.end_date_time) ?
          issue.end_date_time : nextFriday(issue.end_date_time)
        );

        await assignedIssuesController.fetch(dateInterval.value);
        plannerInstanceRef.value?.scrollViewTo(addHours(issue.start_date_time, -12));
        break;
      case "unassigned":
        unassignedIssuesController.setIssues([detailedIssue.value]);
        break;
    }
  }

  return searchHandler;
}
