<script setup lang="ts">
import type { TimelineCtx } from "@/components/ThePlanner/components/TheGrid/composables";

defineOptions({
  name: "GridTimeline"
});

const { timelineCtx } = defineProps<{ timelineCtx: TimelineCtx }>();
const { dayItems, hourItems, contentWidth, containerEl } = timelineCtx;
</script>

<template>
  <div
    class="the-grid-timeline overflow-hidden"
    ref="containerEl"
  >
    <div class="flex flex-col flex-nowrap h-full" :style="{ width: `${ contentWidth }px`}">
      <div class="flex flex-nowrap w-full h-full justify-center items-center border-b border-solid border-gray-300">
        <div
          v-for="item in dayItems"
          :title="item.title"
          :key="item.id"
          :data-id="item.id"
          class="h-full flex-grow-0 flex-shrink-0 basis-auto text-xs font-medium flex justify-center items-center border-r border-solid border-gray-300 last-of-type:border-r-0"
          :style="{ width: `${ item.width }px`}"
        >
          {{ item.label }}
        </div>
      </div>

      <div class="flex flex-nowrap w-full h-full justify-center items-center">
        <div
          v-for="item in hourItems"
          :title="item.title"
          :key="item.id"
          :data-id="item.id"
          class="h-full flex-grow-0 flex-shrink-0 basis-auto text-xs font-medium flex justify-center items-center border-r border-solid border-gray-300 last-of-type:border-r-0"
          :style="{ width: `${ item.width }px` }">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
