import type { NotifyParams } from "@/components/notifications/types";
import { notify as _notify } from "@kyvg/vue3-notification";

export function useNotification<TData>(params?: NotifyParams<TData>) {

  function notify(params2: NotifyParams<TData>) {
    return _notify({ ...params, ...params2 });
  }

  return {
    notify,
    close: _notify.close
  };
}
