<script setup lang="ts">
import { NoDataMessage, TheEventBlock, TheEventBlockContentIssue } from "@/components/ThePlanner/components";
import type {
  GridCtx
}                                                                  from "@/components/ThePlanner/components/TheGrid/composables";

defineOptions({
  name: "GridScheduler"
});

const { gridCtx } = defineProps<{ gridCtx: GridCtx; }>();
const { schedulerCtx } = gridCtx;

const {
  containerEl,
  contentEl,
  canvasEl,
  contentWidth,
  contentHeight,
  lineTimestamp,
  blocks,
  updateEventBlockRef,

  eventBlockHandlers
} = schedulerCtx;
</script>

<template>
  <div
    class="the-grid-scheduler overflow-hidden"
    ref="containerEl"
  >
    <NoDataMessage v-show="!gridCtx.rows.value.length" />

    <div
      class="overflow-hidden relative min-h-full"
      ref="contentEl"
      :style="{
        width: `${ contentWidth }px`,
        height: `${ contentHeight }px`
      }"
    >
      <div
        class="relative"
        ref="canvasEl"
        :style="{
          width: `${ contentWidth }px`,
          height: `${ contentHeight }px`
        }"
      >
        <div
          class="absolute flex flex-col top-0 left-0 z-10"
          :style="{
            width: `${ contentWidth }px`,
            height: `${ contentHeight }px`
          }"
        >
          <div
            v-for="row in gridCtx.rows.value"
            :key="row.id"
            :data-id="row.id"
            class="the-grid-row !flex-grow-0 !flex-shrink-0 !basis-auto"
            :class="{
              'the-grid-row--is-even': row.index % 2
            }"
            :style="{ height: `${ row.height }px` }"
          ></div>
        </div>

        <div
          v-show="lineTimestamp.visible.value"
          :style="lineTimestamp.style.value"
          class="w-4 absolute top-0 left-12 bg-fuchsia-300 h-full z-10 opacity-75">
        </div>

        <div
          class="absolute top-0 left-0 z-20"
          :style="{
            width: `${ contentWidth }px`,
            height: `${ contentHeight }px`
          }"
        >
          <TheEventBlock
            v-for="block in blocks"
            :ref="updateEventBlockRef.bind(undefined,block)"
            :key="block.id"
            :type="block.type"
            v-model:position="block.position"
            :draggable="block.isDraggable"
            :resizeable="block.isResizeable"
            :relative-element="contentEl"
            :width="block.width"
            :height="block.height"
            :min-width="block.minWidth"
            :max-width="block.maxWidth"
            class="absolute"
            style-drag="z-index: 100;"
            style-resize="z-index: 100;"

            :get-drag-position="eventBlockHandlers.getEventBlockDragPosition"

            @dblclick="eventBlockHandlers.onEventBlockDblclick(block,$event)"
            @mouseenter="eventBlockHandlers.onEventBlockMouseenter(block, $event)"
            @mouseleave="eventBlockHandlers.onEventBlockMouseleave(block, $event)"

            @resizeStart="eventBlockHandlers.onEventBlockResizeStart(block, $event)"
            @resize="eventBlockHandlers.onEventBlockResize(block, $event)"
            @resizeEnd="eventBlockHandlers.onEventBlockResizeEnd(block, $event)"

            @dragstart="eventBlockHandlers.onEventBlockDragstart(block,$event)"
            @drag="eventBlockHandlers.onEventBlockDrag(block,$event)"
            @dragend="eventBlockHandlers.onEventBlockDragend(block, $event)"

            @contextmenu="eventBlockHandlers.onEventBlockContextmenu(block, $event)"
          >
            <TheEventBlockContentIssue
              class="w-full h-full"
              :issue="block.issue"
              :detailed="block.isDetailedInfo"
            />
          </TheEventBlock>
        </div>
      </div>
    </div>
  </div>
</template>
