import type { GridSidebarCtx }             from "@/components/ThePlanner/components/TheGrid/composables/useGridSidebar";
import type {
  SchedulerCtx
}                                          from "@/components/ThePlanner/components/TheGrid/composables/useScheduler";
import type {
  GridRow
}                                          from "@/components/ThePlanner/components/TheGrid/types";
import {
  findRowByVerticalPosition,
  isPositionInsideRowVerticalOffset
}                                          from "@/components/ThePlanner/components/TheGrid/utils";
import { getPositionRelativeTo }           from "@/components/ThePlanner/utils";
import { useDebounceFn, useEventListener } from "@vueuse/core";
import type { Ref }                        from "vue";

export type UseGridHoveredRowHandlerOptions = {
  rows: Ref<GridRow[]>;
  sidebarCtx: GridSidebarCtx;
  schedulerCtx: SchedulerCtx;
  hoveredRow: Readonly<Ref<GridRow | null>>;
  isMousePositionInsideGridBodyEl: (e: MouseEvent) => boolean;
  setHoveredRow: (row: GridRow | null) => void;
};

export type UseGridHoveredRowHandlerReturn = ReturnType<typeof useGridHoveredRowHandler>;

export function useGridHoveredRowHandler(options: UseGridHoveredRowHandlerOptions) {
  const {
    rows,
    schedulerCtx,
    sidebarCtx,
    hoveredRow,
    isMousePositionInsideGridBodyEl,
    setHoveredRow
  } = options;
  const onWindowMousemoveDebounced = useDebounceFn(onWindowMouseMove, 0);

  useEventListener(
    window,
    "mousemove",
    onWindowMousemoveDebounced
  );

  function onWindowMouseMove(e: MouseEvent) {
    if ( !schedulerCtx.contentEl.value ) return;

    if (
      !isMousePositionInsideGridBodyEl(e) ||
      !(
        sidebarCtx.bodyCtx.isMousePositionInsideContentEl(e) ||
        schedulerCtx.isMousePositionInsideCanvasEl(e)
      )
    ) {
      setHoveredRow(null);
      return;
    }

    const positionRelative = getPositionRelativeTo(
      e,
      schedulerCtx.contentEl.value
    );

    if ( hoveredRow.value ) {
      if (
        isPositionInsideRowVerticalOffset(
          positionRelative,
          hoveredRow.value
        )
      ) {
        return;
      }
    }

    const row =
      findRowByVerticalPosition(
        positionRelative,
        [
          rows.value[(hoveredRow.value?.index ?? -100) - 1],
          rows.value[(hoveredRow.value?.index ?? -100) + 1]
        ].filter(Boolean) as GridRow[]
      ) ??
      findRowByVerticalPosition(positionRelative, rows.value);

    setHoveredRow(
      row
    );
  }
}
