import { isString, isUndefined } from "lodash-es";

export function normalizeStyleValue(
  value: string | number | undefined,
  defaultValue?: string | number | undefined,
  suffix: "%" | "px" | "rem" | "em" | "" = "px"
): string | number | undefined {
  if ( isUndefined(value) ) {
    return defaultValue;
  }

  return isString(value) ? value : `${ value }${ suffix }`;
}
