<script setup lang="ts">
import type { MultiselectProps }                       from "@/components/inputs/Multiselect";
import type { ProjectSelectEmits, ProjectSelectProps } from "@/components/inputs/ProjectSelect/types";
import Multiselect                                     from "@vueform/multiselect";

defineOptions({
  name: "ProjectSelect"
});

defineProps<ProjectSelectProps>();
defineEmits<ProjectSelectEmits>();

const _vm = defineModel({ required: true });
const config: MultiselectProps = {
  placeholder: "Vyber projekt",
  appendToBody: true,
  allowAbsent: true,
  canClear: true,
  searchable: true,
  object: true,
  strict: false,
  label: "name",
  trackBy: "name",
  valueProp: "redmine_id"
};

</script>

<template>
  <Multiselect
    v-model="_vm"
    :options="projectsList"
    v-bind="config"
    class="the-multiselect"
  >
    <template #singlelabel="{ value: _val }">
      <div class="multiselect-single-label">
        <div class="multiselect-single-label-text">
          {{ _val.name }}
        </div>
      </div>
    </template>
    <template #option="{ option }">
      <div class="h-full flex items-center font-semibold">
        {{ option.name }}
      </div>
    </template>
  </Multiselect>
</template>
