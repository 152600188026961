export function map<
  TResult,
  TItem
>(array: TItem[], cb: (item: TItem, index: number, array: TItem[]) => TResult): TResult[] {
  const result = Array.from<TResult>({ length: array.length });

  for ( let i = 0, len = array.length; i < len; i++ ) {
    result[i] = cb(array[i]!, i, array);
  }

  return result;
}
