<script setup lang="ts">
import type { TheTooltipProps } from "@/components/ThePlanner/components/TheTooltip/types";
import { type Ref, ref }        from "vue";

defineOptions({
  name: "TheTooltip"
});

withDefaults(
  defineProps<TheTooltipProps>(),
  {
    position: () => ({
      x: 0,
      y: 0
    })
    // maxWidth: 300
  }
);

const element = ref<HTMLElement | null>(null);
const contentElement = ref<HTMLElement | null>(null);

defineExpose<{
  el: Ref<HTMLElement | null>;
  contentEl: Ref<HTMLElement | null>;
}>({
  el: element,
  contentEl: contentElement
});
</script>

<template>
  <div
    class="the-tooltip z-[100] shadow-xl fixed transition"
    ref="element"
    :style="[
      {
        top: `${ position.y }px`,
        left: `${ position.x }px`
      }
    ]"
    :class="[
      { 'is-visible': visible },
    ]"
  >
    <div
      ref="contentElement"
      class="bg-white w-full h-full overflow-hidden rounded border border-gray-300"
      :class="classValue"
      :style="styleValue"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
.the-tooltip {
  position: fixed;
  opacity: 0;

  &.is-visible {
    opacity: 1;
  }
}
</style>
