import type { HttpContext } from "@/service";

export type HandleHttpEventsParams<TResponseData = any> = {
  httpContext: HttpContext<TResponseData>;
  onError?: (ctx: HttpContext<TResponseData>) => void;
  onResponse?: (ctx: HttpContext<TResponseData>) => void;
  onResponseOK?: (ctx: HttpContext<TResponseData>) => void;
  onResponseNOK?: (ctx: HttpContext<TResponseData>) => void;
  onFinally?: (ctx: HttpContext<TResponseData>) => void;
}

export function handleHttpEvents<TResponseData = any>(
  options: HandleHttpEventsParams<TResponseData>
) {
  const {
    response,
    onFetchFinally,
    onFetchError,
    onFetchResponse
  } = options.httpContext;

  onFetchResponse(() => {
    if ( response.value?.ok ) {
      options.onResponseOK?.(options.httpContext);
    } else {
      options.onResponseNOK?.(options.httpContext);
    }

    options.onResponse?.(options.httpContext);
  });
  onFetchError(() => {
    options.onError?.(options.httpContext);
  });

  onFetchFinally(() => {
    options.onFinally?.(options.httpContext);
  });
}
