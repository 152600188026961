import type { PlannerViewType } from "@/components/ThePlanner/types/planner";
import { type RemovableRef, useStorage } from "@vueuse/core";

export function usePlannerStorage(): {
  viewType: RemovableRef<PlannerViewType>,
  wrikeColumnsCount: RemovableRef<number>
} {
  const viewType = useStorage<PlannerViewType>(
    "dataView",
    "timeline",
    window.localStorage
  );

  const wrikeColumnsCount = useStorage<number>(
    "wrikeColumnsCount",
    2,
    window.localStorage,
    {
      serializer: {
        read(value) {
          return +value;
        },
        write(value) {
          return value.toString();
        }
      }
    }
  );

  return {
    viewType,
    wrikeColumnsCount
  };
}
