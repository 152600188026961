<script setup lang="ts">
import { TheLoader }          from "@/components";
import { TheScheduler }       from "@/components/ThePlanner/components/TheWrike/components";
import { useWrike }           from "@/components/ThePlanner/components/TheWrike/composables";
import type { TheWrikeProps } from "@/components/ThePlanner/components/TheWrike/types";

const { plannerCtx } = defineProps<TheWrikeProps>();

const wrikeCtx = useWrike({ plannerCtx });

const { containerEl, contentEl } = wrikeCtx;

function scrollViewTo(_date: Date) {

}

defineExpose<{
  scrollViewTo: typeof scrollViewTo
}>({
  scrollViewTo
});

</script>

<template>
  <div
    ref="containerEl"
    class="overflow-hidden h-full"
  >
    <div ref="contentEl" class="relative h-full">
      <TheScheduler :wrike-ctx="wrikeCtx" />
      <TheLoader :visible="plannerCtx.loaders.viewContent?.value" />
    </div>
  </div>
</template>
