import { ProjectSelect }  from "@/components/inputs/ProjectSelect";
import {
  UserSelectSingle
}                         from "@/components/inputs/UserSelectSingle";
import type {
  PlannerCtx
}                         from "@/components/ThePlanner/composables/usePlanner";
import type {
  IssuesFilterType,
  IssuesFilterValue,
  Project,
  UnplannedIssuesSortBy,
  User
}                         from "@/components/ThePlanner/types";
import {
  makeClone
}                         from "@/components/ThePlanner/utils";
import type { Component } from "vue";
import { computed }       from "vue";

export type UseFilterOptionsOptions = {
  plannerCtx: PlannerCtx;
}

export function useFilterOptions({ plannerCtx }: UseFilterOptionsOptions): {
  id: IssuesFilterType;
  value: IssuesFilterValue;
  label: string;
  component: Component,
  props: Record<string, any>,
  listeners: Record<string, Function>
}[] {
  return [
    {
      id: "user",
      value: { value: null, type: "user" },
      label: "Uživatel",
      component: UserSelectSingle,
      props: computed(() => ({
        usersList: plannerCtx.users.value
      })),
      listeners: {
        "update:model-value": (value: User | null) => {
          plannerCtx.onUpdateFetchContext({
            issuesFilter: {
              type: "user",
              value: value ? makeClone(value, true) : value
            }
          });
        }
      }
    },
    {
      id: "project",
      value: { value: null, type: "project" },
      label: "Project",
      component: ProjectSelect,
      props: computed(() => ({
        projectsList: plannerCtx.projects.value
      })),
      listeners: {
        "update:model-value": (value: Project | null) => {
          plannerCtx.onUpdateFetchContext({
            issuesFilter: {
              type: "project",
              value: value ? makeClone(value, true) : null
            }
          });
        }
      }
    }
  ];
}

export function useSortOptions(): {
  value: UnplannedIssuesSortBy;
  [key: string]: any;
}[] {
  return [
    { id: "last-update", value: "last-update", label: "Poslední změna" },
    { id: "issue-number", value: "issue-number", label: "Číslo issue" }
  ];
}
