import "@/assets/styles/main.css";
import App                    from "@/App.vue";
import { AppButton, AppIcon } from "@/components";
import router                 from "@/router";
import Notifications          from "@kyvg/vue3-notification";
import { createPinia }        from "pinia";

import { createApp }          from "vue";
import { init as initSentry } from "./sentry";

const pinia = createPinia();

const app = createApp(App);

if ( import.meta.env.PROD ) {
  initSentry({
    app,
    router
  });
}

app
  .use(pinia)
  .use(router)
  .use(Notifications)
  .component("AppIcon", AppIcon)
  .component("AppButton", AppButton)
  .mount("#app");
