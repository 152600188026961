<script setup lang="ts">
import { TheLoader }                                                       from "@/components";
import { TooltipContentBlockDetail }                                       from "@/components/ThePlanner/components";
import {
  useIssuesStackItems
}                                                                          from "@/components/ThePlanner/components/IssuesStack/composables";
import { UNASSIGNED_DRAGGED_BLOCK_HEIGHT, UNASSIGNED_DRAGGED_BLOCK_WIDTH } from "@/components/ThePlanner/config";
import { markRaw, nextTick, ref }                                          from "vue";
import IssuesStackBody                                                     from "./IssuesStackBody.vue";
import IssuesStackHeader                                                   from "./IssuesStackHeader.vue";
import type { IssuesStackItem, IssuesStackProps }                          from "./types";

defineOptions({
  name: "IssuesStack"
});

const { plannerCtx } = defineProps<IssuesStackProps>();
const detailedInfoEnabled = ref(false);
const stackItems = useIssuesStackItems({
  issuesUnassigned: plannerCtx.issuesUnassigned,
  issuesHighlightedRedmineIds: plannerCtx.issuesHighlightedRedmineIds,
  detailedInfoEnabled
});

function onItemSelect(item: IssuesStackItem, e: Event) {
  plannerCtx.unassignedDraggedBlockCtx.create({
    issue: item.issue,
    mouseEvent: e as MouseEvent,
    width: UNASSIGNED_DRAGGED_BLOCK_WIDTH,
    height: UNASSIGNED_DRAGGED_BLOCK_HEIGHT
  });
}

function onItemUnselect() {
  plannerCtx.unassignedDraggedBlockCtx.destroy();
}

function onItemExpandChange(item: IssuesStackItem) {
  if ( item.expanded ) {
    plannerCtx.tooltipCtx.clear();
  }
}

function onItemMouseenter(item: IssuesStackItem, e: MouseEvent) {
  if ( item.expanded ) return;
  const { tooltipCtx } = plannerCtx;

  tooltipCtx.state.classList = "max-w-[400px]";
  tooltipCtx.state.component = markRaw(TooltipContentBlockDetail);
  tooltipCtx.state.componentProps = {
    issue: item.issue
  };
  tooltipCtx.state.visible = true;

  nextTick(() => {
    const target = e.currentTarget as HTMLElement;
    const clientRect = target.getBoundingClientRect();
    const tooltipEl = tooltipCtx.tooltipEl.value!;
    const position = {
      x: clientRect.x + ((target.offsetWidth - tooltipEl.offsetWidth) / 2),
      y: clientRect.y - tooltipEl!.offsetHeight - 8
    };

    if ( position.x + tooltipEl!.offsetWidth > window.innerWidth ) {
      position.x = window.innerWidth - tooltipEl.offsetWidth - 8;
    }

    tooltipCtx.setPosition(position);
  });
}

function onItemMouseleave(_item: IssuesStackItem, _e: MouseEvent) {
  plannerCtx.tooltipCtx.clear();
}
</script>

<template>
  <div class="the-issues-stack h-full w-full bg-amber-200">
    <div class="h-full w-full flex flex-col">
      <div class="flex-grow-0 flex-shrink-0">
        <IssuesStackHeader
          v-model:detail-info-enabled="detailedInfoEnabled"
          :planner-ctx="plannerCtx"
        />
      </div>
      <div class="flex-1 overflow-y-hidden vue-loader-container">
        <IssuesStackBody
          :items="stackItems"
          :on-item-select="onItemSelect"
          :on-item-unselect="onItemUnselect"
          :on-item-mouseenter="onItemMouseenter"
          :on-item-mouseleave="onItemMouseleave"
          :on-item-expand-change="onItemExpandChange"
        />
        <TheLoader
          :visible="plannerCtx.loaders.unassignedIssues?.value ?? false"
        />
      </div>
    </div>
  </div>
</template>
