<script setup lang="ts">
import type { SimpleSelectEmits, SimpleSelectProps } from "@/components/inputs/SimpleSelect/types";
import { useVModel }                                 from "@vueuse/core";
import Multiselect                                   from "@vueform/multiselect";

defineOptions({
  name: "SimpleSelect"
});

const props = defineProps<SimpleSelectProps>();
const emit = defineEmits<SimpleSelectEmits>();

const _mv = useVModel(props, "modelValue", emit);
</script>

<template>
  <Multiselect
    class="the-multiselect"
    :placeholder="placeholder"
    :can-deselect="canDeselect"
    :can-clear="canClear"
    :id
    close-on-scroll
    append-to-body
    locale="cs"
    v-model="_mv"
    :options="options"
  />
</template>
