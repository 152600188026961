import type { PlannerCtx } from "@/components/ThePlanner/composables/usePlanner";
import type { Nullable }   from "@/types";
import { ref }             from "vue";

export type UseWrikeOptions = {
  plannerCtx: PlannerCtx;
}

export type WrikeCtx = ReturnType<typeof useWrike>;

export function useWrike(options: UseWrikeOptions) {
  const { plannerCtx } = options;

  const containerEl = ref<Nullable<HTMLElement>>(null);
  const contentEl = ref<Nullable<HTMLElement>>(null);

  return {
    containerEl,
    contentEl,
    plannerCtx
  };
}
