<script setup lang="ts">
import type { Position }                                from "@/components/ThePlanner/types";
import { getPositionRelativeTo }                        from "@/components/ThePlanner/utils";
import { useElementSize }                               from "@vueuse/core";
import { debounce }                                     from "lodash-es";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import type { ResizeEvent, ResizeEventType, StateType } from "./types";

defineOptions({
  name: "ResizeableContainer"
});


const props = withDefaults(
  defineProps<{
    lockedSection?: "left" | "right",
    lockedSectionWidth?: number;
    highlighted?: boolean;
    disableSplitter?: boolean;
    disableCollapsable?: boolean;
    disableExpandable?: boolean;
    state?: StateType;
    splitterButtonsOffset?: number;
  }>(),
  {
    state: "default",
    lockedSection: "left",
    lockedSectionWidth: 0,
    highlighted: false,
    disableSplitter: false,
    disableCollapsable: false,
    disableExpandable: false
  }
);

const emit = defineEmits<{
  resizing: [ResizeEvent];
  resizingStart: [ResizeEvent];
  resizingEnd: [ResizeEvent];

  "update:highlighted": [boolean];
  "update:lockedSectionWidth": [number];
  "update:state": [StateType];
}>();

const containerElement = ref<HTMLElement | null>(null);
const sectionLeftElement = ref<HTMLElement | null>(null);
const sectionRightElement = ref<HTMLElement | null>(null);
const splitterElement = ref<HTMLElement | null>(null);
const splitterInnerElement = ref<HTMLElement | null>(null);

const lockedSectionElement = computed<HTMLElement | null>(() => {
  return props.lockedSection === "left" ?
         sectionLeftElement.value :
         sectionRightElement.value;
});

const containerElementSize = useElementSize(containerElement);
const sectionLeftElementSize = useElementSize(sectionLeftElement);
const sectionRightElementSize = useElementSize(sectionRightElement);

const windowMousemoveHandlerDebounced = debounce(windowMousemoveHandler);

const isResizing = ref(false);
const isCollapsed = ref(false);
const isExpanded = ref(false);
const isHighlighted = ref(false);

const splitterMouseOffset = ref<Position>({
  x: 0,
  y: 0
});

const _lockedSectionWidth = ref(props.lockedSectionWidth ?? 0);

watch(() => props.lockedSectionWidth, (width: number) => {
  setLockedSectionWidth(width, true);
});

watch(() => props.highlighted, (highlighted: boolean) => {
  isHighlighted.value = highlighted;
});

watch(() => props.state, (state: StateType) => {
  switch ( state ) {
    case "expanded":
      toggleExpanded(true);
      break;
    case "collapsed":
      toggleCollapsed(true);
      break;
    default:
      toggleExpanded(false);
      toggleCollapsed(false);
      break;
  }
}, { immediate: true });

onMounted(() => {
  setLockedSectionWidth(props.lockedSectionWidth, true);
});

onUnmounted(() => {
  windowMousemoveHandlerDebounced.cancel();
  window.removeEventListener("mousemove", windowMousemoveHandler);
  window.removeEventListener("mouseup", windowMouseupHandler);
});

function setLockedSectionWidth(width: number, checkBoundary = false) {
  if ( !containerElement.value || !splitterElement.value ) return;
  let w = width;

  if ( checkBoundary ) {
    const containerRect = containerElement.value!.getBoundingClientRect();
    const splitterRect = splitterElement.value!.getBoundingClientRect();

    if ( width < 0 ) {
      w = 0;
    }

    if ( width - splitterRect.width > containerRect.width ) {
      w = containerRect.width - splitterRect.width;
    }
  }

  _lockedSectionWidth.value = w;

  if ( lockedSectionElement.value ) {
    lockedSectionElement.value.style.setProperty("width", `${ w }px`);
    emit("update:lockedSectionWidth", w);
  }
}

function emitResizeEvent(type: ResizeEventType) {
  const payload: Omit<ResizeEvent, "type"> = {
    sectionLeftWidth: sectionLeftElementSize.width.value,
    sectionRightWidth: sectionRightElementSize.width.value
  };

  switch ( type ) {
    case "start":
      emit("resizingStart", { type, ...payload });
      break;
    case "resizing":
      emit("resizing", { type, ...payload });
      break;
    case "end":
      emit("resizingEnd", { type, ...payload });
      break;
  }
}

function emitStatus() {
  if ( isCollapsed.value ) {
    emit("update:state", "collapsed");
    return;
  }

  if ( isExpanded.value ) {
    emit("update:state", "expanded");
    return;
  }

  emit("update:state", "default");
}

function toggleHighlighted(value: boolean = !isHighlighted.value) {
  isHighlighted.value = value;
  emit("update:highlighted", value);
}

function toggleCollapsed(value: boolean = !isCollapsed.value) {
  if ( isExpanded.value ) {
    toggleExpanded(false);
    return;
  }

  isCollapsed.value = value;
  emitStatus();
}

function toggleExpanded(value: boolean = !isExpanded.value) {
  if ( isCollapsed.value ) {
    toggleCollapsed(false);
    return;
  }

  isExpanded.value = value;
  emitStatus();
}

function windowMousemoveHandler(e: MouseEvent) {
  if ( isResizing ) {
    const { x } = getPositionRelativeTo(e, containerElement.value!);
    const w = props.lockedSection === "left" ?
              x : containerElementSize.width.value - x;

    setLockedSectionWidth(w - splitterMouseOffset.value.x, true);
    emitResizeEvent("resizing");
  }
}

function windowMouseupHandler(_e: MouseEvent) {
  isResizing.value = false;

  window.removeEventListener("mousemove", windowMousemoveHandlerDebounced);
  window.removeEventListener("mouseup", windowMouseupHandler);
  emitResizeEvent("end");
}

function splitterMousedownHandler(e: MouseEvent) {
  if ( isCollapsed.value || isExpanded.value ) {
    return;
  }

  splitterMouseOffset.value = getPositionRelativeTo(e, splitterInnerElement.value!);
  isResizing.value = true;

  emitResizeEvent("start");
  window.addEventListener("mousemove", windowMousemoveHandlerDebounced);
  window.addEventListener("mouseup", windowMouseupHandler);
}

// function splitterButtonsMouseenterHandler(e: MouseEvent) {
//   console.log(`mouseentrer`);
// }
//
// function splitterButtonsMouseoutHandler(e: MouseEvent) {
//   console.log(`mouseout`);
// }
</script>

<template>
  <div
    class="resizeable-container"
    ref="containerElement"
    :class="{
      'resizeable-container--locked-section-left': lockedSection === 'left',
      'resizeable-container--locked-section-right': lockedSection === 'right',
      'resizeable-container--is-expanded': isExpanded,
      'resizeable-container--is-collapsed': isCollapsed,
      'resizeable-container--is-resizing': isResizing
    }"
  >
    <div
      data-section-id="left"
      class="resizeable-container__section"
      :class="{
        'resizeable-container__section--locked': lockedSection === 'left'
      }"
      ref="sectionLeftElement"
    >
      <slot name="content-left" :width="sectionLeftElementSize.width.value" />
    </div>

    <div
      v-if="!disableSplitter"
      class="splitter"
      :class="{
        'splitter--is-moving': isResizing,
        'splitter--no-resize': isExpanded || isCollapsed,
        'splitter--is-highlighted': highlighted,
      }"
      ref="splitterElement"
    >
      <div
        class="splitter__inner"
        ref="splitterInnerElement"
        @mouseenter="toggleHighlighted(true)"
        @mouseleave="toggleHighlighted(false)"
        @mousedown.self="splitterMousedownHandler"
      >
        <div
          class="splitter__buttons"
          :style="{ top: `${ splitterButtonsOffset }px` }"
          v-if="!(disableCollapsable && disableExpandable)"
        >
          <div
            class="splitter__button splitter__button--collapse"
            @mousedown="toggleCollapsed()"
          >
            <svg
              class="splitter__button-icon"
              viewBox="0 0 256 512"
            >
              <path
                d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z">
              </path>
            </svg>
          </div>
          <div
            class="splitter__button splitter__button--expand"
            @mousedown="toggleExpanded()"
          >
            <svg
              class="splitter__button-icon"
              viewBox="0 0 256 512"
            >
              <path
                d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      data-section-id="right"
      class="resizeable-container__section"
      :class="{
        'resizeable-container__section--locked': lockedSection === 'right'
      }"
      ref="sectionRightElement"
    >
      <slot name="content-right" :width="sectionRightElementSize.width.value" />
    </div>
  </div>
</template>

<style>
.resizeable-container {
  & > .resizeable-container__section {
    & > * {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style scoped lang="postcss">
.resizeable-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;

  & > .resizeable-container__section {
    flex: 1;
    overflow: hidden;
    z-index: 1;
    position: relative;

    &--locked {
      flex: 0 0 auto;
    }
  }

  & > .splitter {
    position: relative;
    z-index: 2;
  }


  &--is-resizing {
    user-select: none;
  }

  &--is-collapsed {
    & > .resizeable-container__section {
      flex: 1;
    }

    & > .resizeable-container__section--locked {
      flex: 0 0 0;
    }
  }

  &--is-expanded {
    & > .resizeable-container__section {
      flex: 0 0 0;
    }

    & > .resizeable-container__section--locked {
      flex: 1;
    }
  }

  &--locked-section-right {
    & > .splitter {
      .splitter__buttons {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.splitter {
  flex: 0 0 0.5rem;
  width: 0.5rem;
  color: #4f5964;
  position: relative;
  overflow: visible;

  &__inner {
    cursor: col-resize;
    width: 0.6em;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e5e5e8;
    transition: width .1s, left .1s;
  }

  &__buttons {
    z-index: 1;
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2.8em;
    width: 2.8em;
    font-size: 1em;
  }

  &__button {
    flex: 1;
    cursor: pointer;
    /*background: #e5e5e8;*/
    @apply bg-gray-300;
    display: flex;
    align-items: center;


    &-icon {
      cursor: pointer;
      fill: #7b7b86;
      height: 1.5em;
    }

    &:hover {
      svg {
        fill: #3183fe;
      }
    }
  }

  &__button--collapse {
    border-top-left-radius: 100% 50%;
    border-bottom-left-radius: 100% 50%;
    justify-content: flex-end;
    padding-right: 0.1em;
  }

  &__button--expand {
    border-top-right-radius: 100% 50%;
    border-bottom-right-radius: 100% 50%;
    padding-left: 0.1em;
  }

  &--is-moving,
  &--is-resizing,
  &--is-highlighted,
  &:hover {
    .splitter__inner {
      @apply bg-gray-300;
    }

    .splitter__button {
      @apply bg-gray-300 shadow-2xl shadow-gray-400;
    }

    .splitter__buttons {
      display: flex;
    }

    .splitter__inner {
      left: calc((10px - 0.5em) / -2);
      width: 10px;
    }
  }

  &--no-resize {
    .splitter__inner {
      cursor: default;
    }
  }
}
</style>
