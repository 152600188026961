import type { UseFetchOptions } from "@vueuse/core";

export type AfterFetchFactoryOptions = {
  mapperFn: (input: any) => any;
}

export function afterFetchHookFactory(options: AfterFetchFactoryOptions) {

  const fn: NonNullable<UseFetchOptions["afterFetch"]> = (ctx) => {
    if ( ctx.response.ok ) {
      return {
        data: options.mapperFn(ctx.data)
      };
    }

    return ctx;
  };

  return fn;
}
