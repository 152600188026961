<script setup lang="ts">
import NoDataMessage                    from "@/components/ThePlanner/components/NoDataMessage.vue";
import type { GridCtx }                 from "@/components/ThePlanner/components/TheGrid/composables";
import type { GridRow }                 from "@/components/ThePlanner/components/TheGrid/types";
import { GRID_USERS_SIDEBAR_MIN_WIDTH } from "@/components/ThePlanner/config";
import type { User }                    from "@/components/ThePlanner/types";

defineOptions({
  name: "GridSidebarBody"
});

const { gridCtx } = defineProps<{ gridCtx: GridCtx; }>();
const {
  rows,
  hoveredRow,
  sidebarCtx,
  contentHeight,
  plannerCtx
} = gridCtx;

const { containerEl, contentEl } = sidebarCtx.bodyCtx;

function toggleUserSelect(user: User) {
  if ( user.is_selected ) {
    plannerCtx.onUserDeselectHandler(user);
  } else {
    plannerCtx.onUserSelectHandler(user);
  }
}
</script>

<template>
  <div
    class="the-grid-sidebar-body w-full h-full overflow-hidden"
    ref="containerEl">
    <div
      class="w-full h-full"
      ref="contentEl"
      :style="{
        height: `${ contentHeight }px`,
        minWidth: `${ GRID_USERS_SIDEBAR_MIN_WIDTH }px`
      }"
    >
      <NoDataMessage v-show="!rows.length" />
      <div
        v-for="row in rows as GridRow[]"
        :key="row.id"
        class="the-grid-row"
        :class="{
          'the-grid-row--is-even': row.index % 2,
          'is-hover': row.id === hoveredRow?.id
        }"
        :style="{
          height: `${ row.height }px`
        }"
      >
        <div class="the-grid-cell text-gray-700 text-sm">
          <div class="flex h-full gap-2 items-center">
            <div class="flex-1 font-semibold flex gap-2 items-center">
              <div class="uppercase">{{ row.user.last_name }}</div>
              <div>{{ row.user.first_name }}</div>
            </div>

            <div
              class="cursor-pointer hover:text-white hover:bg-gray-500 rounded p-1 transition-colors overflow-hidden"
              @click="toggleUserSelect(row.user)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
