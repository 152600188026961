import { createConfig as createPlannerConfig, ThePlanner }                      from "@/components";
import { useNotification }                                                      from "@/components/notifications";
import type { AssignedIssue, PlannerFetchContext, Project, User }               from "@/components/ThePlanner/types";
import type {
  PlannerHandlers,
  PlannerLoaders
}                                                                               from "@/components/ThePlanner/types/planner";
import type { NonNullableKeys, Nullable }                                       from "@/types";
import {
  type UseAssignedIssuesReturn,
  type UseIssueDetailReturn,
  type UseProjectsReturn,
  type UseUnassignedIssuesReturn,
  type UseUsersReturn
}                                                                               from "@/views/PlannerView/use";
import {
  usePlannerStorage
}                                                                               from "@/views/PlannerView/use/usePlannerStorage";
import { getCurrentDateInterval, getNextDateInterval, getPreviousDateInterval } from "@/views/PlannerView/use/utils";
import { useCounter }                                                           from "@vueuse/core";
import { isWithinInterval, startOfDay }                                         from "date-fns";
import { type ComponentInstance, computed, nextTick, readonly, type Ref, ref }  from "vue";

export type UsePlannerConfigOptions = {
  usersController: UseUsersReturn;
  projectsController: UseProjectsReturn;
  assignedIssuesController: UseAssignedIssuesReturn;
  unassignedIssuesController: UseUnassignedIssuesReturn;
  issueDetailController: UseIssueDetailReturn;
  handlers?: Partial<PlannerHandlers>;
  plannerInstanceRef: Ref<Nullable<ComponentInstance<typeof ThePlanner>>>;
}

export type UsePlannerConfigReturn = ReturnType<typeof usePlannerConfig>;

export function usePlannerConfig(options: UsePlannerConfigOptions) {
  const {
    usersController,
    unassignedIssuesController,
    projectsController,
    assignedIssuesController,
    issueDetailController,
    plannerInstanceRef
  } = options;

  const { viewType: view, wrikeColumnsCount } = usePlannerStorage();

  const { notify: notifyUser } = useNotification<User>({
    group: "user-selection"
  });
  const { notify } = useNotification();

  const _loaderCounters: Record<keyof Required<PlannerLoaders>, ReturnType<typeof useCounter>> = {
    global: useCounter(0, { min: 0 }),
    viewContent: useCounter(0, { min: 0 }),
    unassignedIssues: useCounter(0, { min: 0 })
  };

  const dateInterval = ref(getCurrentDateInterval());

  const loaders: NonNullableKeys<PlannerLoaders, "unassignedIssues" | "global" | "viewContent"> = {
    global: computed(() => {
      return (
        _loaderCounters.global.count.value > 0 ||
        issueDetailController.isLoading.value
      );
    }),
    viewContent: computed(() => {
      if ( loaders.global.value ) {
        return false;
      }

      return (
        _loaderCounters.viewContent.count.value > 0 ||
        usersController.isLoading.value ||
        assignedIssuesController.isLoading.value
      );
    }),
    unassignedIssues: computed(() => {
      if ( loaders.global.value ) {
        return false;
      }

      return (
        _loaderCounters.unassignedIssues.count.value > 0 ||
        unassignedIssuesController.isLoading.value
      );
    })
  };

  const fetchContext = ref<PlannerFetchContext>({
    unplannedIssuesSortBy: "last-update",
    issuesFilter: {
      type: "project",
      value: null
    }
  });

  const projectsFiltered = computed(() => {
    const { issuesFilter } = fetchContext.value;
    const { projects } = projectsController;

    if (
      issuesFilter.type === "project" &&
      issuesFilter.value
    ) {
      return projects.value.filter(
        (project: Project) => project.redmine_id === issuesFilter.value!.redmine_id
      );
    } else {
      return projects.value;
    }
  });

  const issues = computed(() => {
    const { issuesFilter } = fetchContext.value;
    const { issues: _issues } = assignedIssuesController;

    if (
      issuesFilter.type === "project" &&
      issuesFilter.value
    ) {
      return _issues.value.filter(
        (issue: AssignedIssue) =>
          issue.project.redmine_id === issuesFilter.value!.redmine_id
      );
    } else {
      return _issues.value;
    }
  });

  const handlers: PlannerHandlers = {
    onMounted: async () => {
      showLoader("global");

      await Promise.allSettled([
        projectsController.fetch(),
        usersController.fetch(),
        unassignedIssuesController.fetch(fetchContext.value),
        assignedIssuesController.fetch(dateInterval.value)
      ]);

      hideLoader("global");
    },
    onRefreshUnassignedIssues: () => {
      if ( issueDetailController.issue.value ) {
        return;
      }

      void unassignedIssuesController.fetch(fetchContext.value);
    },
    onIssueUpdate: async (params) => {
      const { issue, updatedData } = params;

      showLoader("viewContent");
      const updatedIssue = await assignedIssuesController.update({
        issueRedmineId: issue.redmine_id,
        startDateTime: updatedData.start_date_time,
        endDateTime: updatedData.end_date_time,
        assignedToRedmineId: updatedData.assigned_to_planner.redmine_id
      });
      hideLoader("viewContent");

      if ( updatedIssue ) {
        notify({
          type: "success",
          text: `Issue #${ updatedIssue.redmine_id } úspěšně upraveno.`
        });
      } else {
        notify({
          type: "warn",
          text: `Issue #${ params.issue.redmine_id } se nopodařilo upravit.`
        });
      }

      return !!updatedIssue;
    },
    onIssueDelete: async (params) => {
      const { issue } = params;

      showLoader("viewContent");
      const successUnassigned = await assignedIssuesController.unassign({ issueRedmineId: issue.redmine_id });
      hideLoader("viewContent");

      if ( successUnassigned ) {
        // all is good
        void unassignedIssuesController.fetch(fetchContext.value);

        notify({
          type: "success",
          text: `Issue #${ params.issue.redmine_id } úspěšně odebráno.`
        });
      } else {
        notify({
          type: "warn",
          text: `Issue #${ params.issue.redmine_id } se nopodařilo odebrat.`
        });
      }

      return successUnassigned;
    },
    onDateIntervalChanged: async (interval) => {
      dateInterval.value = interval;
      await assignedIssuesController.fetch(interval);
      await nextTick(() => {
        const currentInterval = getCurrentDateInterval();

        if (
          isWithinInterval(interval.start, currentInterval) &&
          isWithinInterval(interval.end, currentInterval)
        ) {
          plannerInstanceRef.value?.scrollViewTo(startOfDay(new Date()));
        } else {
          plannerInstanceRef.value?.scrollViewTo(interval.start);
        }


      });
    },
    onUserDeselect: async (user) => {
      showLoader("viewContent");
      const updatedUser = await usersController.updateUserSelectedStatus(
        user.redmine_id,
        false
      );
      hideLoader("viewContent");

      if ( updatedUser ) {
        notifyUser({
          type: "success",
          data: updatedUser
        });
      } else {
        notify({
          type: "warn",
          text: `Uživatel  se nopodařilo odebrat.`
        });
      }
    },
    onUserSelect: async (user) => {
      showLoader("viewContent");
      const updatedUser = await usersController.updateUserSelectedStatus(
        user.redmine_id,
        true
      );
      hideLoader("viewContent");

      if ( updatedUser ) {
        notifyUser({
          type: "success",
          data: updatedUser
        });
      } else {
        notify({
          type: "warn",
          text: `Uživatel  se nopodařilo přidat.`
        });
      }
    },
    onAssignUnassignedIssue: async (params) => {
      const {
        issue,
        assignedTo,
        startDateTime,
        endDateTime
      } = params;

      const createdIssue = await assignedIssuesController.create({
        issueRedmineId: issue.redmine_id,
        assignedToRedmineId: assignedTo.redmine_id,
        startDateTime,
        endDateTime
      });

      if ( createdIssue ) {
        await unassignedIssuesController.fetch(fetchContext.value);

        notify({
          type: "success",
          text: `Issue #${ createdIssue.redmine_id } úspěšně přiřazeno.`
        });
      } else {
        notify({
          type: "warn",
          text: `Nepodařilo se přiřadit issue.`
        });
      }
    },
    getCurrentDateInterval: () => {
      return getCurrentDateInterval();
    },
    getNextDateInterval: (interval) => {
      return getNextDateInterval(interval);
    },
    getPreviousDateInterval: (interval) => {
      return getPreviousDateInterval(interval);
    },
    onFetchContextChanged: (ctx) => {
      fetchContext.value = ctx;

      if ( issueDetailController.issue.value ) {
        return;
      }

      void unassignedIssuesController.fetch(fetchContext.value);
    },
    onWrikeColumnsCountChanged: (count: number) => {
      wrikeColumnsCount.value = count;
    },
    onViewTypeChanged: (value) => {
      view.value = value;
    },
    onIssueGoToRedmine: (issue) => {
      window.open(issue.url, "_blank");
    },
    onRefreshDataView: async () => {
      showLoader("global");

      await Promise.allSettled([
        usersController.fetch(),
        assignedIssuesController.fetch(dateInterval.value)
      ]);

      hideLoader("global");
    }
  };

  const config = createPlannerConfig({
    dateInterval: readonly(dateInterval),
    view: readonly(view),
    fetchContext: readonly(fetchContext),
    wrikeColumnsCount: readonly(wrikeColumnsCount),

    users: usersController.users,
    usersSelected: usersController.usersSelected,

    usersUnselected: usersController.usersUnselected,
    issues,

    issuesUnassigned: unassignedIssuesController.issues,
    projects: projectsController.projects,

    projectsFiltered,

    issuesHighlighted: issueDetailController.issues,

    handlers,
    loaders
  });

  function showLoader(type: keyof PlannerLoaders) {
    if ( type !== "global" && _loaderCounters.global.count.value > 0 ) {
      return;
    }

    _loaderCounters[type].inc(1);
  }

  function hideLoader(type: keyof PlannerLoaders) {
    if ( type !== "global" && _loaderCounters.global.count.value > 0 ) {
      return;
    }

    _loaderCounters[type].dec(1);
  }

  return {
    config,

    showLoader,
    hideLoader
  };
}
