import { SimpleInput }                                                                 from "@/components";
import type {
  TheDatepickerBaseProps
}                                                                                      from "@/components/inputs/TheDatepickerBase";
import type {
  BaseModalInstanceType
}                                                                                      from "@/components/modals/BaseModal";
import type {
  PlannerCtx
}                                                                                      from "@/components/ThePlanner/composables";
import {
  REGEX_UNSIGNED_NUMBER
}                                                                                      from "@/components/ThePlanner/config";
import type {
  AssignedIssue,
  User
}                                                                                      from "@/components/ThePlanner/types";
import {
  makeClone
}                                                                                      from "@/components/ThePlanner/utils";
import { useIssueService }                                                             from "@/service";
import type { Nullable }                                                               from "@/types";
import { cloneDate }                                                                   from "@/utils";
import type { TimeModel }                                                              from "@vuepic/vue-datepicker";
import { type PromisifyFn, useDebounceFn }                                             from "@vueuse/core";
import { addMinutes, differenceInMinutes, isSameDay, startOfDay }                      from "date-fns";
import { type ComponentInstance, computed, onMounted, reactive, type Ref, ref, watch } from "vue";

export type UseModalIssueDetailOptions = {
  plannerCtx: PlannerCtx;
  detailIssueRedmineId: Ref<number>;
  modalOpenState: Ref<boolean>;
  onClose: () => void;
  onOpen: () => void;
};

export type UseModalIssueDetailReturn = ReturnType<typeof useModalIssueDetail>;

export function useModalIssueDetail(options: UseModalIssueDetailOptions) {
  const baseModalRef = ref<BaseModalInstanceType | null>(null);
  const issueService = useIssueService();
  const issue = ref<AssignedIssue | null>(null);
  const loading = ref(false);
  const inputEstimatedRef = ref<Nullable<ComponentInstance<typeof SimpleInput>>>(null);
  const issueUpdated = reactive<{
    assignedTo: Nullable<User>;
    start: Nullable<AssignedIssue["start_date_time"]>;
    end: Nullable<AssignedIssue["end_date_time"]>;
    estimated: Nullable<AssignedIssue["estimated_hours"]>;
  }>({
    assignedTo: null,
    start: null,
    end: null,
    estimated: null
  });
  const onInputEstimatedInputDebounced: PromisifyFn<typeof onInputEstimatedInput> = useDebounceFn(onInputEstimatedInput, 250);
  const isDirty = computed(() => {
    return ![
      issueUpdated.start?.getTime() === issue.value?.start_date_time.getTime(),
      issueUpdated.end?.getTime() === issue.value?.end_date_time.getTime(),
      issueUpdated.assignedTo?.redmine_id === issue.value?.assigned_to_planner.redmine_id,
      issueUpdated.estimated === issue.value?.estimated_hours
    ].every(v => v);
  });

  const dateTimePickerConfigs = {
    start: {
      date: reactive<TheDatepickerBaseProps>({}),
      time: reactive<TheDatepickerBaseProps>({
        minutesIncrement: 15,
        minutesGridIncrement: 15,
        config: {
          modeHeight: 125
        }
      })
    },
    end: {
      date: reactive<TheDatepickerBaseProps>({}),
      time: reactive<TheDatepickerBaseProps>({
        minutesIncrement: 15,
        minutesGridIncrement: 15,
        config: {
          modeHeight: 125
        }
      })
    }
  };

  onMounted(() => {
    if ( options.modalOpenState.value ) {
      baseModalRef.value?.open();
    }
  });

  watch(() => options.modalOpenState.value, (v) => {
    if ( v ) {
      baseModalRef.value?.open();
    } else {
      baseModalRef.value?.close();
    }
  }, { immediate: true });

  function onModalOpen() {
    fetchIssueData();
    options.onOpen();
  }

  function onModalClose() {
    issue.value = null;
    loading.value = false;
    options.onClose();
  }

  function fetchIssueData() {
    loading.value = true;
    const { onFetchResponse, data, onFetchFinally } = issueService.getIssue(
      options.detailIssueRedmineId.value.toString()
    );

    onFetchResponse(() => {
      issue.value = data.value as AssignedIssue;
      issue.value.total_spent_hours ??= 0;
      issueUpdated.estimated = issue.value.estimated_hours ??
        _updateEstimatedBasedDatesChange(issue.value.start_date_time, issue.value.end_date_time);
      issueUpdated.start = new Date(issue.value.start_date_time);
      issueUpdated.end = new Date(issue.value.end_date_time);
      issueUpdated.assignedTo = (() => {
        const u = options.plannerCtx.users.value.find(u => u.redmine_id === issue.value?.assigned_to_planner.redmine_id);
        return u ? makeClone(u, true) : null;
      })();
      _updateDatetimePickerConfigs();
    });

    onFetchFinally(() => {
      loading.value = false;
    });
  }

  function onIssueDateChange(type: "start" | "end", date: Date) {
    switch ( type ) {
      case "start": {
        const oldDate = issueUpdated.start ?? date;
        const newDate = cloneDate(date, {
          hour: oldDate.getHours(),
          min: oldDate.getMinutes(),
          sec: 0,
          ms: 0
        });

        _normalizeIssueUpdatedDatetimeEnd(
          newDate,
          oldDate
        );

        issueUpdated.start = cloneDate(
          date,
          { hour: 8, min: 0, sec: 0, ms: 0 }
        );
      }
        break;
      case "end": {
        const hour = issueUpdated.end?.getHours() ?? 0;
        const min = issueUpdated.end?.getMinutes() ?? 0;

        issueUpdated.end = cloneDate(date, {
          hour,
          min
        });
      }
        break;
    }

    _updateEstimatedBasedDatesChange();
    _updateDatetimePickerConfigs();
  }

  function onIssueTimeChange(type: "start" | "end", time: TimeModel) {
    switch ( type ) {
      case "start": {
        if ( !issueUpdated.start ) return;

        const oldTime = issueUpdated.start;
        const newTime = cloneDate(oldTime, {
          hour: +time.hours,
          min: +time.minutes,
          sec: 0,
          ms: 0
        });

        _normalizeIssueUpdatedDatetimeEnd(
          newTime,
          oldTime
        );
      }
        break;
      case "end": {
      }
        break;
    }

    issueUpdated[type] = cloneDate(
      issueUpdated[type]!,
      {
        hour: +time.hours,
        min: +time.minutes,
        sec: 0,
        ms: 0
      }
    );

    _updateEstimatedBasedDatesChange();
    _updateDatetimePickerConfigs();
  }

  function onAssignedUserSelected(user: User) {
    issueUpdated.assignedTo = user;
  }

  function onInputEstimatedInput(e: InputEvent) {
    const target = e.target as HTMLInputElement;

    if ( REGEX_UNSIGNED_NUMBER.test(target.value) ) {
      _updateEstimatedBasedValueChange(
        target.valueAsNumber,
        issueUpdated.estimated ?? target.valueAsNumber
      );
    }
  }

  function onInputEstimatedBlur(e: InputEvent) {
    if ( !e.target || !inputEstimatedRef.value?.inputEl ) return;
    const target = e.target as HTMLInputElement;

    if ( !REGEX_UNSIGNED_NUMBER.test(target.value) ) {
      inputEstimatedRef.value.inputEl.value = issueUpdated.estimated!.toString();
    } else {
      // _updateEstimatedBasedValueChange(
      //   target.valueAsNumber,
      //   issueUpdated.estimated ?? target.valueAsNumber
      // );
    }
  }

  function onInputEstimatedKeyup(_e: KeyboardEvent) {
  }

  function onInputEstimatedKeydown(_e: KeyboardEvent) {
  }

  async function saveHandler() {
    if ( !issue.value ) return;

    loading.value = true;
    const success = await options.plannerCtx.onAssignedIssueUpdate({
      issue: issue.value,
      updatedData: {
        assigned_to_planner: {
          redmine_id: issueUpdated.assignedTo!.redmine_id,
          name: issueUpdated.assignedTo!.full_name
        },
        start_date_time: issueUpdated.start!,
        end_date_time: issueUpdated.end!
      }
    });

    loading.value = false;

    if ( success ) {
      baseModalRef.value?.close();
    }
  }

  function revertChanges() {
    fetchIssueData();
  }

  function _normalizeIssueUpdatedDatetimeEnd(newDateStart: Date, oldDateStart: Date) {
    if ( issueUpdated.end ) {
      // time
      const deltaMinutes = differenceInMinutes(newDateStart, oldDateStart);
      issueUpdated.end = addMinutes(issueUpdated.end, deltaMinutes);
    }
  }

  function _updateEstimatedBasedDatesChange(start?: Date, end?: Date) {
    const s = start ?? issueUpdated.start;
    const e = end ?? issueUpdated.end;

    if ( !s || !e ) return 0;
    const t = differenceInMinutes(e, s) / 60;
    issueUpdated.estimated = t;
    return t;
  }

  function _updateEstimatedBasedValueChange(newValue: number, oldValue: number) {
    if ( !issueUpdated.end ) return;

    const deltaHours = newValue - oldValue;

    issueUpdated.estimated = newValue;
    issueUpdated.end = addMinutes(issueUpdated.end, deltaHours * 60);
  }

  function _updateDatetimePickerConfigs() {
    // if ( issueUpdated.end ) {
    //   dateTimePickerConfigs.start.date.maxDate = startOfDay(issueUpdated.end);
    // }

    if ( issueUpdated.start ) {
      dateTimePickerConfigs.end.date.minDate = startOfDay(issueUpdated.start);
    }

    if ( issueUpdated.start && issueUpdated.end ) {
      if ( isSameDay(issueUpdated.start, issueUpdated.end) ) {
        dateTimePickerConfigs.end.time.minTime = {
          seconds: 0,
          hours: issueUpdated.start.getHours(),
          minutes: issueUpdated.start.getMinutes()
        };
      } else {
        dateTimePickerConfigs.end.time.minTime = undefined;
      }
    }
  }

  return {
    dateTimePickerConfigs,
    loading,
    issue,
    issueUpdated,
    baseModalRef,
    inputEstimatedRef,
    isDirty,
    fetchIssueData,
    onModalOpen,
    onModalClose,
    onInputEstimatedInput,
    onInputEstimatedInputDebounced,
    onInputEstimatedKeydown,
    onInputEstimatedKeyup,
    onInputEstimatedBlur,
    onIssueDateChange,
    onIssueTimeChange,
    onAssignedUserSelected,
    saveHandler,
    revertChanges
  };
}
