import { createFetch }           from "@/service/utils/createFetch";
import { useFetch as _useFetch } from "@vueuse/core";
import type { Nullable }         from "@/types";

let _fetch: Nullable<typeof _useFetch>;

export type HttpContext<T = any> = ReturnType<typeof _useFetch<T>>;

export function useFetch(): typeof _useFetch {
  return _fetch ? _fetch : (_fetch = createFetch());
}
