import EventBlock                                               from "@/components/ThePlanner/components/EventBlock/EnventBlock.vue";
import type { BlockBase, Issue, Position }                      from "@/components/ThePlanner/types";
import { resolveBlockType }                                     from "@/components/ThePlanner/utils";
import type { Nullable }                                        from "@/types";
import { useDebounceFn }                                        from "@vueuse/core";
import { type ComponentInstance, onUnmounted, ref, shallowRef } from "vue";

export type UseDraggedBlockListenerParams<TIssue extends Issue = Issue> = Pick<UseDraggedBlockCtxReturn<TIssue>, "block" | "mouseEvent">;
export type UseDraggedBlockListenerFn<TIssue extends Issue = Issue> = (params: UseDraggedBlockListenerParams<TIssue>) => void;
export type UseDraggedBlockListenerType = "start" | "move" | "end";

// export type CalculatePositionParams = {
//   e: MouseEvent;
//   width: number;
//   height: number;
// }

export type UseDraggedBlockCtxOptions = {};

export type UseDraggedBlockCtxCreateParams<TIssue extends Issue = Issue> = {
  issue: TIssue;
  mouseEvent: MouseEvent;
  width: number;
  height: number;
  pointerOffset?: Position;
  detailedInfo?: boolean;
}

export type UseDraggedBlockCtxReturn<TIssue extends Issue = Issue> = ReturnType<typeof useDraggedBlockCtx<TIssue>>;

export function useDraggedBlockCtx<TIssue extends Issue = Issue>(
  _options?: UseDraggedBlockCtxOptions
) {
  /*Private*/
  const _pointerOffset = ref<Nullable<Position>>(null);

  const eventBlockInstance = shallowRef<Nullable<ComponentInstance<typeof EventBlock>>>(null);
  const block = ref<Nullable<BlockBase<TIssue>>>(null);
  const isDragging = ref(false);
  const isVisible = ref(false);
  const mouseEvent = shallowRef<Nullable<MouseEvent>>(null);
  const listeners = new Map<UseDraggedBlockListenerType, Set<UseDraggedBlockListenerFn<TIssue>>>();
  const windowMouseMoveDebounced = useDebounceFn(windowMousemove, 0);

  function _calculateBlockPosition(e: MouseEvent, width: number, height: number): Position {
    const x = e.clientX - (_pointerOffset.value?.x ?? (width / 2));
    const y = e.clientY - (_pointerOffset.value?.y ?? (height / 2));

    return { x, y };
  }

  function create(params: UseDraggedBlockCtxCreateParams<TIssue>) {
    const {
      issue,
      mouseEvent: e,
      width,
      height,
      pointerOffset = null,
      detailedInfo = false
    } = params;
    mouseEvent.value = e;
    _pointerOffset.value = pointerOffset;

    block.value = {
      // @ts-expect-error
      issue,
      isResizeable: false,
      isHighlighted: false,
      isDraggable: false,
      isDetailedInfo: detailedInfo,
      id: issue.redmine_id.toString(),
      position: _calculateBlockPosition(
        e,
        width,
        height
      ),
      height,
      width,
      type: resolveBlockType(issue)
    };

    window.addEventListener("mousemove", windowMouseMoveDebounced);
    window.addEventListener("mouseup", windowMouseup);
    document.body.classList.add("select-none");

    _dispatchListeners("start");
    return block;
  }

  onUnmounted(() => {
    destroy();
    clearListeners();
  });

  function destroy() {
    window.removeEventListener("mousemove", windowMouseMoveDebounced);
    window.removeEventListener("mouseup", windowMouseup);

    block.value = null;
    mouseEvent.value = null;
    isDragging.value = false;
    isVisible.value = false;
    _pointerOffset.value = null;

    document.body.classList.remove("select-none");
  }

  function windowMousemove(e: MouseEvent) {
    mouseEvent.value = e;
    isVisible.value = true;
    isDragging.value = true;

    block.value!.position = _calculateBlockPosition(
      e,
      block.value!.width,
      block.value!.height
    );
    _dispatchListeners("move");
  }

  function windowMouseup(_e: MouseEvent) {
    _dispatchListeners("end");
    destroy();
  }

  function addListener(type: UseDraggedBlockListenerType, cb: UseDraggedBlockListenerFn<TIssue>) {
    if ( listeners.has(type) ) {
      listeners.get(type)!.add(cb);
    } else {
      listeners.set(type, new Set([cb]));
    }
  }

  function removeListener(type: UseDraggedBlockListenerType, cb: UseDraggedBlockListenerFn<TIssue>) {
    listeners.get(type)?.delete(cb);
  }

  function clearListeners() {
    listeners.clear();
  }

  function _dispatchListeners(type: UseDraggedBlockListenerType) {
    const params = {
      block,
      mouseEvent
    };
    listeners.get(type)?.forEach(cb => cb(params));
  }

  function setEventBlockInstance(instance: Nullable<ComponentInstance<typeof EventBlock>>) {
    eventBlockInstance.value = instance;
  }

  return {
    block,
    isDragging,
    isVisible,
    mouseEvent: mouseEvent,
    eventBlockInstance,
    create,
    destroy,
    addListener,
    removeListener,
    clearListeners,
    setEventBlockInstance
  };
}
