import type { User }                                         from "@/components/ThePlanner/types";
import { API_URLS }                                          from "@/service/config";
import { toUser }                                            from "@/service/User/toUser";
import { afterFetchHookFactory, type HttpContext, useFetch } from "@/service/utils";

export function useUserService() {
  const fetch = useFetch();

  function getUsers(): HttpContext<User[]> {
    return fetch(
      API_URLS.user(),
      {
        afterFetch: afterFetchHookFactory({ mapperFn: (data: any[]) => data.map(toUser) })
      })
      .get()
      .json();
  }

  function updateUserSelected(redmineId: User["redmine_id"], selected: boolean): HttpContext<User> {
    return fetch(
      API_URLS.userUpdate(redmineId.toString()),
      {
        afterFetch: afterFetchHookFactory({ mapperFn: toUser })
      }
    )
      .patch(
        JSON.stringify({ is_selected: selected }),
        "application/json"
      )
      .json();
  }

  return {
    getUsers,
    updateUserSelected
  };
}
