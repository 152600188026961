<script setup lang="ts">
import type { GridCtx }                 from "@/components/ThePlanner/components/TheGrid/composables";
import { GRID_USERS_SIDEBAR_MIN_WIDTH } from "@/components/ThePlanner/config";

defineOptions({
  name: "GridSidebarHeader"
});

const { gridCtx } = defineProps<{ gridCtx: GridCtx }>();
const { sidebarCtx } = gridCtx;

const { headerCtx } = sidebarCtx;
const { containerEl, contentEl } = headerCtx;
</script>

<template lang="html">
  <div
    class="the-grid-sidebar-header overflow-hidden"
    ref="containerEl"
  >
    <div
      class="w-full h-full"
      ref="contentEl"
      :style="{ minWidth: `${ GRID_USERS_SIDEBAR_MIN_WIDTH }px`}"
    >
      <div class="w-full h-full">
        <div class="w-full h-full px-2 flex items-center justify-center">
          Vybraní uživatelé
        </div>
      </div>
    </div>
  </div>
</template>
