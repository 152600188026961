import type { DateListItem } from "@/components/ThePlanner/components/TheWrike/types";
import type {
  AssignedIssue,
  DateInterval,
  User
} from "@/components/ThePlanner/types";
import { addDays, differenceInDays, endOfDay, isToday, isWithinInterval, startOfDay } from "date-fns";

export function createDateBlockItems(
  dateInterval: DateInterval,
  user: User,
  issues: AssignedIssue[]
): DateListItem[] {
  const daysCount = differenceInDays(dateInterval.end, dateInterval.start);
  const dateBlocks: DateListItem[] = [];

  for ( let i = 0; i <= daysCount; i++ ) {
    const currentDate = addDays(dateInterval.start, i);
    const currentInterval: DateInterval = {
      start: startOfDay(currentDate),
      end: endOfDay(currentDate)
    };

    const issuesWithinInterval = issues.filter(issue => {
      return isWithinInterval(issue.start_date_time, currentInterval);
    });

    dateBlocks.push({
      id: `${ currentInterval.start.getTime() }__${ currentInterval.end.getTime() }`,
      index: i,
      dateInterval: currentInterval,
      user,
      issues: issuesWithinInterval,
      today: isToday(currentInterval.start)
    });
  }

  return dateBlocks;
}
