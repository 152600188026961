<script setup lang="ts">
import { TheLoader }        from "@/components";
import HorizontalScrollbars from "@/components/ThePlanner/components/TheGrid/components/HorizontalScrollbars.vue";
import GridBody             from "@/components/ThePlanner/components/TheGrid/components/TheBody.vue";
import GridHeader           from "@/components/ThePlanner/components/TheGrid/components/TheHeader.vue";
import { useGrid }          from "@/components/ThePlanner/components/TheGrid/composables";
import type { PlannerCtx }  from "@/components/ThePlanner/composables/usePlanner";

const { plannerCtx } = defineProps<{ plannerCtx: PlannerCtx }>();

defineOptions({
  name: "ThePlannerGrid"
});

const gridCtx = useGrid({
  plannerCtx: plannerCtx
});

const { containerEl } = gridCtx;

defineExpose<{
  scrollViewTo: typeof gridCtx.scrollViewTo
}>({
  scrollViewTo: gridCtx.scrollViewTo
});
</script>

<template>
  <div
    class="the-grid w-full h-full overflow-hidden border border-l-0 border-b-0 border-t-0 border-solid border-gray-300 flex flex-col"
    ref="containerEl"
  >
    <div class="basis-16 border-solid border-b border-gray-300 flex-shrink-0 flex-grow-0">
      <GridHeader :grid-ctx="gridCtx" />
    </div>

    <div class="flex-grow flex-shrink basis-0 overflow-y-auto relative">
      <TheLoader :visible="plannerCtx.loaders.viewContent?.value" />
      <GridBody :grid-ctx="gridCtx" />
    </div>

    <div v-if="gridCtx.isScrollbarHorizontal.value" class="relative">
      <TheLoader :visible="plannerCtx.loaders.viewContent?.value" disable-loader />
      <HorizontalScrollbars :grid-ctx="gridCtx" />
    </div>
  </div>
</template>

<style lang="postcss">
.the-planner {
  .scroll-x-gap {
    flex: 0 0 15px;
    width: 15px;
  }

  .scroll-y-gap {
    flex: 0 0 5px;
    height: 5px;
  }

  .the-grid-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    background-color: theme('colors.white');
    transition: height 0.3s linear, background-color 0.3s linear;

    &--is-even {
      background-color: theme('colors.gray.100');
    }

    &.is-hover,
    &.is-focus {
      background-color: theme('colors.yellow.400');
    }
  }

  .the-grid-cell {
    width: 100%;
    height: 100%;
    padding: 0 theme('padding.2');
  }
}
</style>
