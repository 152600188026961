import type { GetUnassignedIssuesParams } from "@/service/Issue/types";

export const API_URLS = Object.freeze({
  updateOutdated: () => {
    return `${ _getOriginURL() }/api/issue/update-outdated/`;
  },
  login: () =>
    `${ _getOriginURL() }/api/auth/login/`,

  issueDetail: (redmineId: string) => {
    return `${ _getOriginURL() }/api/issue/${ redmineId }/`;
  },

  project: () => {
    return `${ _getOriginURL() }/api/project/`;
  },

  issue: (searchKey: GetUnassignedIssuesParams["filterType"], searchValue: string = "") => {
    switch ( searchKey ) {
      case "":
        return `${ _getOriginURL() }/api/issue/`;
      default:
        const url = `${ _getOriginURL() }/api/issue/${ searchKey }/${ searchValue }`;
        return searchValue ? `${ url }/` : url;
    }
  },

  issueTimeline: (redmineId?: string) => {
    const base = `${ _getOriginURL() }/api/issue/timeline/`;

    return redmineId ?
           `${ base }${ redmineId }/` :
           base;
  },

  user: () => {
    return `${ _getOriginURL() }/api/user/`;
  },

  userUpdate: (redmineId: string) => {
    return `${ _getOriginURL() }/api/user/${ redmineId }/`;
  }
});

function _getOriginURL() {
  const baz = import.meta.env.VITE_API_BASE_URL;
  return baz ?
         baz :
         window.location.origin;
}
