import { API_URLS }                   from "@/service/config";
import { type HttpContext, useFetch } from "@/service/utils";

export function useAppService() {
  const fetch = useFetch();

  function login(username: string, password: string): HttpContext<{ token: string }> {
    return fetch(API_URLS.login())
      .post({
        username,
        password
      })
      .json<{ token: string }>();
  }

  function updateOutdated() {
    return fetch(API_URLS.updateOutdated()).put().json();
  }

  return {
    login,
    updateOutdated
  };
}
