import type { GridRow } from "@/components/ThePlanner/components/TheGrid/types";
import type { Offset }  from "@/components/ThePlanner/types";

export function calculateRowOffset(rowIndex: number, rowHeight: number, rows: GridRow[]): Offset {
  const from = rows[rowIndex - 1]?.offset.to ?? 0;
  const to = from + rowHeight;

  return {
    from,
    to
  };
}
