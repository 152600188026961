import type { Issue }         from "@/components/ThePlanner/types";
import { resolveIssueIsDone } from "@/components/ThePlanner/utils/resolveIssueIsDone";

export function resolveIssueIsSpentTimeOverflow(issue: Issue): boolean {
  const totalSpentHours = issue.total_spent_hours ?? 0;
  const estimatedHours = issue.estimated_hours ?? 0;
  const isDone = resolveIssueIsDone(issue);

  if ( isDone ) {
    return false;
  }

  return (
    // (isNullable(issue.total_spent_hours) && isNullable(issue.estimated_hours)) ||
    (totalSpentHours > estimatedHours)
  );
}
