import type { GridRow }         from "@/components/ThePlanner/components/TheGrid/types";
import { calculateRowOffset }   from "@/components/ThePlanner/components/TheGrid/utils";
import { GRID_ROW_MIN_HEIGHT }  from "@/components/ThePlanner/config";
import type { User }            from "@/components/ThePlanner/types";
import { ref, type Ref, watch } from "vue";

export type UseGridRowsOptions = {
  users: Ref<User[]>;
}

export type UseGridRowsReturn = ReturnType<typeof useGridRows>;

export function useGridRows(options: UseGridRowsOptions) {
  const rows = ref<GridRow[]>([]);

  watch(
    () => options.users.value,
    (users) => {
      const rows_ = Array.from<GridRow>({ length: users.length });

      for ( let i = 0, length = users.length; i < length; i++ ) {
        const user = users[i]!;
        const height = GRID_ROW_MIN_HEIGHT;

        rows_[i] = {
          id: user.redmine_id,
          index: i,
          maxBlockLevelIndex: 1,
          eventBlocks: [],
          eventBlocksCount: 0,
          user,
          height,
          offset: calculateRowOffset(i, height, rows_)
        };
      }

      rows.value = rows_;
    },
    { immediate: true }
  );

  return rows;
}
