// import { datetimeFormatters }      from "@/utils";
import type { TheDatepickerBaseProps } from "@/components/inputs/TheDatepickerBase/types";
import { makeClone }                   from "@/components/ThePlanner/utils";
import { isToday }                     from "date-fns";
import { cs as localeCS }              from "date-fns/locale";
import { merge }                       from "lodash-es";

export const DEFAULT_PROPS: TheDatepickerBaseProps = {
  highlight: {
    weekdays: [0, 6]
  },
  yearFirst: true,
  config: {
    closeOnAutoApply: true
  },
  formatLocale: localeCS,
  hideNavigation: [],
  inputClassName: "outline-none pr-2 text-center text-sm",
  hideInputIcon: true,
  menuClassName: "",
  calendarClassName: "the-datepicker-calendar",
  calendarCellClassName: "the-datepicker-cell",
  teleport: true,
  dayClass: date => isToday(date) ? "the-datepicker-today-day" : "",
  autoApply: true,
  clearable: false,
  spellcheck: false
};

export function mergeWithDefaultProps(props: TheDatepickerBaseProps): TheDatepickerBaseProps {
  return merge(makeClone(DEFAULT_PROPS, true), props);
}
