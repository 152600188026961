import { useElementBounding, useElementSize } from "@vueuse/core";
import { computed, ref }                      from "vue";

export type GridSidebarOptions = {};


function _useGridPartContainer() {
  const containerEl = ref<HTMLElement | null>(null);
  const contentEl = ref<HTMLElement | null>(null);

  const containerElSize = useElementSize(containerEl);
  const contentElSize = useElementSize(contentEl);

  const containerElRect = useElementBounding(containerEl);
  const contentElRect = useElementBounding(contentEl);

  function isMousePositionInsideContainerEl(e: MouseEvent) {
    return !(
      (e.clientX < containerElRect.x.value) ||
      (e.clientX > containerElRect.x.value + containerElRect.width.value) ||
      (e.clientY < containerElRect.y.value) ||
      (e.clientY > containerElRect.y.value + containerElRect.height.value)
    );
  }

  function isMousePositionInsideContentEl(e: MouseEvent) {
    return !(
      (e.clientX < contentElRect.x.value) ||
      (e.clientX > contentElRect.x.value + contentElRect.width.value) ||
      (e.clientY < contentElRect.y.value) ||
      (e.clientY > contentElRect.y.value + contentElRect.height.value)
    );
  }

  return {
    containerEl,
    contentEl,

    containerElSize,
    contentElSize,

    containerElRect,
    contentElRect,

    isMousePositionInsideContainerEl,
    isMousePositionInsideContentEl
  };
}

export type GridSidebarCtx = ReturnType<typeof useGridSidebar>;

export function useGridSidebar(_options: GridSidebarOptions) {
  const headerCtx = _useGridPartContainer();
  const bodyCtx = _useGridPartContainer();

  const scrollLeft = computed({
    get() {
      return headerCtx.containerEl.value?.scrollLeft ?? 0;
    },
    set(left) {
      headerCtx.containerEl.value?.scroll({ left });
      bodyCtx.containerEl.value?.scroll({ left });
    }
  });

  const contentWidth = computed(() => {
    return headerCtx.contentElSize.width.value;
  });

  const contentWidthPx = computed(() => {
    return `${ contentWidth.value }px`;
  });

  const isScrollbarHorizontal = computed(() => {
    return headerCtx.contentElSize.width.value > headerCtx.containerElSize.width.value;
  });

  return {
    headerCtx,
    bodyCtx,
    scrollLeft,
    contentWidth,
    contentWidthPx,
    isScrollbarHorizontal
  };
}
