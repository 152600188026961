<script setup lang="ts">
import type { IssueMetaInfoPropertiesProps } from "@/components/ThePlanner/components/IssueMetaInfo/types";
import { formatDate, formatDatetime }        from "@/utils/datetimeFormatters";
import { computed }                          from "vue";

defineOptions({
  name: "IssueDetailProperties"
});

const props = withDefaults(
  defineProps<IssueMetaInfoPropertiesProps>(),
  {
    hiddenProperties: () => []
  }
);

const assignedTo = computed(() => {
  return props.issue.assigned_to?.name ?? "unset";
});

const datetimeStart = computed(() => {
  if (
    props.issue.state === "unassigned" ||
    props.hiddenProperties.includes("datetimeStart")
  ) return "";

  return formatDatetime(props.issue.start_date_time);
});

const datetimeEnd = computed(() => {
  if (
    props.issue.state === "unassigned" ||
    props.hiddenProperties.includes("datetimeEnd")
  ) return "";

  return formatDatetime(props.issue.end_date_time);
});

const redmineDueDate = computed(
  () => props.issue.due_date ? formatDate(props.issue.due_date) : null
);

const redmineStartDate = computed(
  () => props.issue.start_date ? formatDate(props.issue.start_date) : null
);

const isAssignedIssue = computed(() => props.issue.state === "assigned");

const estimatedHours = computed(() => props.issue.estimated_hours ?? 0);
const spentHours = computed(() => props.issue.total_spent_hours ?? 0);
</script>

<template>
  <div class="flex flex-col text-xs gap-1">
    <div class="">
      <span class="font-semibold">Project:&nbsp;</span>
      <span>{{ issue.project.name }}</span>
    </div>

    <div class="">
      <span class="font-semibold">Subject:&nbsp;</span>
      <span>{{ issue.subject }}</span>
    </div>

    <div v-if="!hiddenProperties.includes('assignedToRedmine')" class="">
      <span class="font-semibold">Assigned:&nbsp;</span>
      <span> {{ assignedTo }}</span>
    </div>

    <div
      v-if="!hiddenProperties.includes('url')"
    >
      <span class="font-semibold">Redmine:&nbsp;</span>
      <a
        :href="issue.url"
        target="_blank"
        class="inline-flex gap-1 items-center hover:text-blue-500">
        <span>otevřít</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-3 h-3">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
        </svg>
      </a>
    </div>
    <div class="">
      <span class="font-semibold">Done:&nbsp;</span>
      <span>{{ issue.done_ratio }}&percnt;</span>
    </div>
    <div class="">
      <span class="font-semibold">Estimated:&nbsp;</span>
      <span>{{ estimatedHours }}h</span>
    </div>

    <div class="">
      <span class="font-semibold">Spent:&nbsp;</span>
      <span
        :class="{
          'text-green-600': estimatedHours >= spentHours,
          'text-red-600': spentHours > estimatedHours
        }"
      >{{ spentHours }}h</span>
    </div>

    <div class="" v-if="!hiddenProperties.includes('redmineStartDate')">
      <span class="font-semibold">Start date:&nbsp;</span>
      <span v-if="redmineStartDate">{{ redmineStartDate }}</span>
      <span v-else>unset</span>
    </div>

    <div class="" v-if="!hiddenProperties.includes('redmineDueDate')">
      <span class="font-semibold">Due date:&nbsp;</span>
      <span v-if="redmineDueDate">{{ redmineDueDate }}</span>
      <span v-else>unset</span>
    </div>

    <div
      v-if="
        isAssignedIssue &&
        (
          !hiddenProperties.includes('datetimeStart') ||
          !hiddenProperties.includes('datetimeEnd')
         )
      "
      class="flex pt-1 gap-3 justify-center"
    >
      <div
        v-if="!hiddenProperties.includes('datetimeStart')"
        class="gap-1 flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <div>{{ datetimeStart }}</div>
      </div>

      <div
        v-if="!hiddenProperties.includes('datetimeEnd')"
        class="gap-1 flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <span>{{ datetimeEnd }}</span>
      </div>
    </div>
  </div>
</template>
