<script setup lang="ts">
import { TheEventBlock, TheEventBlockContentIssue } from "@/components/ThePlanner/components/EventBlock";
import {
  UserBlock
}                                                   from "@/components/ThePlanner/components/TheWrike/components/UserBlock";
import { useScheduler, type WrikeCtx }              from "@/components/ThePlanner/components/TheWrike/composables";
import { computed }                                 from "vue";

const { wrikeCtx } = defineProps<{
  wrikeCtx: WrikeCtx;
}>();

const schedulerCtx = useScheduler({ wrikeCtx });

const {
  containerEl,
  contentEl,
  userBlockItems,
  assignedDraggedBlockCtx
} = schedulerCtx;

const layoutClass = computed(() => {
  return {
    2: "grid-cols-[minmax(400px,_1fr)_minmax(400px,_1fr)]",
    3: "grid-cols-[minmax(400px,_1fr)_minmax(400px,_1fr)_minmax(400px,_1fr)]",
    4: "grid-cols-[minmax(400px,_1fr)_minmax(400px,_1fr)_minmax(400px,_1fr)_minmax(400px,_1fr)]"
  }[schedulerCtx.plannerCtx.wrikeColumnsCount.value] ?? "";
});
</script>

<template>
  <div
    class="min-h-full h-0 overflow-auto"
    ref="containerEl"
  >
    <div
      :class="layoutClass"
      class="grid justify-items-center auto-rows-auto gap-2 p-2"
      ref="contentEl"
    >
      <UserBlock
        v-for="block in userBlockItems"
        :key="block.id"
        :user="block.user"
        :issues="block.issues"
        :scheduler-ctx="schedulerCtx"
      />
    </div>

    <Teleport to="body">
      <TheEventBlock
        v-if="assignedDraggedBlockCtx.isVisible.value && assignedDraggedBlockCtx.block.value"
        :z-index="1000"
        class="cursor-move"
        :ref="assignedDraggedBlockCtx.setEventBlockInstance"
        style="position: fixed;"
        :position="assignedDraggedBlockCtx.block.value.position"
        :height="assignedDraggedBlockCtx.block.value.height"
        :width="assignedDraggedBlockCtx.block.value.width"
        :type="assignedDraggedBlockCtx.block.value.type"
      >
        <TheEventBlockContentIssue
          class="w-full h-full"
          :issue="assignedDraggedBlockCtx.block.value.issue"
          :detailed="assignedDraggedBlockCtx.block.value.isDetailedInfo"
        />
      </TheEventBlock>
    </Teleport>
  </div>
</template>
