<script setup lang="ts">
import { SimpleSelect, type SimpleSelectOption } from "@/components";
import type { PlannerViewType }                  from "@/components/ThePlanner/types/planner";

defineProps<{ modelValue: PlannerViewType }>();
defineEmits<{ "update:modelValue": [PlannerViewType] }>();

const _modelValue = defineModel<PlannerViewType>({ required: true });
const _options = [
  {
    id: "timeline",
    value: "timeline",
    label: "Timeline"
  },
  {
    id: "wrike",
    value: "wrike",
    label: "Wrike"
  }
] as SimpleSelectOption<PlannerViewType>[];

</script>

<template>
  <SimpleSelect :options="_options" v-model="_modelValue" />
</template>
