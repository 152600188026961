<script setup lang="ts">
import { TheHeader, ThePlanner } from "@/components";
import AppButton                 from "@/components/buttons/BaseButton.vue";
import { FormSearchIssue }       from "@/components/forms";
import { usePlannerView }        from "@/views/PlannerView/usePlannerView";

const {
  plannerConfig,
  plannerInstanceRef,

  searchIssueHandler,
  formSearchIssueRedmineIdValue,
  updateFormSearchIssueInstanceRef,
  synchronizeIssuesHandler
} = usePlannerView();
</script>

<template>
  <div class="flex flex-col w-screen h-screen">
    <TheHeader>
      <template #side-left>
        <div class="px-1.5 gap-1.5 flex items-center">
          <FormSearchIssue
            v-model:redmine-id="formSearchIssueRedmineIdValue"
            :ref="updateFormSearchIssueInstanceRef"
            @submit="searchIssueHandler"
          />
          <AppButton
            label="Aktualizovat nedokončené Issues"
            icon="cloud-arrow-up"
            @click="synchronizeIssuesHandler"
          />
        </div>
      </template>
    </TheHeader>
    <main class="flex-1">
      <div class="w-full h-full p-3">
        <ThePlanner
          ref="plannerInstanceRef"
          :config="plannerConfig"
        />
      </div>
    </main>
  </div>
</template>
