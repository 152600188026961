import type { Position } from "@/components/ThePlanner/types";

export type GetPositionRelativeToOptions = {
}

export function getPositionRelativeTo(
  position: Position,
  container: HTMLElement = document.body,
  _options?: GetPositionRelativeToOptions
): Position {
  const rect = container.getBoundingClientRect();

  return {
    x: position.x - rect.x,
    y: position.y - rect.y
  };
}
