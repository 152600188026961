import type { SchedulerCtx }                         from "@/components/ThePlanner/components/TheGrid/composables/useScheduler";
import type {
  TimelineCtx
}                                                    from "@/components/ThePlanner/components/TheGrid/composables/useTimeline";
import type { GridRow }                              from "@/components/ThePlanner/components/TheGrid/types";
import { computeBlockDateInterval }                  from "@/components/ThePlanner/components/TheGrid/utils";
import TooltipContentBlockIssueDragged
                                              from "@/components/ThePlanner/components/TheTooltip/TooltipContentBlockIssueDragged.vue";
import type { UseDraggedBlockListenerParams } from "@/components/ThePlanner/composables";
import type { PlannerCtx }                    from "@/components/ThePlanner/composables/usePlanner";
import type { UnassignedIssue }                      from "@/components/ThePlanner/types";
import { getPositionRelativeTo, makeClone }          from "@/components/ThePlanner/utils";
import type { ReadonlyRef }                          from "@/types";
import { markRaw, nextTick, onMounted, onUnmounted } from "vue";

export type UseSchedulerUDraggedBlockHandlerOptions = {
  plannerCtx: PlannerCtx;
  timelineCtx: TimelineCtx;
  hoveredRow: ReadonlyRef<GridRow | null>;
  contentEl: ReadonlyRef<HTMLElement | null>;
} & Pick<
  SchedulerCtx,
  "isMousePositionInsideContainerEl" |
  "isMousePositionInsideCanvasEl"
>

export type UseSchedulerUDraggedBlockHandlerReturn = ReturnType<typeof useSchedulerUDraggedBlockHandler>;


export function useSchedulerUDraggedBlockHandler(options: UseSchedulerUDraggedBlockHandlerOptions) {
  const {
    plannerCtx,
    timelineCtx,
    hoveredRow,
    contentEl,
    isMousePositionInsideContainerEl,
    isMousePositionInsideCanvasEl
  } = options;
  const { unassignedDraggedBlockCtx, tooltipCtx } = plannerCtx;

  onMounted(() => {
    unassignedDraggedBlockCtx.addListener("move", _onDraggedUnassignedBlockMove);
    unassignedDraggedBlockCtx.addListener("end", _onDraggedUnassignedBlockEnd);
  });

  onUnmounted(() => {
    unassignedDraggedBlockCtx.removeListener("move", _onDraggedUnassignedBlockMove);
    unassignedDraggedBlockCtx.removeListener("end", _onDraggedUnassignedBlockEnd);
  });

  function _onDraggedUnassignedBlockMove(params: UseDraggedBlockListenerParams) {
    const { block, mouseEvent } = params;
    if ( !block.value || !mouseEvent.value ) return;

    const isInsideContainerEl = isMousePositionInsideContainerEl(mouseEvent.value);
    const isInsideCanvasEl = isMousePositionInsideCanvasEl(mouseEvent.value);

    // show the tooltip
    if ( isInsideContainerEl && isInsideCanvasEl ) {
      const relativePosition = getPositionRelativeTo(
        block.value.position,
        contentEl.value!
      );

      const clonedBlock = makeClone(block.value!);
      clonedBlock.position = relativePosition;

      const { start: startDate, end: endDate } = computeBlockDateInterval(
        clonedBlock,
        timelineCtx,
        {
          basedOn: "estimated-hours"
        }
      );

      tooltipCtx.state.component = markRaw(TooltipContentBlockIssueDragged);
      tooltipCtx.state.componentProps = {
        issue: block.value.issue,
        startDate,
        endDate,
        message: ""
      };
      tooltipCtx.state.classList = "w-[300px]";
      tooltipCtx.state.position = relativePosition;
      tooltipCtx.state.visible = true;

      nextTick(() => {
        const { tooltipElBounding } = tooltipCtx;

        tooltipCtx.state.position.x = block.value!.position.x +
          ((block.value!.width - tooltipElBounding.width.value) / 2);

        tooltipCtx.state.position.y = (block.value!.position.y -
          tooltipElBounding.height.value - 8);
      });
    } else {
      tooltipCtx.clear();
    }
  }

  function _onDraggedUnassignedBlockEnd(params: UseDraggedBlockListenerParams) {
    const {
      block,
      mouseEvent
    } = params;
    tooltipCtx.clear();

    if (
      !block.value ||
      !mouseEvent.value ||
      !isMousePositionInsideCanvasEl(params.mouseEvent.value!) ||
      !hoveredRow.value
    ) {
      return;
    }

    const relativePosition = getPositionRelativeTo(
      block.value.position,
      contentEl.value!
    );

    const clonedBlock = makeClone(block.value!);
    clonedBlock.position = relativePosition;

    const { start: startDateTime, end: endDateTime } = computeBlockDateInterval(
      clonedBlock,
      timelineCtx,
      {
        basedOn: "estimated-hours"
      }
    );

    plannerCtx.onUnplannedIssuePlan({
      issue: block.value!.issue as UnassignedIssue,
      assignedTo: hoveredRow.value.user,
      endDateTime,
      startDateTime
    });
  }
}
