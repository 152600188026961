import type { AssignedIssue, BlockBase, Issue, Position } from "@/components/ThePlanner/types";
import type { Nullable }                                  from "@/types";
import { readonly, ref }                                  from "vue";

export type UseSchedulerBlockContextMenuOptions<TIssue extends Issue = Issue> = {
  handlers: {
    onDelete(block: BlockBase<TIssue>): void;
    onGoToRedmine(block: BlockBase<TIssue>): void;
    onEdit(block: BlockBase<TIssue>): void;
  };
  offset?: {
    top?: number,
    left?: number
  };
};

export type UseSchedulerBlockContextMenuReturn = ReturnType<typeof useSchedulerBlockContextMenu>;

export function useSchedulerBlockContextMenu(options: UseSchedulerBlockContextMenuOptions<AssignedIssue>) {
  const position = ref<Position>({ x: 0, y: 0 });
  const visible = ref(false);
  const currentBlock = ref<Nullable<BlockBase<AssignedIssue>>>(null);
  const handlers: Record<keyof UseSchedulerBlockContextMenuOptions["handlers"], Function> = {
    onGoToRedmine() {
      if ( !currentBlock.value ) return;
      options.handlers.onGoToRedmine(currentBlock.value);
      hide();
    },
    onDelete() {
      if ( !currentBlock.value ) return;
      options.handlers.onDelete(currentBlock.value);
      hide();
    },
    onEdit() {
      if ( !currentBlock.value ) return;
      options.handlers.onEdit(currentBlock.value);
    }
  };

  function show(block: BlockBase<AssignedIssue>, pos: Position) {
    position.value = {
      x: pos.x + (options.offset?.left ?? 0),
      y: pos.y + (options.offset?.top ?? 0)
    };
    currentBlock.value = block;
    visible.value = true;
  }

  function hide() {
    currentBlock.value = null;
    visible.value = false;
  }

  return {
    visible: readonly(visible),
    position: readonly(position),
    currentBlock: readonly(currentBlock),
    handlers,
    show,
    hide
  };
}
