import type { AssignedIssue, Issue, IssueStateType, UnassignedIssue } from "@/components/ThePlanner/types";
import {
  API_URLS
}                                                                     from "@/service/config";
import {
  toAssignedIssue
}                                                                     from "@/service/Issue/toAssignedIssue";
import {
  toUnassignedIssue
}                                                                     from "@/service/Issue/toUnassignedIssue";
import type {
  CreateIssueParams,
  DeleteIssueParams,
  GetIssuesParams,
  GetUnassignedIssuesParams,
  UpdateIssueParams
}                                                                     from "@/service/Issue/types";
import {
  afterFetchHookFactory,
  type HttpContext,
  useFetch
}                                                                     from "@/service/utils";
import {
  map
}                                                                     from "@/utils";
import {
  format
}                                                                     from "@/utils/datetimeFormatters";
import {
  formatISO
}                                                                     from "date-fns";

export type UseIssueServiceReturn = ReturnType<typeof useIssueService>;

export function useIssueService() {
  const fetch = useFetch();

  function getIssue(redmineId: string): HttpContext<Issue> {
    return fetch(
      API_URLS.issueDetail(redmineId),
      {
        afterFetch: afterFetchHookFactory({
          mapperFn: (data: any) => {
            const state = (data?.state ?? null) as (IssueStateType | null);

            switch ( state ) {
              case "assigned":
                return toAssignedIssue(data);
              case "unassigned":
                return toUnassignedIssue(data);
              default:
                return null;
            }
          }
        })
      }
    )
      .get()
      .json();
  }


  function getAssignedIssues(params: GetIssuesParams): HttpContext<AssignedIssue[]> {
    const url = new URL(API_URLS.issueTimeline());

    url.searchParams.set(
      "start_date_time",
      format(params.dateInterval.start, "yyyy-MM-dd")
    );

    url.searchParams.set(
      "end_date_time",
      format(params.dateInterval.end, "yyyy-MM-dd")
    );

    return fetch(
      url.toString(),
      {
        afterFetch: afterFetchHookFactory({
          mapperFn: (data: any) => {
            return map(data, toAssignedIssue);
          }
        })
      }
    )
      .get()
      .json();
  }

  function deleteAssignedIssue(params: DeleteIssueParams): HttpContext<UnassignedIssue> {
    return fetch(
      API_URLS.issueTimeline(params.issueRedmineId.toString()),
      {
        afterFetch: afterFetchHookFactory({
          mapperFn: toUnassignedIssue
        })
      }
    )
      .put({ state: "unassigned" })
      .json();
  }

  function createAssignedIssue(params: CreateIssueParams): HttpContext<AssignedIssue> {
    return updateAssignedIssue(params);
  }

  function updateAssignedIssue(params: UpdateIssueParams): HttpContext<AssignedIssue> {
    return fetch(
      API_URLS.issueTimeline(params.issueRedmineId.toString()),
      {
        afterFetch: afterFetchHookFactory({
          mapperFn: toAssignedIssue
        })
      }
    ).put({
       state: "assigned",
       assigned_to: params.assignedToRedmineId,
       start_date_time: formatISO(params.startDateTime),
       end_date_time: formatISO(params.endDateTime)
     })
     .json();
  }

  function getUnassignedIssues(params: GetUnassignedIssuesParams): HttpContext<UnassignedIssue[]> {
    const url = new URL(
      API_URLS.issue(params.filterType, encodeURIComponent(params.filterValue))
    );

    if ( params.sort ) {
      url.searchParams.set("sort", params.sort);
    }

    return fetch(
      API_URLS.issue(params.filterType, encodeURIComponent(params.filterValue)),
      {
        afterFetch: afterFetchHookFactory({
          mapperFn: (data: any[]) => map(data, toUnassignedIssue)
        })
      }
    )
      .get()
      .json();
  }

  return {
    getIssue,
    getAssignedIssues,
    createAssignedIssue,
    deleteAssignedIssue,
    updateAssignedIssue,
    getUnassignedIssues
  };
}
