import { BLOCK_EVENT_HEIGHT, BLOCK_EVENT_HEIGHT_DETAILED_INFO } from "@/components/ThePlanner/config";
import type { BlockBase, BlockType, Issue }                     from "@/components/ThePlanner/types";
import { resolveBlockType }                                     from "@/components/ThePlanner/utils";

export type CreateBlockBaseParams<TIssue extends Issue = Issue> = {
  issue: TIssue,
  timelineHourWidth: number,
  detailedInfo: boolean
};

export function createBlockBase<TIssue extends Issue = Issue>(params: CreateBlockBaseParams<TIssue>): BlockBase<TIssue> {
  const { issue, timelineHourWidth, detailedInfo } = params;
  const estimatedHours = issue.estimated_hours ?? 0;

  const type: BlockType = resolveBlockType(issue);
  const height = detailedInfo ?
                 BLOCK_EVENT_HEIGHT_DETAILED_INFO :
                 BLOCK_EVENT_HEIGHT;

  const width = Math.max(
    Math.ceil(estimatedHours * timelineHourWidth),
    timelineHourWidth
  );

  return {
    issue,
    id: issue.redmine_id.toString(),
    type,
    height,
    width,
    position: {
      x: 0,
      y: 0
    },
    isVisible: true,
    isDetailedInfo: detailedInfo,
    isDraggable: false,
    isHighlighted: false,
    isResizeable: false
  };
}
