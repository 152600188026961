import type { TimelineCtx }                              from "@/components/ThePlanner/components/TheGrid/composables";
import { useSchedulerBlocksHandlers }                    from "@/components/ThePlanner/components/TheGrid/composables";
import {
  useSchedulerBlocks
}                                                        from "@/components/ThePlanner/components/TheGrid/composables/useSchedulerBlocks";
import {
  useSchedulerCurrentTimeUI
}                                                        from "@/components/ThePlanner/components/TheGrid/composables/useSchedulerCurrentTimeUI";
import {
  useSchedulerUDraggedBlockHandler
}                                                        from "@/components/ThePlanner/components/TheGrid/composables/useSchedulerUDraggedBlockHandler";
import type { GridRow }                                  from "@/components/ThePlanner/components/TheGrid/types";
import type { PlannerCtx }                               from "@/components/ThePlanner/composables/usePlanner";
import { isPositionInsideArea }                          from "@/components/ThePlanner/utils";
import { useElementBounding, useElementSize, useScroll } from "@vueuse/core";
import { computed, type Ref, ref }                       from "vue";

export type UseSchedulerOptions = {
  plannerCtx: PlannerCtx;
  timelineCtx: TimelineCtx;
  rows: Readonly<Ref<GridRow[]>>;
  hoveredRow: Readonly<Ref<GridRow | null>>;
};

export type SchedulerCtx = ReturnType<typeof useScheduler>;

export function useScheduler(options: UseSchedulerOptions) {
  const {
    plannerCtx,
    timelineCtx,
    rows,
    hoveredRow
  } = options;

  const containerEl = ref<HTMLElement | null>(null);
  const containerElSize = useElementSize(containerEl);
  const containerElBounding = useElementBounding(containerEl);
  const containerScroller = useScroll(containerEl);
  const contentEl = ref<HTMLElement | null>(null);
  const contentElBounding = useElementBounding(contentEl);
  const canvasEl = ref<HTMLElement | null>(null);
  const canvasElBounding = useElementBounding(canvasEl);
  const lineTimestamp = useSchedulerCurrentTimeUI({
    timelineCtx,
    dateInterval: plannerCtx.dateInterval
  });

  const {
    blocks,
    eventBlocksRefs,
    updateEventBlockRef
  } = useSchedulerBlocks({
    dateInterval: plannerCtx.dateInterval,
    timelineCtx,
    issues: plannerCtx.issues,
    rows: options.rows,
    issuesHighlightedRedmineIds: plannerCtx.issuesHighlightedRedmineIds,
    isDetailedInfo: plannerCtx.isDetailedInfo
  });

  const eventBlockHandlers = useSchedulerBlocksHandlers({
    eventBlocksRefs,
    blocks,

    plannerCtx,
    timelineCtx,

    contentEl,

    containerElBounding,
    canvasElBounding,
    contentElBounding,

    hoveredRow
  });

  // side effects
  useSchedulerUDraggedBlockHandler({
    timelineCtx,
    plannerCtx,
    hoveredRow,
    contentEl,
    isMousePositionInsideCanvasEl,
    isMousePositionInsideContainerEl
  });

  const containerScrollLeftValue = computed({
    get() {
      return containerScroller.x.value;
    },
    set(left) {
      containerScroller.x.value = left;
      timelineCtx.containerScrollLeftValue.value = left;
    }
  });

  const contentWidth = computed(() => {
    return timelineCtx.contentWidth.value;
  });

  const contentHeight = computed(() => {
    return rows.value.reduce(
      (acc, row) => acc + row.height, 0
    );
  });

  const isScrollbarHorizontal = computed(() => {
    return contentWidth.value > containerElSize.width.value;
  });

  function isMousePositionInsideContentEl(e: MouseEvent) {
    return isPositionInsideArea(e, contentElBounding);
  }

  function isMousePositionInsideContainerEl(e: MouseEvent) {
    return isPositionInsideArea(e, containerElBounding);
  }

  function isMousePositionInsideCanvasEl(e: MouseEvent) {
    return isPositionInsideArea(e, canvasElBounding);
  }

  return {
    blocks,
    eventBlocksRefs,
    updateEventBlockRef,

    containerEl,
    containerScrollLeftValue,
    containerScroller,
    contentEl,
    canvasEl,
    isScrollbarHorizontal,
    contentWidth,
    contentHeight,
    lineTimestamp,

    isMousePositionInsideContentEl,
    isMousePositionInsideContainerEl,
    isMousePositionInsideCanvasEl,

    eventBlockHandlers
  };
}
