import type { Position } from "@/components/ThePlanner/types";
import type { MaybeRef } from "vue";
import { toValue }       from "vue";

export type AreaInfo = {
  x: MaybeRef<number>;
  y: MaybeRef<number>;
  width: MaybeRef<number>;
  height: MaybeRef<number>;
}

export type IsPositionInsideAreaOptions = {
  dir: "vertical" | "horizontal";
  offset?: number;
}

export function isPositionInsideArea(position: Position, areaInfo: AreaInfo, options?: IsPositionInsideAreaOptions) {
  const { dir, offset = 0 } = options ?? {};

  switch ( dir ) {
    case "horizontal":
      return isPositionInsideAreaHorizontal(position, areaInfo, offset);
    case "vertical":
      return isPositionInsideAreaVertical(position, areaInfo, offset);
    default:
      return (
        isPositionInsideAreaHorizontal(position, areaInfo, offset) &&
        isPositionInsideAreaVertical(position, areaInfo, offset)
      );
  }
}

function isPositionInsideAreaVertical(
  position: Pick<Position, "y">,
  areaInfo: Pick<AreaInfo, "y" | "height">,
  offset: number
) {
  return (
    position.y + offset >= toValue(areaInfo.y) &&
    position.y + offset <= toValue(areaInfo.y) + toValue(areaInfo.height)
  );
}

function isPositionInsideAreaHorizontal(
  position: Pick<Position, "x">,
  areaInfo: Pick<AreaInfo, "x" | "width">,
  offset: number
) {
  return (
    position.x + offset >= toValue(areaInfo.x) &&
    position.x + offset <= toValue(areaInfo.x) + toValue(areaInfo.width)
  );
}
