export const BLOCK_EVENT_HEIGHT = 36;
export const BLOCK_EVENT_HEIGHT_DETAILED_INFO = Math.round(BLOCK_EVENT_HEIGHT * 3);
export const GRID_ROW_PADDING_TOP = 8;
export const GRID_ROW_PADDING_BOTTOM = 8;
export const GRID_ROW_EVENT_BLOCKS_GAP = 6;
export const GRID_ROW_MIN_HEIGHT = GRID_ROW_PADDING_TOP + GRID_ROW_PADDING_BOTTOM + BLOCK_EVENT_HEIGHT;
export const UNASSIGNED_ISSUE_ITEM_MIN_HEIGHT = 50;
export const HOUR_COLUMN_MIN_WIDTH = 30;
export const GRID_USERS_SIDEBAR_MIN_WIDTH = 250;
export const UNASSIGNED_CONTAINER_WIDTH_PERCENTAGE = 0.2;
export const GRID_USERS_SECTION_WIDTH_PERCENTAGE = 0.18;

export const UNASSIGNED_DRAGGED_BLOCK_WIDTH = 200;
export const UNASSIGNED_DRAGGED_BLOCK_HEIGHT = BLOCK_EVENT_HEIGHT;


// Comparators
export const COMPARATOR_STRING_SORT = new Intl.Collator("cs", { usage: "sort" }).compare;
export const COMPARATOR_STRING_SEARCH = new Intl.Collator("cs", { usage: "search" }).compare;

export const REGEX_UNSIGNED_NUMBER = /^\d+([.]\d+)?$/;
