<script setup lang="ts">
import { ButtonPrimary }                                     from "@/components/buttons";
import { SimpleInput }                                       from "@/components/inputs/SimpleInput";
import { UserSelectSingle }                                  from "@/components/inputs/UserSelectSingle";
import { BaseModal }                                         from "@/components/modals/BaseModal";
import TheDateTimePicker
                                                             from "@/components/modals/IssueDetail/components/TheDateTimePicker.vue";
import type { ModalIssueDetailEmits, ModalIssueDetailProps } from "@/components/modals/IssueDetail/types";
import { useModalIssueDetail }                               from "@/components/modals/IssueDetail/useModalIssueDetail";
import { computed, toRef }                                   from "vue";

const props = defineProps<ModalIssueDetailProps>();
const emit = defineEmits<ModalIssueDetailEmits>();

const title = computed(() => `Issue #${ props.redmineId }`);

const {
  loading,
  issue,
  issueUpdated,
  dateTimePickerConfigs,
  baseModalRef,
  inputEstimatedRef,
  isDirty,
  onModalOpen,
  onModalClose,
  onInputEstimatedBlur,
  onInputEstimatedKeydown,
  onInputEstimatedInputDebounced,
  onInputEstimatedKeyup,
  onIssueTimeChange,
  onIssueDateChange,
  onAssignedUserSelected,
  revertChanges,
  saveHandler
} = useModalIssueDetail({
  plannerCtx: props.plannerCtx,
  detailIssueRedmineId: toRef(props, "redmineId"),
  modalOpenState: toRef(props, "open"),
  onOpen: () => {
    emit("open");
  },
  onClose: () => {
    emit("update:open", false);
    emit("close");
  }
});

</script>

<template>
  <teleport to="body">
    <BaseModal
      :loading
      :title
      ref="baseModalRef"
      width="550px"
      height="450px"
      :on-close-cb="onModalClose"
      :on-open-cb="onModalOpen"
    >
      <div
        v-if="issue"
        class="flex flex-col text-gray-700 w-full text-sm min-h-full justify-between"
      >
        <div class="flex flex-col w-full gap-2.5">
          <div class="flex flex-col gap-1.5">
            <!--ROW-->
            <div>
              <div class="flex gap-2 items-center">
                <div class="font-semibold">Project:</div>
                <div class="">
                  {{ issue.project.name }}
                </div>
              </div>
            </div>
            <!---->
            <!--ROW-->
            <div>
              <div class="flex gap-2 items-center">
                <div class="font-semibold">Subject:</div>
                <div class="">{{ issue.subject }}</div>
              </div>
            </div>
            <!---->
            <!--ROW-->
            <div class="flex gap-2">
              <div class="flex gap-2">
                <div class="font-semibold">Redmine:</div>
                <a class="hover:text-blue-500" target="_blank" :href="issue.url">otevřít</a>
              </div>
            </div>
            <!---->
            <!-- ROW -->
            <div>
              <div class="flex gap-2">
                <div class="font-semibold">Done:</div>
                <div class="hover:text-blue-500">{{ issue.done_ratio }}&percnt;</div>
              </div>
            </div>
          </div>
          <!---->
          <div class="flex flex-col gap-2.5">
            <!--ROW-->
            <div class="flex gap-2">
              <div class="flex items-center gap-2 w-full">
                <div class="font-semibold basis-auto">Assigned to:</div>
                <div class="flex-1">
                  <UserSelectSingle
                    :users-list="users"
                    :can-clear="false"
                    :model-value="issueUpdated.assignedTo"
                    @update:model-value="onAssignedUserSelected"
                  />
                </div>
              </div>
            </div>
            <!---->
            <!--ROW-->
            <div class="flex gap-3 justify-center">
              <div class="flex gap-2 flex-col items-center p-2">
                <div class="font-semibold">
                  Start:
                </div>
                <TheDateTimePicker
                  :model-value="issueUpdated.start"
                  :time-picker-config="dateTimePickerConfigs.start.time"
                  :date-picker-config="dateTimePickerConfigs.start.date"
                  @update:date="onIssueDateChange('start', $event)"
                  @update:time="onIssueTimeChange('start', $event)"
                />
              </div>
              <!---->
              <div class="flex flex-col gap-2 items-center p-2">
                <div class="font-semibold">End:</div>
                <TheDateTimePicker
                  :model-value="issueUpdated.end"
                  :time-picker-config="dateTimePickerConfigs.end.time"
                  :date-picker-config="dateTimePickerConfigs.end.date"
                  @update:date="onIssueDateChange('end', $event)"
                  @update:time="onIssueTimeChange('end', $event)"
                />
              </div>
            </div>
            <!---->
            <!--ROW-->
            <div class="flex gap-2">
              <div class="flex gap-2 items-center">
                <div class="font-semibold text-nowrap">
                  Spent&nbsp;/&nbsp;Estimated:
                </div>
                <!---->
                <div class="flex gap-2 items-center">
                  <div class="flex gap-1">
                    <div>{{ issue.total_spent_hours }}</div>
                    <div>h.</div>
                  </div>
                  <div>/</div>
                  <div class="flex items-center gap-1">
                    <!--                  <div>{{ issueUpdated.estimated }}</div>-->
                    <div class="w-20">
                      <SimpleInput
                        ref="inputEstimatedRef"
                        :model-value="issueUpdated.estimated"
                        type="number"
                        min="0"
                        step="0.25"
                        input-class="!px-2 text-center"
                        disable-clear-icon
                        @blur="onInputEstimatedBlur"
                        @input="onInputEstimatedInputDebounced"
                        @keydown="onInputEstimatedKeydown"
                        @keyup="onInputEstimatedKeyup"
                      />
                    </div>
                    <div>h.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---->
        <div class="flex justify-center gap-8 border-t border-dashed border-gray-600 pt-2.5">
          <ButtonPrimary
            class="min-w-28"
            :disabled="!isDirty"
            @click="revertChanges"
          >Vrátit změny
          </ButtonPrimary>
          <ButtonPrimary
            class="min-w-28"
            :disabled="!isDirty"
            @click="saveHandler"
          >
            Uložit
          </ButtonPrimary>
        </div>
      </div>
    </BaseModal>
  </teleport>
</template>
