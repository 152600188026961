import type { AssignedIssue, UnassignedIssue } from "@/components/ThePlanner/types";
import type { UseIssueServiceReturn }          from "@/service";
import { useIssueService }                     from "@/service";
import type { HttpContext }                    from "@/service/utils";
import type { Nullable }                       from "@/types";
import { handleHttpEvents }                    from "@/views/PlannerView/use/utils";
import { type Ref, ref, shallowRef }           from "vue";

type _Type = "assigned" | "unassigned";
// type _GetAssignedIssues = UseIssueServiceReturn["getAssignedIssues"];
// type _GetUnassignedIssues = UseIssueServiceReturn["getUnassignedIssues"];
// type _ResolveFetchFn<T extends _Type> = T extends "assigned" ?
//                                         _GetAssignedIssues :
//                                         _GetUnassignedIssues;

type _ResolveIssueType<T extends _Type> = T extends "assigned" ?
                                          AssignedIssue :
                                          UnassignedIssue;

export type UseIssuesFetchProviderOptions = {
  service?: UseIssueServiceReturn;
}

export type UseIssuesFetchProviderReturn = ReturnType<typeof useIssuesFetchProvider>;

export function useIssuesFetchProvider<
  T extends _Type,
  TIssue = _ResolveIssueType<T>
>(
  type: T,
  options?: UseIssuesFetchProviderOptions
) {
  const httpCtx = shallowRef<Nullable<HttpContext<TIssue[]>>>(null);
  const issues: Ref<TIssue[]> = ref([]);
  const issueService = options?.service ?? useIssueService();
  const fetchFn = ({
    assigned: issueService.getAssignedIssues,
    unassigned: issueService.getUnassignedIssues
  } as const)[type];

  function fetch(...params: Parameters<typeof fetchFn>): Promise<Nullable<TIssue[]>> {
    return new Promise((resolve, _reject) => {
      if (
        httpCtx.value?.isFetching.value &&
        httpCtx.value.canAbort.value
      ) {
        httpCtx.value.abort();
      }

      // @ts-expect-error
      const fetchCtx: HttpContext<TIssue[]> = fetchFn(...params);
      httpCtx.value = fetchCtx;

      handleHttpEvents({
        httpContext: fetchCtx,
        onResponse({ data }) {
          issues.value = data.value ?? [];
          resolve(issues.value);
        },
        onError(ctx) {
          issues.value = [];

          if ( ctx.aborted.value ) {
            resolve(issues.value);
          } else {
            resolve(null);
          }
        },
        onFinally(ctx) {
          if ( ctx === httpCtx.value ) {
            httpCtx.value = null;
          }
        }
      });
    });
  }

  return {
    issues,
    fetch,
    httpCtx
  };
}
