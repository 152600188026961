import type { DateInterval }                                       from "@/components/ThePlanner/types";
import { addDays, differenceInCalendarDays, endOfDay, startOfDay } from "date-fns";

export function getPreviousDateInterval(interval: DateInterval): DateInterval {
  let diff = differenceInCalendarDays(
    interval.end,
    interval.start
  );
  let { end, start } = interval;

  if ( diff === 0 ) {
    start = addDays(start, -1);
    end = addDays(end, -1);
  } else {
    start = addDays(interval.end, -diff);
    end = addDays(start, -diff);
  }

  return {
    start: startOfDay(start),
    end: endOfDay(end)
  };
}
