<script setup lang="ts">
import { mergeWithDefaultProps }       from "@/components/inputs/TheDatepickerBase/config";
import type { TheDatepickerBaseProps } from "@/components/inputs/TheDatepickerBase/types";
import type { ModelValue }             from "@vuepic/vue-datepicker";
import VueDatepicker                   from "@vuepic/vue-datepicker";
import { computed }                    from "vue";

const props = defineProps<{
  modelValue: ModelValue;
  config: TheDatepickerBaseProps;
}>();

const _modelValue = defineModel<ModelValue>();
const _config = computed<TheDatepickerBaseProps>(() => {
  return mergeWithDefaultProps(props.config);
});
</script>

<template>
  <VueDatepicker
    class="the-datepicker"
    v-model="_modelValue"
    v-bind="_config"
  >
    <template #input-icon>
      <span></span>
      <!--      <slot name="calendar-icon">-->
      <!--        <svg-->
      <!--          xmlns="http://www.w3.org/2000/svg"-->
      <!--          fill="none"-->
      <!--          viewBox="0 0 24 24"-->
      <!--          stroke-width="1.5"-->
      <!--          stroke="currentColor"-->
      <!--          class="w-6 h-6">-->
      <!--          <path-->
      <!--            stroke-linecap="round"-->
      <!--            stroke-linejoin="round"-->
      <!--            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />-->
      <!--        </svg>-->
      <!--      </slot>-->
    </template>
  </VueDatepicker>
</template>

<style lang="postcss">
@import "@vuepic/vue-datepicker/dist/main.css";

:root {
  --dp-font-size: theme("fontSize.xs");
  --dp-input-padding: theme("padding.2") theme("padding.3");
  --dp-font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --dp-cell-border-radius: 50%;
  --dp-input-icon-padding: 0.25rem;
  --dp-time-font-size: theme("fontSize.lg");
}

.the-datepicker {
/*  .dp__input_icon {
    @apply pl-2 h-5 w-5;
  }*/
}

.dp__theme_dark,
.dp__theme_light {
  --dp-primary-color: theme("colors.blue.400");
}

.the-datepicker-cell {
  border-radius: 50%;

  &.dp__cell_auto_range {
    border: 1px dashed theme("colors.blue.400");
  }

  &.dp__cell_auto_range_start {
  }

  &.dp__cell_auto_range_end {
  }
}

.the-datepicker-calendar {
  .dp__calendar_row {
    gap: 2px;
  }

  .dp__calendar_item {
    .dp__range_between {
      @apply bg-blue-400 text-white;
    }

    .dp__today {
      @apply bg-fuchsia-400 text-white;
      border: transparent;
    }

    .dp__cell_auto_range_start,
    .dp__cell_auto_range_end {
      & {
        @apply bg-blue-400 text-white;
      }
    }
  }
}
</style>
