import type { TimelineCtx }                                                       from "@/components/ThePlanner/components/TheGrid/composables";
import type {
  GridRow,
  SchedulerBlock
}                                                                                 from "@/components/ThePlanner/components/TheGrid/types";
import {
  computeBlockLevelIndex,
  createBlockBase
}                                                                                 from "@/components/ThePlanner/components/TheGrid/utils";
import { GRID_ROW_EVENT_BLOCKS_GAP, GRID_ROW_PADDING_TOP, HOUR_COLUMN_MIN_WIDTH } from "@/components/ThePlanner/config";
import type { AssignedIssue, User }                                               from "@/components/ThePlanner/types";
import { differenceInMinutes }                                                    from "date-fns";

export function createRowBlockEvents(
  nthRow: number,
  rows: GridRow[],
  user: User,
  issues: AssignedIssue[],
  timeline: TimelineCtx,
  detailedInfo: boolean,
  issueRedmineIdsToHighlight: number[]
): SchedulerBlock[] {
  const { minuteItemWidth, intervalStart } = timeline;
  const eventBlocks: SchedulerBlock[] = Array.from({ length: issues.length });
  const previousRow = rows[nthRow - 1];

  for ( let i = 0, length = issues.length; i < length; i++ ) {
    const issue = issues[i]!;
    const isHighlighted = issueRedmineIdsToHighlight.includes(issue.redmine_id);
    const { type, height, width, id } = createBlockBase({
      issue,
      detailedInfo,
      timelineHourWidth: timeline.hourItemWidth.value
    });

    // const spentMinutes = differenceInMinutes(issue.end_date_time, issue.start_date_time);
    const minutesFromStart = differenceInMinutes(issue.start_date_time, intervalStart.value);
    const levelIndex = computeBlockLevelIndex(
      { start: issue.start_date_time, end: issue.end_date_time },
      i,
      eventBlocks
    );

    const minWidth = Math.max(HOUR_COLUMN_MIN_WIDTH, timeline.hourItemWidth.value);
    const maxWidth = Number.MAX_SAFE_INTEGER;
    const position = (() => {
      const x = Math.ceil(minutesFromStart * minuteItemWidth.value);
      let y = (previousRow?.offset.to ?? 0);
      y += GRID_ROW_PADDING_TOP;
      y += (levelIndex * height);
      y += (levelIndex * GRID_ROW_EVENT_BLOCKS_GAP);

      return { x, y };
    })();

    eventBlocks[i] = {
      id,
      issue,
      user,
      levelIndex,
      height,
      minWidth,
      maxWidth,
      width,
      type: isHighlighted ? "highlighted" : type,
      isDetailedInfo: detailedInfo,
      isVisible: true,
      isResizeable: true,
      isDraggable: true,
      isHighlighted,
      position
    };
  }

  return eventBlocks;
}
