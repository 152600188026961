import type { IssuesStackItem }                                from "@/components/ThePlanner/components/IssuesStack";
import type { PlannerCtx }                                     from "@/components/ThePlanner/composables/usePlanner";
import type { UnassignedIssue }                                from "@/components/ThePlanner/types";
import { resolveIssueIsDone, resolveIssueIsSpentTimeOverflow } from "@/components/ThePlanner/utils";
import { computed, ref, watch }                                from "vue";

export type UseIssuesStackItemsOptions = {
  detailedInfoEnabled: PlannerCtx["isDetailedInfo"];
  issuesUnassigned: PlannerCtx["issuesUnassigned"];
  issuesHighlightedRedmineIds: PlannerCtx["issuesHighlightedRedmineIds"]
}

export type UseIssuesStackItemsReturn = ReturnType<typeof useIssuesStackItems>;

export function useIssuesStackItems(options: UseIssuesStackItemsOptions) {
  const items = ref<IssuesStackItem[]>([]);
  const itemsGroupedById = computed(() => {
    return new Map(items.value.map(item => [item.id, item]));
  });

  watch(() => options.detailedInfoEnabled.value, (enabled) => {
    items.value.forEach(item => {
      item.expanded = enabled;
    });
  }, { immediate: true });

  watch(
    () => [
      options.issuesUnassigned.value,
      options.issuesHighlightedRedmineIds.value
    ] as [UnassignedIssue[], number[]],
    (tuple: [UnassignedIssue[], number[]]) => {
      const [
        issuesUnassigned = [],
        issuesHighlightedRedmineIds = []
      ] = tuple;

      items.value = issuesUnassigned.map((issue: UnassignedIssue, index: number) => {
        const oldItem = itemsGroupedById.value.get(issue.redmine_id.toString());
        const expanded = oldItem?.expanded ?? options.detailedInfoEnabled.value;
        const isSpentHoursOverflow = resolveIssueIsSpentTimeOverflow(issue);
        const isDone = resolveIssueIsDone(issue);
        const isHighlighted = issuesHighlightedRedmineIds.includes(issue.redmine_id);

        return {
          index,
          id: issue.redmine_id.toString(),
          isDraggable: true,
          expanded,
          issue,
          isDone,
          isSpentHoursOverflow,
          isHighlighted
        } as IssuesStackItem;
      });
    },
    {
      immediate: true
    }
  );


  return items;
}
