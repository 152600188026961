<script setup lang="ts">
import type { AppButtonProps, AppButtonVariant } from "@/components/buttons/types";
import type { CSSClassValue }                    from "@/types";
import { computed }                              from "vue";

const props = withDefaults(
  defineProps<AppButtonProps>(),
  {
    variant: "primary"
  }
);

const slots = defineSlots<{
  icon?: (props: { icon?: AppButtonProps["icon"], iconSize?: AppButtonProps["iconSize"] }) => void;
  default?: (props: { label?: AppButtonProps["label"] }) => void;
}>();

const iconVisible = computed(() => {
  return !!(slots["icon"] || props.icon);
});

const variantClass = computed<CSSClassValue>(() => {
  return ({
    primary: "button--primary",
    secondary: "button--secondary"
  } as Record<AppButtonVariant, string>)[props.variant];
});
</script>

<template>
  <button class="button" :class="variantClass">
    <span class="button__icon" v-if="iconVisible">
      <slot name="icon" :icon="icon" :iconSize="iconSize">
        <AppIcon :name="icon" :size="iconSize" />
      </slot>
    </span>

    <span class="button__content">
      <slot :label="label">{{ label }}</slot>
    </span>
  </button>
</template>
