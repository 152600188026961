import type { IssueBaseRaw } from "@/components/ThePlanner/types";
import { merge }             from "lodash-es";

export function toIssueBaseRaw(data: Record<string, any>): IssueBaseRaw {
  const created_on = new Date(data["created_on"]);
  const due_date = data["due_date"] ? new Date(data["due_date"]) : null;
  const start_date = data["start_date"] ? new Date(data["start_date"]) : null;

  return merge<Record<string, any>, Partial<IssueBaseRaw>>(
    data,
    {
      created_on,
      due_date,
      start_date
    }
  ) as IssueBaseRaw;
}
