import { TheTooltip }                              from "@/components/ThePlanner/components";
import type { Position }                           from "@/components/ThePlanner/types";
import { useElementBounding }                      from "@vueuse/core";
import { isNull }                                  from "lodash-es";
import { computed, markRaw, reactive, shallowRef } from "vue";

export type UsePlannerTooltipCtxOptions = {};

export type UsePlannerTooltipCtxReturn = ReturnType<typeof usePlannerTooltipCtx>;
export type PlannerTooltipCtx = UsePlannerTooltipCtxReturn;

export function usePlannerTooltipCtx(_options?: UsePlannerTooltipCtxOptions) {
  const state = reactive({
    width: null as (number | null),
    height: null as (number | null),
    classList: null as (string | string[] | null),
    visible: false as boolean,
    position: { x: 0, y: 0 } as Position,
    component: null as (any | null),
    componentProps: null as (Record<string, any> | null)
  });

  const tooltipComponentRef = shallowRef<InstanceType<typeof TheTooltip> | null>(null);
  const tooltipEl = computed(() => tooltipComponentRef.value?.el ?? null);
  const tooltipElBounding = useElementBounding(tooltipEl);

  const widthNormalizedValue = computed(
    () => {
      return isNull(state.width) ? null : `${ state.width }px`;
    }
  );
  const heightNormalizedValue = computed(
    () => {
      return isNull(state.height) ? null : `${ state.height }px`;
    }
  );

  function clear() {
    state.visible = false;
    state.width = null;
    state.height = null;
    state.position = { x: 0, y: 0 };
    state.component = null;
    state.componentProps = null;
  }

  function setPosition(position: Position) {
    state.position = position;
  }

  function setVisible(visible: boolean) {
    state.visible = visible;
  }

  function setComponentProps(props: Record<string, any>) {
    state.componentProps = markRaw(props);
  }

  function setTooltipComponentRef(el: InstanceType<typeof TheTooltip> | null) {
    tooltipComponentRef.value = el;
  }

  // function setState(_state: Partial<PlannerTooltipCtxState>) {
  // }

  return {
    state,
    tooltipEl,
    tooltipElBounding,
    tooltipComponentRef,
    heightNormalizedValue,
    widthNormalizedValue,
    clear,
    setPosition,
    setTooltipComponentRef,
    setVisible,
    setComponentProps
    // setState
  };
}
