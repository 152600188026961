import type { IssueBaseRaw, UnassignedIssue } from "@/components/ThePlanner/types";
import { toIssueBaseRaw }                     from "@/service/Issue/toIssueBaseRaw";
import { merge }                              from "lodash-es";

export function toUnassignedIssue(data: Record<string, any>): UnassignedIssue {
  return merge<Record<string, any>, IssueBaseRaw, Partial<UnassignedIssue>>(
    data,
    toIssueBaseRaw(data),
    {}
  ) as UnassignedIssue;
}
