<script setup lang="ts">
import { TheLoader }                                            from "@/components";
import {
  normalizeStyleValue
}                                                               from "@/components/ThePlanner/utils/normalizeStyleValue";
import { isUndefined }                                          from "lodash-es";
import { computed, ref, type StyleValue, watch }                from "vue";
import type { BaseModalEmits, BaseModalExpose, BaseModalProps } from "./types";

defineOptions({
  name: "BaseModal"
});

const props = withDefaults(
  defineProps<BaseModalProps>(),
  {
    width: "700px",
    height: "auto",
    loading: false,
    opened: undefined
  }
);

const emit = defineEmits<BaseModalEmits>();
const _opened = ref(false);
const _openedModalValue = computed({
  get() {
    return isUndefined(props.opened) ? _opened.value : props.opened;
  },
  set(v) {
    if ( isUndefined(props.opened) ) {
      _opened.value = v;
    } else {
      emit("update:opened", v);
    }
  }
});
const _loading = ref(false);
const styleValue = computed<StyleValue>(() => {
  const width = normalizeStyleValue(props.width, undefined);
  const height = normalizeStyleValue(props.height, undefined);
  const maxHeight = "90vh";

  return {
    width,
    height,
    maxHeight
  };
});

watch(() => props.loading, (v: boolean | undefined) => {
  _loading.value = !!v;
}, { immediate: true });

watch(() => _openedModalValue.value, (v) => {
  if ( v ) {
    window.addEventListener("keydown", _onWindowKeydown);
  } else {
    window.removeEventListener("keydown", _onWindowKeydown);
  }
}, { immediate: true });

function _onWindowKeydown(e: KeyboardEvent) {
  if (
    e.key === "Escape" &&
    !props.disableClose &&
    !e.composedPath().some(item => item instanceof HTMLElement && item.classList.contains("the-modal")) ) {
    close();
  }
}

function open() {
  _openedModalValue.value = true;
  props.onOpenCb?.();
}

function close() {
  if ( props.disableClose || props.onCloseCb?.() === false ) return;
  _openedModalValue.value = false;
}

defineExpose<BaseModalExpose>({
  open,
  close
});
</script>

<template>
  <div class="the-modal-backdrop" v-if="_openedModalValue">
    <div
      class="the-modal border border-gray-300 bg-white rounded shadow-xl overflow-hidden"
      :style="styleValue"
    >
      <div class="flex flex-col w-full relative h-full">
        <!--HEADER-->
        <div class="basis-10  bg-gray-100 border-b border-gray-300">
          <div class="flex justify-between items-center h-full w-full px-2">
            <div class="text-gray-600 text-lg font-semibold">
              <slot name="title">{{ title }}</slot>
            </div>
            <div
              class="text-gray-600 hover:text-gray-900 cursor-pointer"
              @click="close()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </div>

        <!--CONTENT-->
        <div class="flex-1 relative overflow-hidden">
          <TheLoader :visible="_loading" />
          <div class="h-full bg-white p-2 overflow-auto">
            <div class="min-h-full h-1">
              <slot />
            </div>
          </div>
        </div>

        <!--FOOTER-->
        <div class="flex-0"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.the-modal {
  z-index: 101;
}

.the-modal-backdrop {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
}
</style>
