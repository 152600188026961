import { useUserBlockItems }  from "@/components/ThePlanner/components/TheWrike/composables/useUserBlockItems";
import type { WrikeCtx }      from "@/components/ThePlanner/components/TheWrike/composables/useWrike";
import { useDraggedBlockCtx } from "@/components/ThePlanner/composables";
import type { AssignedIssue } from "@/components/ThePlanner/types";
import type { Nullable }      from "@/types";
import { ref }                from "vue";

export type UseSchedulerOptions = {
  wrikeCtx: WrikeCtx;
}

export type SchedulerCtx = ReturnType<typeof useScheduler>;

export function useScheduler(options: UseSchedulerOptions) {
  const { wrikeCtx } = options;
  const { plannerCtx } = wrikeCtx;

  const containerEl = ref<Nullable<HTMLElement>>(null);
  const contentEl = ref<Nullable<HTMLElement>>(null);
  const assignedDraggedBlockCtx = useDraggedBlockCtx<AssignedIssue>();

  // const {
  //   blocksGroupedByUserRedmineID: schedulerBlocksGroupedByUserRedmineID
  // } = useSchedulerBlocks({
  //   issues: plannerCtx.issues,
  //   users: plannerCtx.usersSelected,
  //   dateInterval: plannerCtx.dateInterval
  // });

  // function onSchedulerBlockDragStart() {
  //
  // }

  const { blocks: userBlockItems } = useUserBlockItems({
    users: plannerCtx.usersSelected,
    dateInterval: plannerCtx.dateInterval,
    issues: plannerCtx.issues
  });

  return {
    plannerCtx,
    wrikeCtx,

    userBlockItems,
    assignedDraggedBlockCtx,

    containerEl,
    contentEl
  };
}
