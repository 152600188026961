import Home                               from "@/pages/Home.vue";
import Login                              from "@/pages/Login.vue";
import { useAuthStore }                   from "@/store";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  strict: import.meta.env.DEV,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login
    }
  ]
});

if ( import.meta.env.DEV ) {
  router.addRoute({
    path: "/playground",
    name: "Playground",
    component: () => import("@/pages/Playground.vue")
  });
}

router.beforeEach((to, _from, next) => {
  const authStore = useAuthStore();

  if ( to.meta.requiresAuth && !authStore.isAuthenticated ) {
    next("/login");
  }

  next(true);
});

export default router;
