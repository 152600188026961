<script setup lang="ts">
import type { BlockType, Position }                                                        from "@/components/ThePlanner/types";
import {
  getPositionRelativeTo,
  makeClone
}                                                                                          from "@/components/ThePlanner/utils";
import {
  normalizeStyleValue
}                                                                                          from "@/components/ThePlanner/utils/normalizeStyleValue";
import type { Nullable }                                                                   from "@/types";
import { useDraggable, useVModel }                                                         from "@vueuse/core";
import { computed, normalizeClass, normalizeStyle, reactive, ref, type StyleValue, toRef } from "vue";
import {
  useElementResizeable
}                                                                                          from "./../../composables/useElementResizeable";
import type { Emits, EventBlockDragEvent, Expose, Props }                                  from "./types";

defineOptions({
  name: "EventBlock"
});

const EVENT_BLOCK_CLASS_TYPES: Record<BlockType, string> = {
  error: "the-event-block--error",
  default: "",
  highlighted: "the-event-block--highlighted",
  success: "the-event-block--success",
  warn: "the-event-block--warn"
};

const props = withDefaults(
  defineProps<Props>(),
  {
    type: "default",
    minWidth: 20,
    position: () => ({ x: 0, y: 0 }),
    draggable: false,
    resizeable: false,
    relativeElement: null
  }
);

const emit = defineEmits<Emits>();
const positionModelValue = useVModel(
  props,
  "position",
  emit,
  {
    defaultValue: { x: 0, y: 0 },
    clone: (value) => makeClone(value)
  }
);

const el = ref<HTMLElement | null>(null);
const resizerLeftEl = ref<HTMLElement | null>(null);
const resizerRightEl = ref<HTMLElement | null>(null);
const contentElement = ref<HTMLElement | null>(null);
const isHovering = ref(false);
const isDragging = ref(false);

const resizeableInfo = useElementResizeable(el, {
  elementInfo: {
    x: computed(() => positionModelValue.value.x),
    y: computed(() => positionModelValue.value.y)
  },
  contextElement: toRef(props, "relativeElement"),
  resizerLeftElement: resizerLeftEl,
  resizerRightElement: resizerRightEl,
  onStart(resizeEvent) {
    if (
      !props.resizeable &&
      (
        (resizeEvent.dir === "left" && !props.resizeableRight) ||
        (resizeEvent.dir === "right" && !props.resizeableLeft)
      )
    ) {
      return false;
    }

    // positionModelValue.value = {
    //   x: resizeEvent.x,
    //   y: positionModelValue.value.y
    // };
    // emit("update:width", resizeEvent.width);
    emit("resize-start", resizeEvent);
  },
  onResize(resizeEvent) {
    // positionModelValue.value = {
    //   x: resizeEvent.x,
    //   y: positionModelValue.value.y
    // };
    // emit("update:width", resizeEvent.width);
    emit("resize", resizeEvent);
  },
  onEnd(resizeEvent) {
    // positionModelValue.value = {
    //   x: resizeEvent.x,
    //   y: positionModelValue.value.y
    // };
    // emit("update:width", resizeEvent.width);
    emit("resize-end", resizeEvent);
  }
});

const draggableInfo = reactive(useDraggable(el, {
  handle: contentElement,
  initialValue: positionModelValue,
  onStart(_position, event) {
    if ( !(
      event.button === 0 &&
      props.draggable &&
      !event.composedPath().some(el => el instanceof HTMLAnchorElement)
    ) ) {
      return false;
    }

    const position = _getElGlobalPosition();
    const dragEvent: EventBlockDragEvent = {
      type: "start",
      event,
      position,
      positionRelative: _calculatePositionRelative(
        "start",
        position,
        event
      ),
      relativeElement: props.relativeElement,
      el: el.value!
    };

    positionModelValue.value = props.getDragPosition?.(dragEvent) ?? dragEvent.position;

    emit("dragstart", dragEvent);
  },
  onMove(position, event) {
    isDragging.value = true;
    const dragEvent: EventBlockDragEvent = {
      type: "drag",
      position,
      event,
      el: el.value!,
      relativeElement: props.relativeElement,
      positionRelative: _calculatePositionRelative(
        "drag",
        position,
        event
      )
    };

    positionModelValue.value = props.getDragPosition?.(dragEvent) ?? dragEvent.position;
    emit("drag", dragEvent);
  },
  onEnd(_position, event) {
    isDragging.value = false;
    const position = _getElGlobalPosition();

    const dragEvent: EventBlockDragEvent = {
      type: "end",
      position,
      el: el.value!,
      relativeElement: props.relativeElement,
      positionRelative: _calculatePositionRelative(
        "end",
        position,
        event
      ),
      event
    };

    positionModelValue.value = props.getDragPosition?.(dragEvent) ?? dragEvent.position;
    emit("dragend", dragEvent);
  }
}));


const classValue = computed<string>(() => {
  return normalizeClass(
    [
      {
        "is-resizeable": props.resizeable || props.resizeableLeft || props.resizeableRight,
        "is-draggable": props.draggable,
        "is-dragging": isDragging.value,
        "is-hover": isHovering.value,
        "is-resizing": resizeableInfo.isResizing.value
      },
      draggableInfo.isDragging ? props.classDrag : undefined,
      isHovering.value ? props.classHover : undefined,
      resizeableInfo.isResizing.value ? props.classResize : undefined,
      EVENT_BLOCK_CLASS_TYPES[props.type]
    ]
  );
});

const styleValue = computed<StyleValue>(() => {
  const top = positionModelValue.value.y;
  const left = positionModelValue.value.x;

  return normalizeStyle([
    {
      top: normalizeStyleValue(top),
      left: normalizeStyleValue(left),
      width: normalizeStyleValue(props.width),
      maxWidth: normalizeStyleValue(props.maxWidth),
      minWidth: normalizeStyleValue(props.minWidth),
      height: normalizeStyleValue(props.height),
      minHeight: normalizeStyleValue(props.minHeight),
      zIndex: normalizeStyleValue(props.zIndex, undefined, "")
    },
    draggableInfo.isDragging ? props.styleDrag : undefined,
    isHovering.value ? props.styleHover : undefined,
    resizeableInfo.isResizing.value ? props.styleResize : undefined
  ]);
});

defineExpose<Expose>({
  el: el,
  resizerLeftEl: resizerLeftEl,
  resizerRightEl: resizerRightEl
});

function _getElGlobalPosition(): Position {
  const { x, y } = el.value!.getBoundingClientRect();
  return {
    x, y
  };
}

function _calculatePositionRelative(
  _type: EventBlockDragEvent["type"],
  position: Position,
  _event: PointerEvent
): Nullable<Position> {
  if ( props.relativeElement ) {
    return getPositionRelativeTo(
      position,
      props.relativeElement
    );
  }

  return null;
}

function contextmenuHandler(e: MouseEvent) {
  emit("contextmenu", e);
}
</script>

<template>
  <div
    class="the-event-block"
    :class="classValue"
    :style="styleValue"
    ref="el"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div
      class="the-event-block-content-wrapper"
      @contextmenu="contextmenuHandler"
    >
      <div
        v-if="resizeable"
        class="the-event-block-resizer the-event-block-resizer--left" data-direction="left"
        ref="resizerLeftEl"
      ></div>
      <div
        class="the-event-block-content"
        ref="contentElement"
      >
        <slot />
      </div>
      <div
        v-if="resizeable"
        class="the-event-block-resizer the-event-block-resizer--right" data-direction="right"
        ref="resizerRightEl"
      ></div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.the-event-block {
  @apply
  rounded
  overflow-hidden;

  .the-event-block-content-wrapper {
    @apply
    border-l-4
    border-solid
    border-blue-600
    bg-blue-300
    text-white
    h-full
    w-full
    relative;
  }

  .the-event-block-resizer {
    @apply absolute z-10 cursor-ew-resize bg-yellow-400 w-3 opacity-0 transition-opacity h-full;
  }

  .the-event-block-resizer--left {
    @apply top-0 -left-2;
  }

  .the-event-block-resizer--right {
    @apply top-0 -right-1;
  }

  .the-event-block-content {
    @apply h-full w-full flex-1;
    /* flex items-center */
  }

  &.is-hover {
    .the-event-block-content-wrapper {
      @apply bg-blue-400;
    }
  }

  &.is-resizeable {
    &.is-hover,
    &.is-resizing {
      @apply select-none;

      .the-event-block-resizer {
        @apply opacity-100;
      }
    }

    &.is-resizing {
      .the-event-block-content-wrapper {
        @apply bg-blue-400;
      }
    }
  }

  &:not(.is-resizing).is-draggable {
    .the-event-block-content {
      @apply cursor-pointer;
    }
  }

  &:not(.is-dragging).is-hover {
    .the-event-block-content-wrapper {
      /*@apply bg-blue-500;*/
    }
  }

  &:not(.is-resizing).is-dragging {
    @apply select-none opacity-50;

    .the-event-block-content {
      @apply cursor-grabbing;
    }
  }
}

.the-event-block {
  &--error {
    .the-event-block-content-wrapper {
      @apply border-red-600 bg-red-200;
    }

    &.is-hover {
      .the-event-block-content-wrapper {
        @apply bg-red-400;
      }
    }

    &.is-resizeable {
      &.is-hover,
      &.is-resizing {

        &.is-resizing {
          .the-event-block-content-wrapper {
            @apply bg-red-400;
          }
        }
      }
    }
  }

  &--highlighted {
    .the-event-block-content-wrapper {
      @apply border-pink-600 bg-pink-300;
    }

    &.is-hover {
      .the-event-block-content-wrapper {
        @apply bg-pink-400;
      }
    }

    &.is-resizeable {
      &.is-hover,
      &.is-resizing {

        &.is-resizing {
          .the-event-block-content-wrapper {
            @apply bg-pink-400;
          }
        }
      }
    }
  }

  &--success {
    .the-event-block-content-wrapper {
      @apply border-green-600 bg-green-200;
    }

    &.is-hover {
      .the-event-block-content-wrapper {
        @apply bg-green-400;
      }
    }

    &.is-resizeable {
      &.is-hover,
      &.is-resizing {

        &.is-resizing {
          .the-event-block-content-wrapper {
            @apply bg-green-400;
          }
        }
      }
    }
  }
}
</style>
