<script setup lang="ts">
import { TheMultiselect, type MultiselectProps } from "@/components/inputs/Multiselect";
import type { User }                             from "@/components/ThePlanner/types";
import type { UserSelectMultipleEmits, UserSelectMultipleProps } from "./types";

defineOptions({
  name: "UserSelectMultiple"
});

defineProps<UserSelectMultipleProps>();
const emit = defineEmits<UserSelectMultipleEmits>();

const config: MultiselectProps = {
  placeholder: "Výběr uživatelů",
  mode: "multiple",
  label: "full_name",
  valueProp: "redmine_id",
  trackBy: ["last_name", "first_name"],
  searchable: true,
  appendToBody: true,
  object: true,
  clearOnSelect: false,
  canClear: false,
  closeOnSelect: false,
  filterResults: true,
  strict: false,
  hideSelected: false
};

const _mv = defineModel({ default: () => [] });

function onSelectHandler(user: User) {
  emit("select", user);
}

function onDeselectHandler(user: User) {
  emit("deselect", user);
}
</script>

<template>
  <TheMultiselect
    v-model="_mv"
    :options="usersList"
    v-bind="config"
    class="the-multiselect"
    @select="onSelectHandler"
    @deselect="onDeselectHandler"
  >
    <template #multiplelabel="{ values }">
      <div class="multiselect-multiple-label font-normal">
        <div class="flex gap-2 items-center">
          <div>Vybráno&nbsp;<span>{{ values.length }}</span>&nbsp;uživatelů</div>
        </div>
      </div>
    </template>


    <template #option="{ option, isSelected }">
      <div class="w-full flex items-center font-semibold">
        <div class="flex-1">
          <span class="uppercase">{{ option.last_name }}</span>&nbsp;
          <span>{{ option.first_name }}</span>
        </div>

        <div class="w-6 h-6">
          <svg
            v-if="!isSelected(option)"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-full h-full">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
          </svg>

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-full h-full">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
          </svg>
        </div>
      </div>
    </template>
  </TheMultiselect>
</template>
