<script setup lang="ts">
</script>

<template>
  <div
    class="py-2 min-w-32 px-4 text-gray-600 bg-gray-200 rounded rounded-bl-2xl rounded-tr-2xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 text-center font-semibold"
  >
    <slot>Žádná data</slot>
  </div>
</template>
