<script setup lang="ts">
import IssuesList                    from "@/components/ThePlanner/components/IssuesStack/IssuesList.vue";
import type { IssuesStackBodyProps } from "@/components/ThePlanner/components/IssuesStack/types";
import NoDataMessage                 from "@/components/ThePlanner/components/NoDataMessage.vue";
import { type Ref, ref }             from "vue";

defineOptions({
  name: "IssuesStackBody"
});

defineProps<IssuesStackBodyProps>();
const el = ref<HTMLElement | null>(null);
const contentEl = ref<HTMLElement | null>(null);

defineExpose<{
  el: Ref<HTMLElement | null>;
  contentEl: Ref<HTMLElement | null>;
}>({
  el,
  contentEl
});
</script>

<template>
  <div
    class="the-issues-stack-body overflow-y-auto w-full h-full bg-white border-t border-gray-300"
    ref="el"
  >
    <div
      ref="contentEl"
      class="min-h-full min-w-[300px] h-0 relative"
    >
      <NoDataMessage v-show="!items.length" />
      <IssuesList
        :items
        :on-item-select="onItemSelect"
        :on-item-unselect="onItemUnselect"
        :on-item-mouseenter="onItemMouseenter"
        :on-item-mouseleave="onItemMouseleave"
        :on-item-expand-change="onItemExpandChange"
      />
    </div>
  </div>
</template>
