import type {
  OnAssignedBlockDrop,
  OnUnassignedBlockDrop
}                                                from "@/components/ThePlanner/components/TheWrike/components";
import {
  useEventBlockHandlers
}                                                from "@/components/ThePlanner/components/TheWrike/components/DateBlock/useEventBlockHandlers";
import {
  useSchedulerBlocks
}                                                from "@/components/ThePlanner/components/TheWrike/components/DateBlock/useSchedulerBlocks";
import type { SchedulerCtx }                     from "@/components/ThePlanner/components/TheWrike/composables";
import { useHandleUnassignedDraggedBlock }       from "@/components/ThePlanner/components/TheWrike/composables";
import type { UseDraggedBlockListenerParams }    from "@/components/ThePlanner/composables";
import type { AssignedIssue, DateInterval }      from "@/components/ThePlanner/types";
import { isPositionInsideArea }                  from "@/components/ThePlanner/utils";
import { format, formatDate }                    from "@/utils/datetimeFormatters";
import type { Nullable }                         from "@/types";
import { useElementBounding }                    from "@vueuse/core";
import { addHours, addMinutes, setHours }        from "date-fns";
import type { Ref }                              from "vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

export type UseDateBlockOptions =
  {
    issues: Ref<AssignedIssue[]>;
    dateInterval: Ref<DateInterval>;
    schedulerCtx: SchedulerCtx;
    onUnassignedBlockDrop: OnUnassignedBlockDrop;
    onAssignedBlockDrop: OnAssignedBlockDrop;
  }

export type UseDateBlockReturn = {};

export function useDateBlock(options: UseDateBlockOptions) {
  const {
    issues,
    schedulerCtx,
    dateInterval
  } = options;

  const { wrikeCtx } = schedulerCtx;
  const containerEl = ref<Nullable<HTMLElement>>(null);
  const containerElBounding = useElementBounding(containerEl);
  const isHighlighted = ref(false);

  const {
    blocks,
    eventBlocksRefs,
    updateEventBlockRef
  } = useSchedulerBlocks({
    issues,
    detailedInfo: schedulerCtx.plannerCtx.isDetailedInfo
  });

  const eventBlockHandlers = useEventBlockHandlers({
    schedulerCtx,
    blocks,
    eventBlocksRefs,
    dateInterval
  });

  useHandleUnassignedDraggedBlock({
    containerEl,
    wrikeCtx,
    onContainerElEnter(_params) {
      isHighlighted.value = true;
    },
    onContainerElLeave(_params) {
      isHighlighted.value = false;
    },
    onDragend(params) {
      const { isInsideContainer } = params;
      const { issue } = params.params.block.value!;

      if ( isInsideContainer ) {
        const startDateTime = setHours(dateInterval.value.start, 8);
        const endDateTime = addMinutes(startDateTime, (issue.estimated_hours ?? 1) * 60);

        options.onUnassignedBlockDrop({
          startDateTime,
          endDateTime,
          issue
        });
      }
    }
  });

  const issuesCount = computed(() => {
    return issues.value.length;
  });

  const labelDayOfWeek = computed(() => {
    return format(dateInterval.value.start, "EEEE");
  });

  const labelDate = computed(() => {
    return formatDate(dateInterval.value.start);
  });

  onMounted(() => {
    schedulerCtx.assignedDraggedBlockCtx.addListener("move", _onAssignedDraggedBlockMove);
    schedulerCtx.assignedDraggedBlockCtx.addListener("end", _onAssignedDraggedBlockEnd);
  });

  onUnmounted(() => {
    schedulerCtx.assignedDraggedBlockCtx.removeListener("move", _onAssignedDraggedBlockMove);
    schedulerCtx.assignedDraggedBlockCtx.removeListener("end", _onAssignedDraggedBlockEnd);

  });

  function _onAssignedDraggedBlockMove(params: UseDraggedBlockListenerParams) {
    const { mouseEvent } = params;
    isHighlighted.value = isPositionInsideArea({
        x: mouseEvent.value!.clientX,
        y: mouseEvent.value!.clientY
      },
      containerElBounding
    );
  }

  function _onAssignedDraggedBlockEnd(params: UseDraggedBlockListenerParams<AssignedIssue>) {
    const { mouseEvent, block } = params;

    const isInside = isPositionInsideArea({
        x: mouseEvent.value!.clientX,
        y: mouseEvent.value!.clientY
      },
      containerElBounding
    );

    isHighlighted.value = isInside;

    if ( isInside && !eventBlocksRefs.value.has(block.value!.id) ) {
      const startDateTime = addHours(options.dateInterval.value.start, 8);
      const endDateTime = addMinutes(startDateTime, (block.value?.issue.estimated_hours ?? 1) * 60);

      options.onAssignedBlockDrop({
        issue: block.value!.issue,
        startDateTime,
        endDateTime
      });
    }
  }

  function onElementMouseenter(_e: MouseEvent) {
    isHighlighted.value = true;
  }

  function onElementMouseleave(_e: MouseEvent) {
    isHighlighted.value = schedulerCtx.assignedDraggedBlockCtx.isDragging.value;
  }


  return {
    containerEl,
    blocks,
    updateEventBlockRef,

    eventBlockHandlers,

    issuesCount,
    labelDate,
    labelDayOfWeek,

    isHighlighted,

    onElementMouseenter,
    onElementMouseleave
  };
}
