export type DateParts = {
  year?: number;
  month?: number;
  date?: number;
  hour?: number;
  min?: number;
  sec?: number;
  ms?: number;
};

export function cloneDate(dateToClone: Date, parts: DateParts) {
  const {
    year = dateToClone.getFullYear(),
    month = dateToClone.getMonth(),
    date = dateToClone.getDate(),
    hour = dateToClone.getHours(),
    min = dateToClone.getMinutes(),
    sec = dateToClone.getSeconds(),
    ms = dateToClone.getMilliseconds()
  } = parts;

  return new Date(
    year,
    month,
    date,
    hour,
    min,
    sec,
    ms
  );
}
