import type { TimelineCtx }                           from "@/components/ThePlanner/components/TheGrid/composables/useTimeline";
import type { DateInterval }                          from "@/components/ThePlanner/types";
import { useIntervalFn }                              from "@vueuse/core";
import { isWithinInterval }                           from "date-fns";
import { reactive, type Ref, toRefs, toValue, watch } from "vue";

export type UseSchedulerCurrentTimeUIOptions = {
  timelineCtx: TimelineCtx;
  dateInterval: Ref<DateInterval>;
};

export type UseSchedulerCurrentTimeUIReturn = ReturnType<typeof useSchedulerCurrentTimeUI>;

export function useSchedulerCurrentTimeUI(options: UseSchedulerCurrentTimeUIOptions) {
  const { dateInterval, timelineCtx } = options;

  const state = reactive({
    date: new Date(),
    x: timelineCtx.dateToOffset(new Date()),
    y: 0,
    width: timelineCtx.hourItemWidth.value,
    visible: false,
    style: {
      left: "0px",
      width: "0px"
    }
  });

  const _intervalControls = useIntervalFn(() => {
      _recomputeLineTimeStamp();
    },
    60_000
  );

  watch(() =>
    [
      dateInterval.value,
      timelineCtx.hourItemWidth.value
    ], () => {
    _recomputeLineTimeStamp();
  }, { immediate: true });

  function _recomputeLineTimeStamp() {
    const today = new Date();
    state.date = today;

    state.x = timelineCtx.dateToOffset(today) - (timelineCtx.hourItemWidth.value / 2);
    state.style.left = `${ state.x }px`;

    state.width = timelineCtx.hourItemWidth.value;
    state.style.width = `${ state.width }px`;

    state.visible = isWithinInterval(state.date, toValue(options.dateInterval));

    if ( state.visible ) {
      !_intervalControls.isActive && _intervalControls.resume();
    } else {
      _intervalControls.isActive && _intervalControls.pause();
    }
  }

  return toRefs(state);
}
