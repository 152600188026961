<script setup lang="ts">
import { SimpleSelect }                                        from "@/components";
import { TheMultiselect }                                      from "@/components/inputs/Multiselect";
import { computed }                                            from "vue";
import type { IssuesStackHeaderEmits, IssuesStackHeaderProps } from "./types";
import { useFilterOptions, useSortOptions }                    from "./utils";

defineOptions({
  name: "IssuesStackHeader"
});

const props = defineProps<IssuesStackHeaderProps>();
const emit = defineEmits<IssuesStackHeaderEmits>();

const _detailInfoMV = defineModel("detailInfoEnabled", { default: false });
const filterOptions = useFilterOptions({ plannerCtx: props.plannerCtx });
const sortOptions = useSortOptions();
const filterOptionStructure = computed(() => {
  return filterOptions.find(
    i => i.value.type === props.plannerCtx.fetchContext.value.issuesFilter.type
  );
});

function onFilterOptionUpdate(value: any) {
  filterOptions.forEach(o => o.value.value = null);

  props.plannerCtx.onUpdateFetchContext({
    issuesFilter: value ? value.value : null
  });
}
</script>

<template>
  <div class="the-issues-stack-header w-full h-full bg-gray-100 overflow-hidden">
    <div class="h-full w-full p-2 px-2 flex flex-col">
      <div class="flex-1 flex items-center">
        <form @submit.prevent class="w-full text-sm text-gray-600">
          <div class="flex gap-0.5">
            <div class="w-32 flex flex-col gap-0.5">
              <div class="text-xs text-gray-400">Kategorie</div>
              <TheMultiselect
                :model-value="filterOptionStructure"
                class="the-multiselect"
                object
                :can-clear="false"
                :options="filterOptions"
                label="label"
                track-by="label"
                value-prop="id"
                @update:model-value="onFilterOptionUpdate"
              />
            </div>

            <div class="flex-1 flex flex-col gap-0.5">
              <div class="text-xs text-gray-400">Hodnota</div>
              <component
                v-if="filterOptionStructure"
                :is="filterOptionStructure.component"
                v-bind="filterOptionStructure.props['value']"
                v-on="filterOptionStructure!.listeners"
                :model-value="filterOptionStructure.value.value"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="pt-2 gap-2 flex items-center">
        <div class="flex flex-col gap-0.5 flex-1 min-w-40 max-w-64">
          <div class="text-xs text-gray-400 gap-0.5">Řazení</div>
          <SimpleSelect
            placeholder="Řadit podle ..."
            :model-value="plannerCtx.fetchContext.value.unplannedIssuesSortBy"
            :options="sortOptions"
            @update:model-value="
              plannerCtx.onUpdateFetchContext({ unplannedIssuesSortBy: $event })
            "
          />
        </div>
        <div class="ml-auto flex self-end gap-1">
          <div class="flex gap-1">
            <button
              class="p-2 transition hover:text-blue-600"
              @click="plannerCtx.onRefreshUnplannedIssues()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
            </button>
          </div>
          <div class="flex gap-1">
            <label class="text-gray-400 flex items-center gap-1 text-xs select-none">
              <input
                v-model="_detailInfoMV"
                type="checkbox"
              >
              <span>Detailní informace</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
