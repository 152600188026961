<script setup lang="ts">
import AccordionBtnToggle                            from "./AppAccordionBtnToggle.vue";
import type { AppAccordionEmits, AppAccordionProps } from "./types";
import type { Nullable }                             from "@/types";
import { computed, ref }                             from "vue";

const props = withDefaults(
  defineProps<AppAccordionProps>(),
  {
    modelValue: undefined
  }
);

const emit = defineEmits<AppAccordionEmits>();

const el = ref<Nullable<HTMLElement>>(null);
const headerEl = ref<Nullable<HTMLElement>>(null);

const _openedPrivate = ref(false);
const _isOpen = computed({
  get() {
    return props.modelValue ?? _openedPrivate.value;
  },
  set(value: boolean) {
    if ( typeof props.modelValue === "boolean" ) {
      emit("update:modelValue", value);
    } else {
      _openedPrivate.value = value;
    }
  }
});

defineExpose<{
  el: HTMLElement;
  headerEl: HTMLElement;
}>({
  el: el.value!,
  headerEl: headerEl.value!
});

defineSlots<{
  header(
    props: {
      toggleOpen: typeof toggleOpen;
      opened: boolean;
    }
  ): void;
  headerTitle(props: {}): void;
  btnToggle(props: {
    toggleOpen: typeof toggleOpen;
    opened: boolean;
  }): void;
}>();

function toggleOpen(open?: boolean) {
  _isOpen.value = open ?? !_isOpen.value;
}
</script>

<template>
  <div
    class="accordion"
    :class="{ 'is-open': _isOpen }"
    ref="el"
  >
    <div class="accordion__content">
      <div
        class="accordion__header"
        :class="headerClass"
        ref="headerEl"
      >
        <slot name="header" :toggleOpen="toggleOpen" :opened="_isOpen">
          <div
            class="accordion__header-title"
            :class="titleClass"
          >
            <slot name="headerTitle" />
          </div>

          <slot
            name="btnToggle"
            v-if="!disableToggleButton"
            :opened="_isOpen"
            :toggleOpen="toggleOpen"
          >
            <AccordionBtnToggle @click="toggleOpen()" />
          </slot>
        </slot>
      </div>

      <div class="accordion__body">
        <div class="overflow-hidden">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
