import {
  type TheEventBlockDragEvent,
  TooltipContentBlockDetail,
  TooltipContentBlockDragged
}                                                                    from "@/components/ThePlanner/components";
import type {
  UseSchedulerBlocksReturn
}                                                                    from "@/components/ThePlanner/components/TheGrid/composables/useSchedulerBlocks";
import type {
  TimelineCtx
}                                                                    from "@/components/ThePlanner/components/TheGrid/composables/useTimeline";
import type {
  GridRow,
  SchedulerBlock
}                                                                    from "@/components/ThePlanner/components/TheGrid/types";
import {
  computeBlockDateInterval
}                                                                    from "@/components/ThePlanner/components/TheGrid/utils";
import type {
  TooltipContentBlockDraggedProps,
  TooltipContentIssueDetailProps
}                                                                    from "@/components/ThePlanner/components/TheTooltip";
import type {
  ResizeInfoEvent
}                                                                    from "@/components/ThePlanner/composables/useElementResizeable";
import type {
  PlannerCtx
}                                                                    from "@/components/ThePlanner/composables/usePlanner";
import type { BlockBase, DateInterval, Position, User }              from "@/components/ThePlanner/types";
import { calculateTooltipPosition, isPositionInsideArea, makeClone } from "@/components/ThePlanner/utils";
import type { Nullable, ReadonlyRef }                                from "@/types";
import type { UseElementBoundingReturn }                             from "@vueuse/core";
import { differenceInMinutes }                                       from "date-fns";
import { computed, markRaw, nextTick, type Ref, ref, shallowRef }    from "vue";

export type UseSchedulerBlocksHandlersOptions = {
  plannerCtx: PlannerCtx;
  timelineCtx: TimelineCtx;

  contentEl: Ref<Nullable<HTMLElement>>;

  containerElBounding: UseElementBoundingReturn;
  contentElBounding: UseElementBoundingReturn;
  canvasElBounding: UseElementBoundingReturn;

  hoveredRow: ReadonlyRef<Nullable<GridRow>>;

  blocks: Readonly<UseSchedulerBlocksReturn["blocks"]>;
  eventBlocksRefs: Readonly<UseSchedulerBlocksReturn["eventBlocksRefs"]>;
}

export type UseSchedulerBlocksHandlersReturn = ReturnType<typeof useSchedulerBlocksHandlers>;

export function useSchedulerBlocksHandlers(options: UseSchedulerBlocksHandlersOptions) {
  const {
    plannerCtx,
    timelineCtx,
    eventBlocksRefs,
    contentElBounding
  } = options;

  const { tooltipCtx } = plannerCtx;

  const modifiedBlock = shallowRef<SchedulerBlock | null>(null);
  const modifiedBlockInitial = shallowRef<SchedulerBlock | null>(null);

  const startDragEvent = shallowRef<TheEventBlockDragEvent | null>(null);
  const isBlockDragging = ref(false);
  const isBlockResizing = ref(false);
  const isBlockSomeActionDoing = computed(() => {
    return isBlockDragging.value || isBlockResizing.value;
  });

  /* DRAGG */

  function getEventBlockDragPosition(ev: TheEventBlockDragEvent): Position {
    if ( ev.positionRelative!.y < 0 ) {
      return {
        y: 0,
        x: ev.positionRelative!.x
      };
    }

    if ( ev.positionRelative!.y + ev.el.offsetHeight > contentElBounding.height.value ) {
      return {
        x: ev.positionRelative!.x,
        y: contentElBounding.height.value - ev.el.offsetHeight
      };
    }

    return ev.positionRelative!;
  }

  function onEventBlockDragstart(block: SchedulerBlock, e: TheEventBlockDragEvent) {
    startDragEvent.value = e;
    modifiedBlock.value = block;
    modifiedBlockInitial.value = makeClone(block, true);
  }

  function onEventBlockDrag(block: SchedulerBlock, ev: TheEventBlockDragEvent) {
    isBlockDragging.value = true;

    if (
      !isPositionInsideArea(ev.position, contentElBounding, { dir: "horizontal", offset: (block.width - 10) })
    ) {
      _showTooltip(
        block,
        "drag",
        {
          message: "Issue je mimo zvolený časový interval planneru."
        }
      );

      return;
    }

    if ( !options.hoveredRow.value ) {
      _showTooltip(
        block,
        "drag",
        {
          message: "Issue nemá přiřazeného uživatele."
        }
      );

      return;
    }

    _showTooltip(
      block,
      "drag"
    );
  }

  async function onEventBlockDragend(block: SchedulerBlock, ev: TheEventBlockDragEvent) {
    isBlockDragging.value = false;

    const blockDateInterval = computeBlockDateInterval(
      block,
      timelineCtx,
      {
        basedOn: "estimated-hours"
      }
    );

    const deltaMinutes = Math.abs(
      differenceInMinutes(
        modifiedBlockInitial.value!.issue.start_date_time,
        blockDateInterval.start
      )
    );

    let success = (
      deltaMinutes >= 15 &&
      !!options.hoveredRow.value &&
      isPositionInsideArea(ev.position, contentElBounding, { dir: "horizontal", offset: (block.width - 10) })
    );
    console.log(isPositionInsideArea(ev.position, contentElBounding, {
      dir: "horizontal",
      offset: (block.width - 10)
    }));

    if ( success ) {
      success = await _updateUnassignedIssue(block, blockDateInterval);
    }

    if ( !success ) {
      block.position = modifiedBlockInitial.value!.position;
    }

    modifiedBlock.value = null;
    modifiedBlockInitial.value = null;
    tooltipCtx.clear();
  }

  /* RESIZE */

  function onEventBlockResizeStart(block: SchedulerBlock, _e: ResizeInfoEvent) {
    modifiedBlock.value = block;
    modifiedBlockInitial.value = makeClone(block, true);
  }

  function onEventBlockResize(block: SchedulerBlock, e: ResizeInfoEvent) {
    if ( !modifiedBlock.value ) return;
    isBlockResizing.value = true;
    block.position.x = e.x;
    block.width = e.width;

    _showTooltip(block, "resize");
  }

  async function onEventBlockResizeEnd(block: SchedulerBlock, _e: ResizeInfoEvent) {
    isBlockResizing.value = false;

    const interval = computeBlockDateInterval(
      block,
      timelineCtx,
      { basedOn: "width" }
    );

    if ( !(await _updateUnassignedIssue(block, interval)) ) {

      block.position = modifiedBlockInitial.value!.position;
      block.width = modifiedBlockInitial.value!.width;
    }

    modifiedBlock.value = null;
    modifiedBlockInitial.value = null;
    tooltipCtx.clear();
  }

  function onEventBlockDblclick(block: SchedulerBlock, _e: MouseEvent) {
    window.open(block.issue.url, "_blank");
  }

  /* STATIC TOOLTIP */

  function onEventBlockMouseenter(block: SchedulerBlock, _e: MouseEvent) {
    if ( isBlockSomeActionDoing.value ) return;
    _showTooltip(block, "detail");
  }

  function onEventBlockMouseleave(_block: SchedulerBlock, _e: MouseEvent) {
    if ( isBlockSomeActionDoing.value ) return;
    tooltipCtx.clear();
  }

  function onEventBlockContextmenu(block: SchedulerBlock, e: MouseEvent) {
    e.preventDefault();
    plannerCtx.blockContextMenuCtx.show(block, e);
  }

  async function _updateUnassignedIssue(block: SchedulerBlock, interval: DateInterval, user?: User) {
    const u = user ?? options.hoveredRow.value?.user;
    if ( !u ) {
      console.warn(`hoveredRow is null!!!`);
      return false;
    }

    return plannerCtx.onAssignedIssueUpdate({
      issue: block.issue,
      updatedData: {
        assigned_to_planner: {
          redmine_id: u.redmine_id,
          name: u.full_name
        },
        start_date_time: interval.start,
        end_date_time: interval.end
      }
    });
  }

  function _showTooltip<
    T extends "detail" | "drag" | "resize"
  >(
    block: SchedulerBlock,
    type?: T,
    props?: {
      detail: Partial<TooltipContentIssueDetailProps>,
      drag: Partial<TooltipContentBlockDraggedProps>,
      resize: Partial<TooltipContentBlockDraggedProps>
    }[T]
  ) {
    tooltipCtx.state.classList = "max-w-[400px]";

    switch ( type ) {
      case "detail":
        tooltipCtx.state.component = markRaw(TooltipContentBlockDetail);
        tooltipCtx.state.componentProps = Object.assign(
          {
            issue: block.issue
          } as TooltipContentIssueDetailProps,
          props
        );
        break;
      case "resize":
      case "drag":
        const basedOn = type === "drag" ? "estimated-hours" : "width";
        const { start: startDate, end: endDate } = computeBlockDateInterval(
          block,
          timelineCtx,
          { basedOn }
        );
        tooltipCtx.state.component = markRaw(TooltipContentBlockDragged);
        tooltipCtx.state.componentProps = Object.assign(
          {
            issue: block.issue,
            startDate,
            endDate,
            message: ""
          } as TooltipContentBlockDraggedProps,
          props
        );
        break;
    }

    tooltipCtx.state.visible = true;
    nextTick(() => {
      const p = _getTooltipPosition(block);

      if ( p ) {
        tooltipCtx.setPosition(p);
      }
    });
  }

  function _getTooltipPosition(block: BlockBase): Position | null {
    const eventBlockEl = eventBlocksRefs.value.get(block.id)!.el;

    if ( !eventBlockEl ) {
      return null;
    }

    const { tooltipElBounding } = tooltipCtx;
    const containerElBounding = options.containerElBounding;

    const tooltipPosition = calculateTooltipPosition({
      eventBlockEl,
      tooltipCtx
    });

    if ( tooltipPosition.x + tooltipElBounding.width.value > containerElBounding.x.value + containerElBounding.width.value ) {
      tooltipPosition.x = (containerElBounding.x.value + containerElBounding.width.value) - tooltipElBounding.width.value;
    }

    if ( tooltipPosition.x < containerElBounding.x.value ) {
      tooltipPosition.x = containerElBounding.x.value;
    }

    return tooltipPosition;
  }

  return {
    isBlockResizing,
    isBlockDragging,
    isBlockSomeActionDoing,

    getEventBlockDragPosition,

    onEventBlockMouseenter,
    onEventBlockMouseleave,
    onEventBlockDblclick,
    onEventBlockContextmenu,

    /*RESIZE*/
    onEventBlockResizeStart,
    onEventBlockResize,
    onEventBlockResizeEnd,

    /*DRAG*/
    onEventBlockDragstart,
    onEventBlockDrag,
    onEventBlockDragend
  };
}
