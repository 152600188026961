<script setup lang="ts">
import type { UseSchedulerBlockContextMenuReturn } from "@/components/ThePlanner/composables";
import { onClickOutside, useCurrentElement }       from "@vueuse/core";

const props = defineProps<{ ctx: UseSchedulerBlockContextMenuReturn }>();
const el = useCurrentElement();

onClickOutside(el, () => {
  props.ctx.hide();
});
</script>

<template>
  <div
    v-if="ctx.visible.value"
    class="fixed top-0 left-0 z-40"
    :style="{
          top: `${ ctx.position.value.y }px`,
          left: `${ ctx.position.value.x }px`
        }"
  >
    <div class="min-w-32 flex flex-col text-sm border border-gray-300 cursor-pointer text-gray-500 font-medium bg-white rounded overflow-hidden">
      <div
        ref="schedulerBlockMenuEl"
        class="flex flex-col"
      >
        <div
          class="flex gap-1 py-2 pl-2 pr-3 hover:bg-yellow-400 transition items-center"
          @click="ctx.handlers.onGoToRedmine()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </svg>
          <div>Otevřít</div>
        </div>

        <div
          class="flex gap-1 py-2 pl-2 pr-3 hover:bg-yellow-400 transition items-center"
          @click="ctx.handlers?.onEdit?.()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
          </svg>
          <div>Upravit</div>
        </div>

        <div
          class="flex gap-1 py-2 pl-2 pr-3 hover:bg-yellow-400 transition items-center"
          @click="ctx.handlers.onDelete()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
          </svg>
          <div>Odstranit z časové osy</div>
        </div>
      </div>
    </div>
  </div>
</template>
