import { toIssueBaseRaw }                   from "@/service/Issue/toIssueBaseRaw";
import { get, merge }                       from "lodash-es";
import type { AssignedIssue, IssueBaseRaw } from "@/components/ThePlanner/types";

export function toAssignedIssue(data: Record<string, any>): AssignedIssue {
  return merge<Record<string, any>, IssueBaseRaw, Partial<AssignedIssue>>(
    data,
    toIssueBaseRaw(data),
    {
      start_date_time: new Date(get(data, "start_date_time")),
      end_date_time: new Date(get(data, "end_date_time"))
    }
  ) as AssignedIssue;
}
