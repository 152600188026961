<script setup lang="ts">
import type { BaseProps } from "@/components/notifications/types";
import type { User }      from "@/components/ThePlanner/types";
import { computed }       from "vue";
import NotifyContainer    from "./NotifyContainer.vue";

defineOptions({
  name: "NotifyUserSelection"
});

const props = defineProps<BaseProps<User>>();

const user = computed(() => props.item.data!);
const userName = computed(() => `${ user.value.last_name } ${ user.value.first_name }`);
const selectedMessagePart = computed(() => {
  return user.value.is_selected ? `přidán` : `odebrán`;
});
</script>

<template>
  <NotifyContainer :item :close>
    <p class="w-full text-center text-sm text-white">
      <span class="font-bold uppercase">{{ userName }}</span>&nbsp;
      <span>{{ selectedMessagePart }}.</span>
    </p>
  </NotifyContainer>
</template>
