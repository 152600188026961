import type {
  OnAssignedBlockDropParams,
  OnUnassignedBlockDropParams
}                                                from "@/components/ThePlanner/components/TheWrike/components";
import type { SchedulerCtx }                     from "@/components/ThePlanner/components/TheWrike/composables";
import { useHandleUnassignedDraggedBlock }       from "@/components/ThePlanner/components/TheWrike/composables";
import { createDateBlockItems }                  from "@/components/ThePlanner/components/TheWrike/utils";
import type { UseDraggedBlockListenerParams }    from "@/components/ThePlanner/composables";
import type { AssignedIssue, User }              from "@/components/ThePlanner/types";
import { isPositionInsideArea }                  from "@/components/ThePlanner/utils";
import type { Nullable }                         from "@/types";
import { useElementBounding }                    from "@vueuse/core";
import type { Ref }                              from "vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

export type UseUserBlockOptions = {
  user: Ref<User>;
  issues: Ref<AssignedIssue[]>;
  schedulerCtx: SchedulerCtx;
}

export type UseUserBlockReturn = ReturnType<typeof useUserBlock>;

export function useUserBlock(options: UseUserBlockOptions) {
  const { schedulerCtx } = options;
  const { plannerCtx, wrikeCtx } = schedulerCtx;
  const { assignedDraggedBlockCtx } = schedulerCtx;

  const containerEl = ref<Nullable<HTMLElement>>(null);
  const containerElBounding = useElementBounding(containerEl);
  const isHighlighted = ref(false);

  const dateItems = computed(() => {
    return createDateBlockItems(
      plannerCtx.dateInterval.value,
      options.user.value,
      options.issues.value
    );
  });

  useHandleUnassignedDraggedBlock({
    wrikeCtx,
    containerEl,
    onContainerElEnter(_params) {
      isHighlighted.value = true;
    },
    onContainerElLeave(_params) {
      isHighlighted.value = false;
    }
  });

  onMounted(() => {
    assignedDraggedBlockCtx.addListener(
      "move",
      _onAssignedDraggedBlockMove
    );
  });

  onUnmounted(() => {
    assignedDraggedBlockCtx.addListener(
      "move",
      _onAssignedDraggedBlockMove
    );
  });

  function _onAssignedDraggedBlockMove(params: UseDraggedBlockListenerParams) {
    const { mouseEvent } = params;
    const e = mouseEvent.value!;

    isHighlighted.value = isPositionInsideArea({
        x: e.clientX,
        y: e.clientY
      },
      containerElBounding
    );
  }

  function onUserDeselect(user: User) {
    return wrikeCtx.plannerCtx.onUserDeselectHandler(user);
  }

  function onElementMouseenter(_e: MouseEvent) {
    if ( assignedDraggedBlockCtx.isDragging.value ) return;
    isHighlighted.value = true;
  }

  function onElementMouseleave(_e: MouseEvent) {
    if ( assignedDraggedBlockCtx.isDragging.value ) return;
    isHighlighted.value = false;
  }

  function onUnassignedBlockDrop(params: OnUnassignedBlockDropParams) {
    plannerCtx.onUnplannedIssuePlan({
      assignedTo: options.user.value,
      issue: params.issue,
      startDateTime: params.startDateTime,
      endDateTime: params.endDateTime
    });
  }

  function onAssignedBlockDrop(params: OnAssignedBlockDropParams) {
    void plannerCtx.onAssignedIssueUpdate({
      issue: params.issue,
      updatedData: {
        start_date_time: params.startDateTime,
        end_date_time: params.endDateTime,
        assigned_to_planner: {
          name: options.user.value.full_name,
          redmine_id: options.user.value.redmine_id
        }
      }
    });
  }

  return {
    dateItems,
    containerEl,

    isHighlighted,

    onUserDeselect,
    onElementMouseenter,
    onElementMouseleave,

    onUnassignedBlockDrop,
    onAssignedBlockDrop
  };
}

