import type { PlannerTooltipCtx } from "@/components/ThePlanner/composables";
import type { Position }          from "@/components/ThePlanner/types";

export type CalculateTooltipPositionParams = {
  eventBlockEl: HTMLElement;
  tooltipCtx: PlannerTooltipCtx;
}

export function calculateTooltipPosition(params: CalculateTooltipPositionParams): Position {
  const { tooltipCtx, eventBlockEl } = params;

  const { tooltipElBounding } = tooltipCtx;
  const eventBlockElBounding = eventBlockEl.getBoundingClientRect();

  const x = eventBlockElBounding.x + ((eventBlockElBounding.width - tooltipElBounding.width.value) / 2);
  const y = (() => {
    const t = eventBlockElBounding.y - tooltipElBounding.height.value - 8;
    return t > 0 ? t : eventBlockElBounding.y + eventBlockElBounding.height +  8;
  })();

  return { x, y };
}
