<script setup lang="ts">
import { TheDatepickerBase }           from "@/components";
import type { TheDatepickerBaseProps } from "@/components/inputs/TheDatepickerBase";
import type { DateInterval }           from "@/components/ThePlanner/types";
import { datetimeFormatters }          from "@/utils";
import { endOfDay, startOfDay }        from "date-fns";
import { computed }                    from "vue";

defineOptions({
  name: "TheDateRangePicker"
});

type Props = {
  modelValue: DateInterval;
}

const props = defineProps<Props>();

const pickerProps: TheDatepickerBaseProps = {
  multiCalendars: {
    count: 2,
    solo: true
  },
  format: (dates: Date[]) => {
    if ( dates.length !== 2 ) return "";
    const f = "E d.M.yyyy";
    const s = datetimeFormatters.format(dates[0]!, f);
    const e = datetimeFormatters.format(dates[1]!, f);

    return `${ s } - ${ e }`;
  },
  hideNavigation: ["time"],
  range: {
    partialRange: true
  }
};


const emit = defineEmits<{
  "update:modelValue": [DateInterval],
  "go-next-date-interval": [],
  "go-previous-date-interval": []
}>();

const _modelValue = computed({
  get(): [Date, Date] {
    return [props.modelValue.start, props.modelValue.end];
  },
  set(dates: [Date, Date | null]) {
    const [start, end] = dates;

    emit(
      "update:modelValue",
      {
        start: startOfDay(start),
        end: endOfDay(end ?? start)
      }
    );
  }
});

function goToNextHandler() {
  emit("go-next-date-interval");
}

function goToPreviousHandler() {
  emit("go-previous-date-interval");
}
</script>

<template>
  <div class="flex gap-0.5 items-center">
    <button class="pl-1 h-full pr-1 py-1 hover:text-white hover:bg-gray-500 rounded" @click="goToPreviousHandler">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
      </svg>
    </button>

    <TheDatepickerBase
      v-model="_modelValue"
      :config="pickerProps"
    />

    <button class="pl-1 h-full pr-1 py-1 hover:text-white hover:bg-gray-500 rounded" @click="goToNextHandler">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
      </svg>
    </button>
  </div>
</template>

<!--<style lang="postcss">-->
<!--@import "@vuepic/vue-datepicker/dist/main.css";-->

<!--:root {-->
<!--  &#45;&#45;dp-font-size: theme("fontSize.xs");-->
<!--  &#45;&#45;dp-input-padding: theme("padding.2") theme("padding.3");-->
<!--  &#45;&#45;dp-font-family: "Poppins", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;-->
<!--  &#45;&#45;dp-cell-border-radius: 50%;-->
<!--}-->

<!--.dp__theme_dark,-->
<!--.dp__theme_light {-->
<!--  &#45;&#45;dp-primary-color: theme("colors.blue.600");-->
<!--}-->

<!--.the-datepicker-cell {-->
<!--  border-radius: 50%;-->

<!--  &.dp__cell_auto_range {-->
<!--    border: 1px dashed var(&#45;&#45;dp-primary-color);-->
<!--  }-->

<!--  &.dp__cell_auto_range_start {-->
<!--  }-->

<!--  &.dp__cell_auto_range_end {-->
<!--  }-->
<!--}-->

<!--.the-datepicker-calendar {-->
<!--  .dp__calendar_row {-->
<!--    gap: 2px;-->
<!--  }-->

<!--  .dp__calendar_item {-->
<!--    .dp__today {-->
<!--      @apply bg-yellow-400 text-white;-->
<!--      border: transparent;-->
<!--    }-->

<!--    .dp__cell_auto_range_start,-->
<!--    .dp__cell_auto_range_end {-->
<!--      &,-->
<!--      &.dp__today {-->
<!--        @apply bg-blue-500 text-white;-->
<!--      }-->
<!--    }-->

<!--    .dp__range_between {-->
<!--      @apply bg-blue-400 text-white;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
