<script setup lang="ts">
import { AppAccordion }                      from "@/components";
import AccordionIssueHeader
                                             from "@/components/ThePlanner/components/AccordionIssue/AccordionIssueHeader.vue";
import { IssueMetaInfoProperties }           from "@/components/ThePlanner/components/IssueMetaInfo";
import { UNASSIGNED_ISSUE_ITEM_MIN_HEIGHT }  from "@/components/ThePlanner/config";
import { onClickOutside, useCurrentElement } from "@vueuse/core";
import { ref, watch }                        from "vue";
import type { IssuesStackItem }              from "./types";

export type Props = {
  items: IssuesStackItem[];
  onItemSelect(item: IssuesStackItem, e: MouseEvent): void;
  onItemUnselect(): void;
  onItemMouseenter(item: IssuesStackItem, e: MouseEvent): void;
  onItemMouseleave(item: IssuesStackItem, e: MouseEvent): void;
  onItemExpandChange(item: IssuesStackItem): void;
}

const props = defineProps<Props>();
const selectedItem = ref<IssuesStackItem | null>(null);
const hoveredItem = ref<IssuesStackItem | null>(null);
const focusedItem = ref<IssuesStackItem | null>(null);

const _el = useCurrentElement<HTMLElement>();
onClickOutside(
  _el,
  () => {
    selectedItem.value = null;
    hoveredItem.value = null;
  }
);

watch(() => selectedItem.value, (item) => {
  if ( !item ) props.onItemUnselect();
});

function onItemMouseEnter(item: IssuesStackItem, e: MouseEvent) {
  hoveredItem.value = item;
  props.onItemMouseenter(item, e);
}

function onItemHeaderMouseleave(item: IssuesStackItem, e: MouseEvent) {
  hoveredItem.value = null;
  props.onItemMouseleave(item, e);
}


function onItemHeaderMousedown(item: IssuesStackItem, e: MouseEvent) {
  if (
    e.button === 0 && /* left mouse button */
    !e.composedPath().some(el => {
      return el instanceof HTMLElement && el.classList.contains("accordion__btn-toggle");
    })
  ) {
    selectedItem.value = item;
    props.onItemSelect(item, e);
  } else {
    selectedItem.value = null;
  }
}

function onItemDoubleClick(item: IssuesStackItem, _e: MouseEvent) {
  onItemExpandChange(item, !item.expanded);
}

function onItemExpandChange(item: IssuesStackItem, expand: boolean) {
  item.expanded = expand;
  props.onItemExpandChange(item);
}
</script>

<template>
  <div class="the-issues-list w-full overflow-y-hidden">
    <AppAccordion
      v-for="item in items"
      :key="item.id"
      :model-value="item.expanded"
      class="border-none !rounded-none"
      @update:modelValue="onItemExpandChange(item, $event)"
    >
      <template #header="props_">
        <AccordionIssueHeader
          type="default"
          :style="{ minHeight: `${ UNASSIGNED_ISSUE_ITEM_MIN_HEIGHT }px` }"
          class="cursor-pointer"
          :class="{
            'bg-red-200 hover:bg-red-400': focusedItem?.id !== item.id && item.isSpentHoursOverflow && !item.isHighlighted,
            'bg-green-300 hover:bg-green-400': focusedItem?.id !== item.id && !item.isSpentHoursOverflow && !item.isHighlighted && item.isDone,
            'bg-red-400': !item.isHighlighted && item.isSpentHoursOverflow && focusedItem?.id === item.id,
            'bg-amber-100': !item.isHighlighted && !item.isSpentHoursOverflow && item.expanded,
            'hover:bg-amber-300': !item.isHighlighted && !item.isSpentHoursOverflow,
            'bg-amber-300': !item.isHighlighted && !item.isSpentHoursOverflow && focusedItem?.id === item.id,
            'bg-pink-200 hover:bg-pink-300': item.isHighlighted
          }"

          @toggleOpen="props_.toggleOpen()"

          @dblclick="onItemDoubleClick(item, $event)"
          @mousedown="onItemHeaderMousedown(item, $event)"
          @mouseenter="onItemMouseEnter(item, $event)"
          @mouseleave="onItemHeaderMouseleave(item, $event)"
        >
          <div class="flex-1 flex items-center pl-2">
            <div class="font-semibold text-sm">#{{ item.issue.redmine_id }}</div>
            <div>&nbsp;-&nbsp;</div>
            <div class="italic text-xs">{{ item.issue.subject }}</div>
          </div>
        </AccordionIssueHeader>
      </template>

      <IssueMetaInfoProperties
        class="px-2 py-3 min-h-full w-full justify-center"
        :issue="item.issue"
        :hidden-properties="['datetimeStart', 'datetimeEnd']"
      />
    </AppAccordion>
  </div>
</template>
